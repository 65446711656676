import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { distinctUntilChanged } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs';

import { deepEqual, getTheme, HexColorCode, ITheme, THEME_VARIABLES } from '../../';

export type ThemeCssObj = Record<THEME_VARIABLES, HexColorCode>;
@UntilDestroy()
export abstract class ThemeService {
  constructor(protected store: Store) {}

  public init() {
    let prev;
    this.store
      .select(getTheme)
      .pipe(
        filter(theme => theme !== null),
        distinctUntilChanged(curr => deepEqual(prev, curr)),
        untilDestroyed(this)
      )
      .subscribe(theme => {
        prev = theme;
        this.applyTheme(this.generateTheme(theme));
      });
  }

  private generateTheme(theme: ITheme): ThemeCssObj {
    return {
      [THEME_VARIABLES.PRIMARY]: theme.colorPrimary,
      [THEME_VARIABLES.PRIMARY_HOVER]: theme.colorPrimaryHover,
      [THEME_VARIABLES.SECONDARY]: theme.colorSecondary,
      [THEME_VARIABLES.SECONDARY_HOVER]: theme.colorSecondaryHover,
      [THEME_VARIABLES.SURFACE]: theme.colorSurface,
      [THEME_VARIABLES.SURFACE_HOVER]: theme.colorSurfaceHover,
      [THEME_VARIABLES.BACKGROUND_LIGHT]: theme.colorBackgroundLight,
      [THEME_VARIABLES.BACKGROUND_MEDIUM]: theme.colorBackgroundMedium,
      [THEME_VARIABLES.TEXT_PRIMARY]: theme.colorTextPrimary,
      [THEME_VARIABLES.TEXT_SECONDARY]: theme.colorTextSecondary,
      [THEME_VARIABLES.TEXT_BUTTON_PRIMARY]: theme.colorTextButtonPrimary,
      [THEME_VARIABLES.TEXT_BUTTON_SECONDARY]: theme.colorTextButtonSecondary,
    };
  }

  protected abstract applyTheme(themeObj: ThemeCssObj): void;
}
