import { gql } from 'apollo-angular';

export const deleteUserMutation = gql`
  mutation deleteUser {
    deleteUser
  }
`;

export const initChangeEmailMutation = gql`
  mutation initChangeEmail($payload: ResidentInitChangeEmailBean!) {
    initChangeEmail(input: $payload)
  }
`;

export const changeEmailMutation = gql`
  mutation changeEmail($payload: ResidentChangeEmailTokenBean!) {
    changeEmail(input: $payload)
  }
`;

export const changePasswordMutation = gql`
  mutation changePassword($payload: ResidentChangePasswordBean!) {
    changePassword(input: $payload)
  }
`;

export const initResetPasswordMutation = gql`
  mutation initResetPassword($payload: ResidentInitResetPasswordBean!) {
    initResetPassword(input: $payload)
  }
`;

export const resetPasswordMutation = gql`
  mutation resetPassword($payload: ResidentResetPasswordBean!) {
    resetPassword(input: $payload)
  }
`;

export const personalDataFields = `
  userDataResponseBean {
    firstName
    name
    email
    birthDate
    phone
    mobile
    address {
      street
      houseNumber
      zipCode
      additional
      city
      state
      country
    }
    sepaMandate {
      iban
      bic
      issuedAt
      issuedUntil
    }
  }
`;

export const getPersonalDataQuery = gql`
  query getPersonalData {
  getPersonalData {
      ${personalDataFields}
    }
  }
`;
