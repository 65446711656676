import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { NavigationItem } from '../../../../../models';
import { NavigationDrawerService } from '../navigation-drawer.service';

@Component({
  selector: 'rs-web-navigation-drawer-list',
  templateUrl: './navigation-drawer-list.component.html',
  styleUrls: ['./navigation-drawer-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationDrawerListComponent {
  @Input() items: NavigationItem[];
  @Input() isSubList = false;

  constructor(public navigationDrawer: NavigationDrawerService) {}
}
