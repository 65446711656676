import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromActions from './homepage-module.actions';
import { catchError, switchMap, map } from 'rxjs';
import { HomepageModuleFacade } from '../../services';
import { IHomepageModuleResponse } from '../../models';

@Injectable()
export class HomepageModuleEffects {
  constructor(
    private actions$: Actions,
    private homepageModuleFacade: HomepageModuleFacade
  ) {}

  getHomepageModuleToken$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.GetHomepageModuleToken),
      switchMap(() =>
        this.homepageModuleFacade.getHomepageModuleToken().pipe(
          map((response: IHomepageModuleResponse) =>
            fromActions.GetHomepageModuleTokenSuccess({
              response,
            })
          ),
          catchError((error: Error) => [fromActions.GetHomepageModuleTokenFailed({ error })])
        )
      )
    );
  });
}
