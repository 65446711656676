<div
  rsWebElevation
  [elevation]="2"
  class="d-flex p-2 align-items-center border-radius-default card-container"
  [class.overdue-border]="isOverdue(paymentRequest.status)"
  (click)="openPaymentRequestPage(paymentRequest.paymentLandingPageUrl)"
>
  <div class="icon-container">
    <i
      class="icon"
      [class.icon-warning]="isError(paymentRequest.status)"
      [class.icon-archive]="isPaid(paymentRequest.status)"
      [class.icon-payment-request]="
        !isError(paymentRequest.status) && !isPaid(paymentRequest.status)
      "
    ></i>
  </div>
  <div class="d-flex flex-column overflow-hidden">
    <div class="d-flex align-items-center mb-2">
      <div
        class="badge me-2"
        [ngClass]="{
          overdue: !isPaid(paymentRequest.status) && !isCanceled(paymentRequest.status),
          paid: isPaid(paymentRequest.status),
          canceled: isCanceled(paymentRequest.status),
        }"
      >
        <span *ngIf="isOverdue(paymentRequest.status)"
          >{{ 'payment_request.overdue_l' | translate | uppercase }}
          {{ paymentRequest.dueDate | date: 'dd.MM.YYYY' }}</span
        >
        <span *ngIf="isWaitingForPayment(paymentRequest.status)"
          >{{ 'payment_request.open_request_l' | translate | uppercase }}
        </span>
        <span *ngIf="isPaid(paymentRequest.status)"
          >{{ 'payment_request.paid_l' | translate | uppercase }}
          {{ paymentRequest.dueDate | date: 'dd.MM.YYYY' }}</span
        >
        <span *ngIf="isCanceled(paymentRequest.status)"
          >{{ 'ticket.state.cancelled' | translate | uppercase }}
          {{ paymentRequest.dueDate | date: 'dd.MM.YYYY' }}</span
        >
        <span
          *ngIf="
            !isOverdue(paymentRequest.status) &&
            !isPaid(paymentRequest.status) &&
            !isWaitingForPayment(paymentRequest.status) &&
            !isCanceled(paymentRequest.status)
          "
          >{{ 'payment_request.due_l' | translate | uppercase }}
          {{ paymentRequest.dueDate | date: 'dd.MM.YYYY' }}</span
        >
      </div>
      <div *ngIf="isError(paymentRequest.status)" class="badge-error">
        <span>{{ 'payment_request.status_error_l' | translate | uppercase }}</span>
      </div>
    </div>
    <div class="label line-clamp-1">
      <strong>{{ paymentRequest.amount | currency: 'EUR' }} </strong
      >{{ 'payment_request.for_l' | translate }}
      {{ paymentRequest.subject }}
    </div>
  </div>
</div>
