import { ImmomioIconName } from '@resident-nx/shared';

export interface ContentTabItem {
  id: string;

  /**
   * Label to show on the bottom tab bar
   */
  label: string;

  /**
   * Content module to show on the router outlet
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  content: any;
  icon: ImmomioIconName;
  badgeText?: string;
  needsAuth?: boolean;
}
