import { Component, Input, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  AnnouncementsWidgetBaseComponent,
  ButtonTypeEnum,
  IAnnouncementsState,
  ImmomioIconName,
  getAnnouncements,
} from '@resident-nx/shared';

@Component({
  selector: 'rs-web-announcements-widget',
  templateUrl: './announcements-widget.component.html',
  styleUrls: ['./announcements-widget.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AnnouncementsWidgetWebComponent extends AnnouncementsWidgetBaseComponent {
  @Input() override icon = ImmomioIconName.News;
  @Input() override title = 'news.announcements_l';
  @Input() override buttonText = 'news.more_announcements_a';
  @Input() showAmountOfContent = false;
  public ButtonEnumTypes = ButtonTypeEnum;
  public announcementsLimit = 4;
  public override announcements$ = this.announcementsStore.select(getAnnouncements);

  constructor(protected override announcementsStore: Store<IAnnouncementsState>) {
    super(announcementsStore);
  }

  public onTap(announcementId: number): void {
    this.tapped.emit(announcementId);
  }

  public onTapOverview(): void {
    this.overviewTapped.emit();
  }
}
