import { ChangeDetectionStrategy, Component } from '@angular/core';

import { AppWebBaseComponent } from '@resident-nx/web';

@Component({
  selector: 'rs-web-root',
  templateUrl: './app.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent extends AppWebBaseComponent {}
