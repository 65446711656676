import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbAccordionDirective, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import {
  DocumentSortField,
  GetSharedContent,
  IContract,
  ISharedDocumentsResponse,
  ISharedFolderContentResponse,
  ISort,
  ImmomioIconName,
  SortType,
  getContractsCount,
  getDocumentSorting,
  getResidentAppSettings,
  getSelectedContract,
  getSharedContent,
} from '@resident-nx/shared';
import {
  BehaviorSubject,
  Observable,
  combineLatest,
  filter,
  first,
  map,
  switchMap,
  tap,
} from 'rxjs';
import { FileDownloadWebService } from '../../../services';
import { ContractMenuDesign } from '../contract-menu/contract-menu.component';
import { DocumentWebPreviewModalComponent } from './document-preview-modal/document-preview-modal.component';

@UntilDestroy()
@Component({
  selector: 'rs-web-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss'],
})
export class DocumentsWebComponent implements OnInit, OnDestroy {
  @Input() folderId: string;
  @ViewChild(NgbAccordionDirective) accordion: NgbAccordionDirective;
  public documents$: Observable<ISharedFolderContentResponse>;
  public immomioIconName = ImmomioIconName;
  public ContractMenuDesign = ContractMenuDesign;
  public sortTypes = SortType;
  public documentSortSupported$: Observable<boolean>;
  public showSubFolderContent: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public numberOfContracts$: Observable<number>;
  constructor(
    private store: Store,
    private downloadService: FileDownloadWebService,
    private modalService: NgbModal
  ) {}

  ngOnInit() {
    this.numberOfContracts$ = this.store.select(getContractsCount).pipe(untilDestroyed(this));
    this.documentSortSupported$ = this.store.select(getResidentAppSettings).pipe(
      map(settings => settings?.documentSortingSupported),
      untilDestroyed(this)
    );
    let filterOrSortChanged$: Observable<[IContract, ISort<DocumentSortField>]>;
    if (this.folderId !== undefined) {
      filterOrSortChanged$ = combineLatest([
        this.store.select(getSelectedContract),
        this.store.select(getDocumentSorting),
      ]).pipe(first());
    } else {
      filterOrSortChanged$ = combineLatest([
        this.store.select(getSelectedContract),
        this.store.select(getDocumentSorting),
      ]).pipe(
        tap(() => {
          if (this.accordion) {
            this.accordion.collapseAll();
          }
          this.showSubFolderContent.next(false);
        }),
        map(([selectedContract, documentSorting]) => {
          return [selectedContract, documentSorting];
        })
      );
    }
    this.documents$ = filterOrSortChanged$.pipe(
      tap(([contract, sort]) => {
        this.store.dispatch(
          GetSharedContent({
            params: {
              folderId: this.folderId,
              contractId: contract.id,
              sortField: sort.value,
              sortOrder: sort.direction,
            },
          })
        );
      }),
      switchMap(() =>
        this.store.select(getSharedContent).pipe(
          filter(this.documentsFolderIdFilter(this.folderId)),
          tap(() => {
            this.showSubFolderContent.next(true);
          })
        )
      ),
      untilDestroyed(this)
    );
  }

  ngOnDestroy(): void {
    this.modalService.dismissAll();
  }

  public preview(document: ISharedDocumentsResponse) {
    const modal = this.modalService.open(DocumentWebPreviewModalComponent, {
      fullscreen: true,
    });
    Object.assign(modal.componentInstance, {
      documentName: document.name,
      documentType: document.extension,
      previewLoading: true,
    });
    this.downloadService
      .getDocument(document)
      .pipe(untilDestroyed(this))
      .subscribe(data => {
        Object.assign(modal.componentInstance, {
          downloadBlob: data.download,
          previewUrl: data.preview as string,
          previewLoading: false,
        });
      });
    modal.result.then(result => {
      if (result) this.download(result.downloadBlob, result.documentName);
    });
  }

  public download(downloadBlob: string, documentName: string) {
    this.downloadService.download(downloadBlob, documentName);
  }

  public getDocumentType(documentName: string): string {
    const tempArr = documentName.split('.');
    return tempArr[tempArr.length - 1].toLowerCase();
  }

  private documentsFolderIdFilter(
    folderId: string
  ): (documents: ISharedFolderContentResponse) => boolean {
    return (documents: ISharedFolderContentResponse): boolean => {
      return (
        documents !== null &&
        (folderId === undefined
          ? documents.folderName === 'DOCUMENT_MANAGEMENT_ROOT_FOLDER_NAME_L'
          : folderId === documents.folderId)
      );
    };
  }
}
