import { NgIfContext } from '@angular/common';
import { Component, Input, TemplateRef } from '@angular/core';
import { IUserData } from '@resident-nx/shared';
import { AvatarSizeEnum } from '../../atoms';

@Component({
  selector: 'rs-web-profile-details',
  templateUrl: './profile-details.component.html',
  styleUrls: ['./profile-details.component.scss'],
})
export class ProfileDetailsWebComponent {
  @Input() userData: IUserData;
  @Input() noData: TemplateRef<NgIfContext<string>>;
  public avatarSize = AvatarSizeEnum;
}
