import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ButtonWebComponent, IconWebComponent } from '../../../atoms';
import { ButtonTypeEnum, ImmomioIconName } from '@resident-nx/shared';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'rs-web-ticket-cancel-modal',
  templateUrl: './ticket-cancel-modal.component.html',
  styleUrls: ['./ticket-cancel-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ButtonWebComponent, IconWebComponent, TranslateModule],
})
export class TicketCancelModalWebComponent {
  public activeModal = inject(NgbActiveModal);
  public buttonTypeEnum = ButtonTypeEnum;
  public immomioIconName = ImmomioIconName;
}
