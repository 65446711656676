import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';

import {
  AnnouncementDetailsBaseComponent,
  IAnnouncementsState,
  ButtonTypeEnum,
  BreadcrumbItem,
  ImmomioIconName,
  IResidentS3File,
} from '@resident-nx/shared';
import { ROUTE } from '../../../models';
import { FileDownloadWebService } from '../../../services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DocumentWebPreviewModalComponent } from '../../organisms';
import { ImageBorderRadiusEnum, ImageObjectFitEnum } from '../../atoms';

@UntilDestroy()
@Component({
  selector: 'rs-web-announcement-details',
  templateUrl: './announcement-details.component.html',
  styleUrls: ['./announcement-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnnouncementDetailsWebComponent
  extends AnnouncementDetailsBaseComponent
  implements OnInit, OnDestroy
{
  protected readonly buttonTypeEnum = ButtonTypeEnum;
  protected readonly immomioIconName = ImmomioIconName;
  public breadcrumbs: BreadcrumbItem[] = [
    {
      label: 'main_navigation_news_l',
      url: ROUTE.NEWS,
    },
    {
      label: 'news.announcements_l',
      url: `${ROUTE.NEWS}/${ROUTE.ANNOUNCEMENTS}`,
    },
  ];
  public imageBorderRadiusEnum = ImageBorderRadiusEnum;
  public imageObjectFitEnum = ImageObjectFitEnum;

  constructor(
    private store: Store<IAnnouncementsState>,
    private route: ActivatedRoute,
    private router: Router,
    private downloadService: FileDownloadWebService,
    private modalService: NgbModal
  ) {
    super(store);
  }

  ngOnInit(): void {
    this.route.params.pipe(untilDestroyed(this)).subscribe(params => {
      this.breadcrumbs.push({ label: params.id });
    });
  }

  ngOnDestroy(): void {
    this.modalService.dismissAll();
  }

  public onNavigateBack() {
    this.router.navigate([ROUTE.NEWS, ROUTE.ANNOUNCEMENTS]);
  }

  public onNavigate(event: string) {
    this.router.navigate([event]);
  }

  public onPreview(attachment: IResidentS3File) {
    const modal = this.modalService.open(DocumentWebPreviewModalComponent, {
      fullscreen: true,
    });
    Object.assign(modal.componentInstance, {
      documentName: attachment.name,
      documentType: attachment.type.toLowerCase(),
      previewLoading: true,
    });

    this.downloadService
      .getAnnoucementAttachment(attachment)
      .pipe(untilDestroyed(this))
      .subscribe(data => {
        Object.assign(modal.componentInstance, {
          downloadBlob: data.download,
          previewUrl: data.preview as string,
          previewLoading: false,
        });
      });

    modal.result.then(result => {
      if (result) {
        this.download(result.downloadBlob, result.documentName);
      }
    });
  }

  public download(downloadBlob: string, documentName: string) {
    this.downloadService.download(downloadBlob, documentName);
  }
}
