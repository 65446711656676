import { CommonModule, DatePipe, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AngularSvgIconModule } from 'angular-svg-icon';

import {
  ATOMS,
  ButtonWebComponent,
  CardsContainerHeaderWebComponent,
  CardWebComponent,
  ContractMenuWebComponent,
  EmergencyContactsOverviewWebComponent,
  HeadlineWebComponent,
  IconWebComponent,
  ImageWebComponent,
  LabelWebComponent,
  LoaderWebComponent,
  LogoComponent,
  ModalContentComponent,
  ModalService,
  MOLECULES,
  ORGANISMS,
  PAGES,
  PictureWebComponent,
  ToastsContainerComponent,
} from './components';

import { LayoutModule } from '@angular/cdk/layout';
import localeDe from '@angular/common/locales/de';
import {
  NgbAccordionModule,
  NgbCarouselModule,
  NgbDropdownModule,
  NgbToastModule,
} from '@ng-bootstrap/ng-bootstrap';
import { LetDirective } from '@ngrx/component';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import {
  AuthInterceptor,
  AuthService,
  AuthTokenService,
  DialogService,
  DisplayCurrencyPipe,
  FeedbackService,
  RequestService,
  RouterService,
  SearchService,
  ServiceFacade,
  SharedModule,
  StorageService,
  ThemeService,
} from '@resident-nx/shared';
import { OAuthModule } from 'angular-oauth2-oidc';
import { NgApexchartsModule } from 'ng-apexcharts';
import { SortingMenuComponent } from './components/organisms/sorting-menu/sorting-menu.component';
import { ElevationDirective } from './directives';
import { DateTransformPipe, DocumentBreadcrumbsPipe } from './pipes';
import {
  AuthTokenWebService,
  AuthWebService,
  DialogWebService,
  FeedbackWebService,
  FileDownloadWebService,
  LocalStorageService,
  NewRelicWebService,
  RequestWebService,
  RouterWebService,
  ServiceWebFacade,
  SessionStorageService,
  ThemeWebService,
} from './services';

registerLocaleData(localeDe);

const COMPONENTS = [...ATOMS, ...MOLECULES, ...ORGANISMS, ...PAGES];
const PIPES = [DocumentBreadcrumbsPipe];
const MODULES = [
  CommonModule,
  RouterModule,
  FormsModule,
  ReactiveFormsModule,
  AngularSvgIconModule.forRoot(),
  TranslateModule,
  SharedModule,
  OAuthModule.forRoot(),
  LayoutModule,
  NgbToastModule,
  ToastsContainerComponent,
  NgbAccordionModule,
  NgApexchartsModule,
  NgbDropdownModule,
  NgApexchartsModule,
  NgbCarouselModule,
];

@NgModule({
  imports: [
    ...MODULES,
    LetDirective,
    CardWebComponent,
    LabelWebComponent,
    IconWebComponent,
    ElevationDirective,
    ButtonWebComponent,
    ImageWebComponent,
    LoaderWebComponent,
    CardsContainerHeaderWebComponent,
    PictureWebComponent,
    DateTransformPipe,
    EmergencyContactsOverviewWebComponent,
    ModalContentComponent,
    SortingMenuComponent,
    ContractMenuWebComponent,
    HeadlineWebComponent,
    LogoComponent,
  ],
  declarations: [...COMPONENTS, ...PIPES],
  exports: [
    ...MODULES,
    ...COMPONENTS,
    ...PIPES,
    IconWebComponent,
    ButtonWebComponent,
    ImageWebComponent,
    ContractMenuWebComponent,
    HeadlineWebComponent,
    LogoComponent,
  ],
  providers: [
    { provide: AuthService, useClass: AuthWebService },
    { provide: AuthTokenService, useClass: AuthTokenWebService },
    { provide: RouterService, useClass: RouterWebService },
    { provide: DialogService, useClass: DialogWebService },
    { provide: FeedbackService, useExisting: FeedbackWebService },
    { provide: ThemeService, useExisting: ThemeWebService },
    { provide: RequestService, useClass: RequestWebService },
    { provide: StorageService, useClass: LocalStorageService },
    { provide: ServiceFacade, useClass: ServiceWebFacade },
    { provide: LOCALE_ID, useValue: 'de-DE' },
    SessionStorageService,
    LocalStorageService,
    TranslatePipe,
    DisplayCurrencyPipe,
    DatePipe,
    SearchService,
    ModalService,
    NewRelicWebService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    FileDownloadWebService,
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class WebModule {}
