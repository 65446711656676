import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

// import { AvailableLanguageCodesEnum, BreakPointsMaxInPx, Language } from 'libs/models';

export type FooterLinkAppearance = 'default' | 'button';

export interface FooterLink {
  link: string;
  name: string;
  appearance?: FooterLinkAppearance;
  hideOnMobile?: boolean;
}

enum BreakPointsMaxInPx {
  XS = 575,
  SM = 767,
  MD = 991,
  LG = 1199,
}

@UntilDestroy()
@Component({
  selector: 'rs-web-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent implements OnInit, OnDestroy {
  @Input() currentYear = new Date().getFullYear();
  @Input() links: FooterLink[] = [];
  @Input() showLanguagePicker = false;
  //   @Input() availableLanguages: Language[];
  //   @Input() currentLanguageCode: AvailableLanguageCodesEnum;
  @Input() sideNavMode: boolean;
  @Input() dark: boolean;

  @Output() languageChange = new EventEmitter<string>();
  @Output() profileSettingsToggleClick = new EventEmitter<Event>();

  private _mobileView: BehaviorSubject<boolean>;

  public get mobileView$() {
    return this._mobileView.asObservable().pipe(distinctUntilChanged());
  }

  constructor(private observer: BreakpointObserver) {}

  public ngOnInit() {
    this._mobileView = new BehaviorSubject<boolean>(null);

    this.observer
      .observe(`(max-width: ${BreakPointsMaxInPx.SM}px)`)
      .pipe(untilDestroyed(this))
      .subscribe(result => {
        this.setMobileView(result.matches);
      });
  }

  public ngOnDestroy() {
    this._mobileView.complete();
  }

  private setMobileView(mobileView: boolean) {
    this._mobileView.next(mobileView);
  }

  public onLanguageChange(value: string) {
    this.languageChange.emit(value);
  }

  public onProfileSettingsToggleClick(event: Event) {
    this.profileSettingsToggleClick.emit(event);
  }
}
