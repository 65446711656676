<div class="offers-widget">
  <rs-web-cards-container-header
    [icon]="icon"
    [title]="title"
    [buttonText]="'general.overview_a'"
    [buttonTypeEnum]="ButtonEnumTypes.LIGHT_BORDERED"
    (tapped)="onTapOverview()"
  ></rs-web-cards-container-header>
  <div class="offers-widget__overflow">
    <div class="offers-widget__content">
      <ng-container *ngFor="let offer of offers$ | async; index as i">
        <rs-web-content-card
          *ngIf="(token$ | async) && i === 0"
          class="offers-widget__content-card"
          [image]="'/web/images/homepage-card.svg'"
          [title]="'apartments'"
          [text]="'to_apartments'"
          [textOverflow]="false"
          [preset]="preset.OFFER"
          (click)="onTapHomepage()"
        >
        </rs-web-content-card>
        <rs-web-content-card
          *ngIf="
            ((token$ | async) === null && offersLimit - 1 > i) ||
              ((token$ | async) && offersLimit - 2 > i);
            else moreOffers
          "
          class="offers-widget__content-card"
          [id]="offer.node.id"
          [image]="offer.node.imageUrl"
          [title]="offer.node.title"
          [text]="offer.node.body"
          [preset]="preset.OFFER"
          (tapped)="onTap($event)"
        ></rs-web-content-card>
        <ng-template #moreOffers>
          <rs-web-card
            *ngIf="
              ((token$ | async) === null && offersLimit - 1 === i) ||
              ((token$ | async) && offersLimit - 2 === i)
            "
            [hover]="true"
            [elevation]="3"
            (click)="onTapOverview()"
          >
            <div class="offers-widget__extra-card">{{ buttonText | translate }}</div>
          </rs-web-card>
        </ng-template>
      </ng-container>
    </div>
  </div>
  <div *ngIf="(offersActionState$ | async).pending" class="d-flex justify-content-center m-5">
    <rs-web-loader></rs-web-loader>
  </div>
</div>
