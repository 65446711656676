<ng-container *ngIf="(newsActionState$ | async).done; else loading">
  <ng-container *ngIf="news$ | async as news">
    <div class="container container--with-spacing">
      <div class="d-flex align-items-center mb-3">
        <rs-web-breadcrumbs
          [items]="breadcrumbs"
          (navigateBack)="onNavigateBack()"
          (navigateToUrl)="onNavigate($event)"
        ></rs-web-breadcrumbs>
      </div>
      <div class="container__content">
        <div class="d-flex justify-content-center">
          <rs-web-image [defaultSrc]="news.imageUrl" [maxHeightInPx]="400"></rs-web-image>
        </div>
        <div class="d-flex justify-content-center mt-4">
          <h3>{{ news.title }}</h3>
        </div>
        <span class="subtitle">{{ news.publishedAt | rsWebDate }}</span>
        <hr />
        <p [innerHtml]="news.text"></p>
        <div class="d-flex justify-content-center mt-4" *ngIf="news?.href">
          <a [href]="news?.href" target="_blank">
            <rs-web-button [type]="buttonTypeEnum.PRIMARY">{{
              'general.learn_more_b' | translate
            }}</rs-web-button></a
          >
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-template #loading>
  <div class="loading-area d-flex justify-content-center">
    <rs-web-loader></rs-web-loader>
  </div>
</ng-template>
