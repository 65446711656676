import { Directive, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { passwordValidator } from '../../utils/validators/validators';
import {
  ConsentType,
  ErrorOrigin,
  ImmomioIconName,
  IRegisterNewResident,
  IRegisterResidentResponse,
  IValidationDataSet,
  ValidationDataItem,
} from '../../models';
import { RouterService } from '../../services';
import { IActionState } from '../../utils';
import * as fromRegistrationStore from '../../+state/account/registration';

@UntilDestroy()
@Directive()
export abstract class PasswordAndConsentFormBaseComponent implements OnInit, OnDestroy {
  public fg: FormGroup = this.fb.group({
    password: ['', [Validators.required, passwordValidator]],
    consent: [false, [Validators.requiredTrue]],
    document: [false],
  });
  public immomioIconName = ImmomioIconName;
  public passwordValid: boolean;
  public validationDataArray: ValidationDataItem[];
  public registerResidentResponse$: Observable<IRegisterResidentResponse>;
  public registerResidentActionState$: Observable<IActionState>;
  private validationDataSet: IValidationDataSet;
  private subs$: Subscription[] = [];
  private email: string;
  private invitationCode: string;

  constructor(
    private fb: FormBuilder,
    private routerService: RouterService,
    private activeRoute: ActivatedRoute,
    private registerStore: Store<fromRegistrationStore.RegistrationState>
  ) {}

  ngOnInit() {
    this.validationDataSet = {
      minLength: {
        id: 'minLength',
        control: this.fg.get('password'),
        text: 'pw-check.error.pw-min_length',
      },
      hasLowerUpperCase: {
        id: 'hasLowerUpperCase',
        control: this.fg.get('password'),
        text: 'pw-check.error.pw-has_lower_upper_case',
      },
      hasNumberSpecial: {
        id: 'hasNumberSpecial',
        control: this.fg.get('password'),
        text: 'pw-check.error.pw-has_number_special',
      },
    };
    this.validationDataArray = Object.values(this.validationDataSet);

    this.registerResidentActionState$ = this.registerStore.select(
      fromRegistrationStore.getRegisterResidentActionState
    );

    this.registerStore
      .select(fromRegistrationStore.getRegistrationForm)
      .pipe(untilDestroyed(this))
      .subscribe(regForm => {
        this.email = regForm.email;
        this.invitationCode = regForm.invitationCode;
      });
  }

  ngOnDestroy(): void {
    this.subs$.forEach(sub => sub?.unsubscribe());
  }

  public validatePassword(focus: boolean): void {
    this.fg.get('password').markAsTouched();
    if (focus) {
      this.subs$[0] = this.fg.get('password').valueChanges.subscribe(() => {
        if (this.fg.get('consent').untouched) {
          this.validatePositive();
        } else {
          this.validateAll();
        }
      });
    }
  }

  public enableSubmit(event): void {
    this.fg.get('consent').markAsTouched();
    this.fg.patchValue({ consent: event.object.checked });

    if (this.fg.get('password').touched && this.fg.get('consent').value) {
      this.validateAll();
    }
  }

  checked(event: { checked: any; type: ConsentType }) {
    switch (event.type) {
      case ConsentType.TERMS_OF_SERVICE:
        if (event.checked) {
          this.fg.get('consent').markAsTouched();
          this.fg.patchValue({ consent: event.checked });
        } else {
          this.fg.get('consent').markAsUntouched();
          this.fg.patchValue({ consent: event.checked });
        }
        break;
      case ConsentType.DIGITAL_DOCUMENTS_RECEIVAL:
        if (event.checked) {
          this.fg.get('document').markAsTouched();
          this.fg.patchValue({ document: event.checked });
        } else {
          this.fg.get('document').markAsUntouched();
          this.fg.patchValue({ document: event.checked });
        }
        break;
    }
  }

  public submit() {
    this.validateAll();
    if (this.fg.get('consent').value && this.fg.get('password').valid) {
      const registerResidentInput: IRegisterNewResident = {
        email: this.email,
        password: this.fg.get('password').value,
        passwordConfirmation: this.fg.get('password').value,
        invitationCode: this.invitationCode,
        hasConsentedToDigitalDocumentsReceival: this.fg.get('document').value,
      };
      this.registerStore.dispatch(
        fromRegistrationStore.RegisterResident({ registerResidentInput })
      );
      this.handleRegistrationResponse();
    }
  }

  public handleRegistrationResponse(): void {
    this.registerResidentActionState$.pipe(untilDestroyed(this)).subscribe(response => {
      if (response.done) {
        void this.routerService.navigate(['../register-page4-confirmation-email-info'], {
          relativeTo: this.activeRoute,
        });
      } else if (response.error) {
        void this.routerService.navigate(
          [
            '../register-page-registration-error-screen',
            {
              error: response.error.message,
              origin: ErrorOrigin.REGISTRATION_PASSWORD_AND_CONSENT,
            },
          ],
          {
            relativeTo: this.activeRoute,
          }
        );
      }
    });
  }

  private validatePositive(): void {
    this.passwordValid = null;
    for (let i = 0; i < this.validationDataArray.length; i++) {
      const validationItem = this.validationDataArray[i];
      const valid =
        this.fg.get('password').value && !this.fg.get('password').hasError(validationItem.id);
      validationItem.valid = valid || null;
    }
  }

  private validateAll(): void {
    this.passwordValid = null;
    for (let i = 0; i < this.validationDataArray.length; i++) {
      const validationItem = this.validationDataArray[i];
      const valid =
        this.fg.get('password').value && !this.fg.get('password').hasError(validationItem.id);
      validationItem.valid = valid || false;
      if (!valid) {
        this.passwordValid = false;
      }
    }
  }
}
