<div class="ticket-details-header">
  <div class="ticket-details-header__symbols">
    <rs-web-status-badge [state]="state"></rs-web-status-badge>
    <div class="ticket-details-header__icons">
      <rs-web-icon
        *ngIf="deleteButton"
        [cursor]="'pointer'"
        [size]="20"
        [color]="'red'"
        [icon]="immomioIconName.Trash"
        (tapped)="cancelTicketEvent.emit()"
      ></rs-web-icon>
      <rs-web-icon
        [cursor]="'pointer'"
        [size]="20"
        [icon]="immomioIconName.Close"
        (tapped)="closeEvent.emit()"
      ></rs-web-icon>
    </div>
  </div>
  <div class="ticket-details-header__title h4">{{ title }}</div>
</div>
