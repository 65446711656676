import { Inject, Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

import * as fromActions from './user-apps.actions';
import { UserAppsFacade } from '../../../services';
import { IEnvironment } from '../../../models';

@Injectable()
export class UserAppsEffects {
  constructor(
    @Inject('ENVIRONMENT') private environment: IEnvironment,
    private actions$: Actions,
    private userAppsFacade: UserAppsFacade
  ) {}

  getUserRegisteredSaasResidentApps$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.GetUserRegisteredSaasResidentApps),
      switchMap(() => {
        if (this.environment.customerIdent) {
          const registeredApps = [
            { customerIdent: this.environment.customerIdent, name: this.environment.appName },
          ];
          return of(fromActions.GetUserRegisteredSaasResidentAppSuccess({ registeredApps }));
        } else {
          return this.userAppsFacade.getUserRegisteredSaasResidentApps().pipe(
            map(registeredApps =>
              fromActions.GetUserRegisteredSaasResidentAppSuccess({
                registeredApps,
              })
            ),
            catchError((error: Error) => [
              fromActions.GetUserRegisteredSaasResidentAppsFailed({ error }),
            ])
          );
        }
      })
    )
  );

  loadCustomerIdent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.LoadCustomerIdent),
      switchMap(({ domain }) =>
        this.userAppsFacade.getCustomerIdent(domain).pipe(
          map(customerIdentBean =>
            fromActions.LoadCustomerIdentSuccess({
              customerIdent: customerIdentBean.customerIdent,
            })
          )
        )
      )
    )
  );

  loadCustomerIdentSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.LoadCustomerIdentSuccess),
      switchMap(({ customerIdent }) =>
        of(fromActions.SetSelectedCustomerIdent({ selectedCustomerIdent: customerIdent }))
      )
    )
  );
}
