<rs-web-card [borderStyle]="'none'">
  <div *ngIf="!showAdditionalInfos" class="d-flex flex-column">
    <span class="title-xs text-secondary">ZN {{ meter.deviceId }}</span>
  </div>

  <rs-web-consumption-chart-additional-info *ngIf="showAdditionalInfos" [meter]="meter">
  </rs-web-consumption-chart-additional-info>
  <div class="consumption-chart-container">
    <!-- Month -->
    <rs-web-consumption-chart-month
      [consumption]="consumption$ | async"
      [pastConsumption]="pastConsumption$ | async"
      [options]="options"
      *ngIf="timeframe === ConsumptionTimeframe.MONTH"
    ></rs-web-consumption-chart-month>
    <!-- Quarter -->
    <rs-web-consumption-chart-quarter
      [consumption]="consumption$ | async"
      [options]="options"
      [consumptionType]="meter.type"
      *ngIf="timeframe === ConsumptionTimeframe.QUARTER"
    ></rs-web-consumption-chart-quarter>
    <!-- Year -->
    <rs-web-consumption-chart-year
      [consumption]="consumption$ | async"
      [pastConsumption]="pastConsumption$ | async"
      [options]="options"
      (consumptionSelected)="selectConsumptionByItem($event)"
      *ngIf="timeframe === ConsumptionTimeframe.YEAR"
    ></rs-web-consumption-chart-year>

    @if (!isDataPresent()) {
      <div class="consumption-chart-no-data secondary-text title-xs">
        {{ 'consumption_comparison.no_data_l' | translate }}
      </div>
    }
  </div>
</rs-web-card>
