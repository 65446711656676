import { inject, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  AppSettingsResolver,
  ContractsResolver,
  LegalTextsResolver,
  PermissionsResolver,
  PersonalDataResolver,
  SharedModule,
  UserResolver,
} from '@resident-nx/shared';
import {
  AuthWebGuard,
  ContractGuard,
  FaqWebComponent,
  ROUTE,
  TicketingGuardService,
} from '@resident-nx/web';
import { AuthenticatedComponent } from './features/authentication/components';
import { unauthenticatedRoutes } from './features/unauthenticated/unauthenticated.module';

const routes: Routes = [
  {
    path: '',
    component: AuthenticatedComponent,
    resolve: {
      appSettings: AppSettingsResolver,
      user: UserResolver,
      legalTexts: LegalTextsResolver,
      personalData: PersonalDataResolver,
      contracts: ContractsResolver,
      permissions: PermissionsResolver,
    },
    data: {
      resolverWaitForData: true,
    },
    canActivate: [AuthWebGuard, ContractGuard(true)],
    children: [
      {
        path: '',
        redirectTo: ROUTE.DASHBOARD,
        pathMatch: 'full',
      },
      {
        path: ROUTE.HOME,
        loadChildren: () => import('./features/home/home.module').then(mod => mod.HomeModule),
      },
      {
        path: ROUTE.SETTINGS,
        loadChildren: () =>
          import('./features/settings/settings.module').then(mod => mod.SettingsModule),
      },
      {
        path: ROUTE.NEWS,
        loadChildren: () =>
          import('./features/news-overview/news-overview.module').then(
            mod => mod.NewOverviewModule
          ),
      },
      {
        path: ROUTE.TICKETS,
        resolve: {
          permissions: PermissionsResolver,
        },
        canActivate: [() => inject(TicketingGuardService).TicketingGuard()],
        loadChildren: () => import('./features/ticketing').then(routes => routes.TICKETS_ROUTES),
      },
      {
        path: ROUTE.LEGAL,
        loadChildren: () => import('./features/legal/legal.module').then(mod => mod.LegalModule),
      },
      {
        path: ROUTE.FAQ,
        component: FaqWebComponent,
      },
      {
        path: ROUTE.PROFILE_PAGE,
        loadChildren: () =>
          import('./features/profile-page/profile-page.module').then(
            mod => mod.ProfilePageWebModule
          ),
      },
      {
        path: ROUTE.DASHBOARD,
        loadChildren: () =>
          import('./features/dashboard/dashboard.module').then(mod => mod.DashboardModule),
      },
      {
        path: ROUTE.CONSUMPTION,
        loadChildren: () =>
          import('./features/consumption/consumption.module').then(mod => mod.ConsumptionModule),
      },
    ],
  },
  {
    path: ROUTE.LOGOUT,
    loadComponent: () =>
      import('./features/logout/logout.component').then(component => component.LogoutComponent),
  },
  ...unauthenticatedRoutes,
];

@NgModule({
  imports: [
    SharedModule,
    RouterModule.forRoot(routes, {
      bindToComponentInputs: true,
    }),
  ],
})
export class AppRoutingModule {}
