import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  ButtonBaseComponent,
  ButtonBorderRadiusEnum,
  ButtonSizeEnum,
  ButtonTypeEnum,
} from '@resident-nx/shared';
import { Elevation, ElevationType } from '../../../../web/directives/elevation/elevation.model';
import { ElevationDirective } from '../../../directives';
import { LoaderWebComponent } from '../loader/loader.component';
import { NgClass } from '@angular/common';

@Component({
  selector: 'rs-web-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ElevationDirective, LoaderWebComponent, NgClass],
})
export class ButtonWebComponent extends ButtonBaseComponent {
  @Input() type: ButtonTypeEnum = ButtonTypeEnum.PRIMARY;
  @Input() size: ButtonSizeEnum = ButtonSizeEnum.LARGE;
  @Input() iconLeft: string;
  @Input() iconRight: string;
  @Input() spaceBetweenIcons: false;
  @Input() borderRadius: ButtonBorderRadiusEnum = ButtonBorderRadiusEnum.BIG;
  @Input() elevation: ElevationType = Elevation.ZERO;
  @Input() elevationHoverEffect = true;
  @Input() ghost = false;
  @Input() loading = false;
  @Input() disabled = false;
  @Input() useFullContainerSize = false; // e.g. used in DataTable for taking whole cell size
  @Input() zeroPadding: boolean;
  @Input() disableHover: boolean;
  @Input() useDefaultCursor: boolean;
  @Input() buttonType: 'button' | 'submit' | 'reset' = 'button'; // html button type

  public ButtonTypeEnum = ButtonTypeEnum;
  public baseClass = 'button';

  public isGhost(): boolean {
    return this.type === ButtonTypeEnum.PRIMARY ? false : this.ghost;
  }

  public isDisabled(): boolean {
    return this.disabled || this.loading;
  }

  public getElevation(): ElevationType {
    return this.isGhost() || this.type === ButtonTypeEnum.LINK ? Elevation.ZERO : this.elevation;
  }

  public click(): void {
    if (!this.isDisabled()) {
      this.tapped.emit();
    }
  }
}
