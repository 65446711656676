import { Directive, EventEmitter, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import * as fromOffersState from '../../+state/news/offers';
import { WidgetBaseComponent } from './widget.base-component';
import { LoadingType } from '../../models';

@UntilDestroy()
@Directive()
export abstract class OffersWidgetBaseComponent extends WidgetBaseComponent {
  @Output() tapped = new EventEmitter();
  @Output() homepageModuleTapped = new EventEmitter();
  offers$ = this.offersStore.select(fromOffersState.getLatestOffers).pipe(untilDestroyed(this));
  offersActionState$ = this.offersStore
    .select(fromOffersState.getOffersActionState)
    .pipe(untilDestroyed(this));
  public loadingTypes: LoadingType[] = [];

  constructor(protected offersStore: Store<fromOffersState.IOffersState>) {
    super();
  }

  navigateToOverview(event) {
    this.overviewTapped.emit(event);
  }
}
