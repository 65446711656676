import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';

import { permissionsConverter, PermissionsFacade } from '../../../services';
import * as fromActions from './permissions.actions';

@Injectable()
export class PermissionsEffects {
  constructor(
    private actions$: Actions,
    private permissionsFacade: PermissionsFacade
  ) {}

  loadPermissions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.LoadPermissions),
      switchMap(() =>
        this.permissionsFacade.getPermissions().pipe(
          map(permissionsConverter.fromDto),
          map(response => fromActions.LoadPermissionsSuccess({ response })),
          catchError(error => [fromActions.LoadPermissionsFailed(error)])
        )
      )
    )
  );
}
