<div class="mobile-title title-m">{{ 'user.profile_details_l' | translate }}</div>
<div class="mobile-avatar">
  <rs-web-avatar [name]="userData" [size]="avatarSize['LARGE']" [rounded]="true"></rs-web-avatar>
</div>
<div class="flex-mobile-column row mb-4">
  <div class="d-flex flex-column col-6 custom-margin-bottom">
    <span class="column-title">{{ 'registration_user_first_name_l' | translate }}</span>
    <span *ngIf="userData?.firstName; else noData">{{ userData.firstName }}</span>
  </div>
  <div class="d-flex flex-column col-6">
    <span class="column-title">{{ 'registration_user_name_l' | translate }}</span>
    <span *ngIf="userData?.name; else noData">{{ userData.name }}</span>
  </div>
</div>
<div class="flex-mobile-column row mb-4">
  <div class="d-flex flex-column col-6">
    <span class="column-title">{{ 'user_profile.date_of_birth_l' | translate }}</span>
    <span *ngIf="userData?.birthDate; else noData">{{
      userData.birthDate | date: 'dd.MM.YYYY'
    }}</span>
  </div>
</div>
<div class="flex-mobile-column row mb-4">
  <div class="d-flex flex-column col-6 custom-margin-bottom">
    <span class="column-title">{{ 'street' | translate }}</span>
    <span *ngIf="userData?.address?.street; else noData"
      >{{ userData.address.street }} {{ userData.address.houseNumber }}</span
    >
  </div>
  <div class="d-flex flex-column col-6">
    <span class="column-title"> {{ 'zip' | translate }}/{{ 'location' | translate }}</span>
    <span *ngIf="userData?.address?.zipCode; else noData"
      >{{ userData.address.zipCode }} {{ userData.address.city }}</span
    >
  </div>
</div>
