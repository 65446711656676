<ng-container *ngIf="emergencyContactsByContract$ | async as emergencyContactsByContract">
  <ng-container *ngIf="emergencyContactsByContract[contract] as emergencyContactsResponse">
    <div *ngIf="emergencyContactsResponse?.contacts?.length" class="m-t-28">
      <rs-web-cards-container-header
        [icon]="immomioIconName.ContactList"
        [title]="'contract.emergency_contact_l' | translate"
      ></rs-web-cards-container-header>
      <div [closeOthers]="true" class="m-t-16" ngbAccordion>
        <div
          class="m-b-16"
          ngbAccordionItem
          *ngFor="let contact of emergencyContactsResponse.contacts"
        >
          <div ngbAccordionHeader>
            <button ngbAccordionButton>
              <div class="d-flex">
                <div class="icon icon-container">
                  <rs-web-icon [icon]="immomioIconName.User"></rs-web-icon>
                </div>
                <div class="contact-container">
                  <div class="font-bold">{{ contact.position }}</div>
                  <div class="text-secondary">{{ contact.name }}</div>
                </div>
              </div>
              <div class="chevron-icon">
                <rs-web-icon [icon]="immomioIconName.ChevronDown" [size]="12.5"></rs-web-icon>
              </div>
            </button>
          </div>
          <div ngbAccordionCollapse>
            <div ngbAccordionBody>
              <rs-web-contact-details [contact]="contact"></rs-web-contact-details>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
<ng-container *ngIf="emergencyContactActionState$ | async as emergencyContactActionState">
  <rs-web-loader
    *ngIf="emergencyContactActionState[contract]?.pending"
    [centered]="true"
  ></rs-web-loader>
</ng-container>
