import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { ImmomioIconName, removeHTML } from '@resident-nx/shared';
import { Elevation, ElevationType } from '../../../directives';
import {
  CenterImageSizeEnum,
  ImageBorderRadiusEnum,
  ImageBorderStyleEnum,
  ImageObjectFitEnum,
} from '../../atoms/image/image.model';
import { ContentCardPreset } from './content-card.model';

@Component({
  selector: 'rs-web-content-card',
  templateUrl: './content-card.component.html',
  styleUrls: ['./content-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ContentCardWebComponent implements OnInit {
  @Input() elevation: ElevationType = Elevation.THREE;
  @Input() hover = true;
  @Input() textOverflow = true;
  @Input() id: number;
  @Input() image: string;
  @Input() title: string;
  @Input() text: string;
  @Input() date: string;
  @Input() preset: ContentCardPreset = null;
  @Output() tapped = new EventEmitter<number>();
  public imageObjectFit: ImageObjectFitEnum;
  public imageBoarderRadius: ImageBorderRadiusEnum;
  public imageBorderStyle: ImageBorderStyleEnum;
  public footerIcon: ImmomioIconName;
  public centerImageSize: CenterImageSizeEnum;
  public presets = ContentCardPreset;

  ngOnInit(): void {
    switch (this.preset) {
      case ContentCardPreset.ANNOUNCEMENT:
        this.footerIcon = ImmomioIconName.Clock;
        break;
      case ContentCardPreset.NEWS:
        this.footerIcon = ImmomioIconName.Calendar;
        this.imageObjectFit = ImageObjectFitEnum.CONTAINS;
        this.imageBoarderRadius = ImageBorderRadiusEnum.ROUND;
        this.imageBorderStyle = ImageBorderStyleEnum.ROUNDED;
        this.centerImageSize = CenterImageSizeEnum.M;
        break;
      default:
        this.footerIcon = ImmomioIconName.Clock;
        this.imageObjectFit = ImageObjectFitEnum.COVER;
        this.imageBoarderRadius = null;
        this.imageBorderStyle = null;
        break;
    }
  }

  public removeHTML = removeHTML;

  public tap(): void {
    this.tapped.emit(this.id);
  }
}
