import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

import { of } from 'rxjs';
import { IFederatedLoginEnvironmentVariables, StorageService } from '@resident-nx/shared';
import { AngularKeycloakService } from '../authentication';

@Injectable()
export class AuthWebGuard implements CanActivate {
  constructor(
    private router: Router,
    private sessionStorage: StorageService,
    private keycloakAngularService: AngularKeycloakService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.sessionStorage.setItem('path-after-auth', {
      pathAfterAuth: state.url.split('?')[0],
      queryParams: route.queryParams,
    });

    const clientId = this.sessionStorage.getItem(IFederatedLoginEnvironmentVariables.CLIENT_ID);

    if (!clientId) {
      void this.router.navigate(['login']);
    } else {
      if (!this.keycloakAngularService.getKeycloakInstance()) {
        return this.keycloakAngularService.initializeKeycloak();
      }
      return of(true);
    }
    return of(false);
  }
}
