import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  getContracts,
  getContractsActionState,
  getPermissionsActionState,
  getResidentAppSettings,
  IActionState,
  IContract,
  IResidentAppSettings,
  LoadContracts,
  LoadPermissions,
} from '@resident-nx/shared';
import { combineLatest, filter, map, Observable, switchMap, tap } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class TicketingGuardService {
  private router = inject(Router);
  private store = inject(Store);
  private path = '';

  constructor() {
    this.redirectPath()
      .pipe(untilDestroyed(this))
      .subscribe(p => {
        this.path = p;
      });
  }

  getPath() {
    return this.path;
  }

  TicketingGuard(): Observable<boolean> {
    return this.getContractAndPermissionInfo().pipe(
      map(([contracts, residentAppSettings]) => {
        const allowContractlessTicketing =
          residentAppSettings?.allowContractlessTicketCreationForDamages ||
          residentAppSettings?.allowContractlessTicketCreationForOtherConcerns;
        const canActivate = contracts?.length ? true : allowContractlessTicketing;
        return this.handleCanActivate(canActivate);
      })
    );
  }

  redirectPath(): Observable<string> {
    return this.getContractAndPermissionInfo().pipe(
      map(([contracts, residentAppSettings]) => {
        if (contracts.length >= 1) return 'damages';
        if (residentAppSettings?.allowContractlessTicketCreationForDamages) return 'damages';
        if (
          !residentAppSettings?.allowContractlessTicketCreationForDamages &&
          residentAppSettings?.allowContractlessTicketCreationForOtherConcerns
        )
          return 'requests';
        return 'damages';
      })
    );
  }

  DamageTicketingGuard(): Observable<boolean> {
    return this.getContractAndPermissionInfo().pipe(
      map(([contracts, residentAppSettings]) => {
        const canActivate =
          contracts?.length >= 1
            ? true
            : residentAppSettings?.allowContractlessTicketCreationForDamages;
        return this.handleCanActivate(canActivate);
      })
    );
  }

  ConcernTicketingGuard(): Observable<boolean> {
    return this.getContractAndPermissionInfo().pipe(
      map(([contracts, residentAppSettings]) => {
        const canActivate =
          contracts?.length >= 1
            ? true
            : residentAppSettings?.allowContractlessTicketCreationForOtherConcerns;
        return this.handleCanActivate(canActivate);
      })
    );
  }

  private handleCanActivate(canActivate: boolean): boolean {
    if (canActivate) {
      return true;
    } else {
      this.router.navigate(['dashboard']);
      return false;
    }
  }

  private getContractAndPermissionInfo(): Observable<[IContract[], IResidentAppSettings]> {
    return combineLatest([
      this.store.select(getContractsActionState),
      this.store.select(getPermissionsActionState),
    ]).pipe(
      tap(([contractsActionState, permissionsActionState]: [IActionState, IActionState]) => {
        if (!contractsActionState.pending && !contractsActionState.done) {
          this.store.dispatch(LoadContracts({}));
        }
        if (!permissionsActionState.pending && !permissionsActionState.done) {
          this.store.dispatch(LoadPermissions());
        }
      }),
      filter(
        ([contractsActionState, permissionsActionState]) =>
          contractsActionState.done && permissionsActionState.done
      ),
      switchMap(() =>
        combineLatest([this.store.select(getContracts), this.store.select(getResidentAppSettings)])
      )
    );
  }
}
