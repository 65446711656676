import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable, catchError, forkJoin, map, of, switchMap, withLatestFrom } from 'rxjs';

import {
  IConversationEditResponse,
  IDirectAssignmentContacts,
  IHQTicketDetailsResponse,
  IMetadataTicketDetailsResponse,
  IRootQuestionTicketOverview,
  ITicketEditResponse,
  ITicketMessageGroup,
  ITicketResidentCategory,
  ITicketResidentOverviewEdge,
  ITicketResidentOverviewResponse,
  TicketIssueType,
  TicketStatus,
} from '../../models';
import { TicketsFacade, ticketChatConverter } from '../../services';
import * as fromMasterDataState from '../account/masterdata';
import * as fromReducers from '../account/masterdata/masterdata.reducer';
import * as fromTicketState from './';
import * as fromActions from './tickets.actions';
import * as fromTicketReducers from './tickets.reducer';

@Injectable()
export class TicketsEffects {
  loadDamageTickets$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.LoadDamageTickets),
      withLatestFrom(this.store.select(fromMasterDataState.getSelectedContract)),
      switchMap(([action, contract]) =>
        this.ticketsFacade
          .getTicketsOverview(
            contract?.id,
            action.issueType,
            action.ticketStatus,
            action.offset,
            action.limit,
            action.sort
          )
          .pipe(
            map((response: ITicketResidentOverviewResponse) =>
              fromActions.LoadDamageTicketsSuccess({
                response,
              })
            ),
            catchError((error: Error) => [fromActions.LoadDamageTicketsFailed({ error })])
          )
      )
    )
  );
  loadRequestTickets$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.LoadRequestTickets),
      withLatestFrom(this.store.select(fromMasterDataState.getSelectedContract)),
      switchMap(([action, contract]) =>
        this.ticketsFacade
          .getTicketsOverview(
            contract?.id || undefined,
            action.issueType,
            action.ticketStatus,
            action.offset,
            action.limit,
            action.sort
          )
          .pipe(
            map((response: ITicketResidentOverviewResponse) =>
              fromActions.LoadRequestTicketsSuccess({
                response,
              })
            ),
            catchError((error: Error) => [fromActions.LoadRequestTicketsFailed({ error })])
          )
      )
    )
  );
  loadDamageCategoryQuestions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.LoadDamageCategoryQuestions),
      withLatestFrom(this.store.select(fromMasterDataState.getSelectedContract)),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      switchMap(([_, contract]) =>
        this.ticketsFacade
          .getCategoryQuestions(TicketIssueType.PROPERTY_DAMAGE, contract?.id || undefined)
          .pipe(
            map((damageCategories: IRootQuestionTicketOverview) =>
              fromActions.LoadDamageCategoryQuestionsSuccess({
                damageCategories,
              })
            ),
            catchError(error => [fromActions.LoadDamageCategoryQuestionsFailed({ error })])
          )
      )
    )
  );
  loadConcernCategoryQuestions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.LoadConcernCategoryQuestions),
      withLatestFrom(this.store.select(fromMasterDataState.getSelectedContract)),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      switchMap(([_, contract]) =>
        this.ticketsFacade
          .getCategoryQuestions(TicketIssueType.CONCERN, contract?.id || undefined)
          .pipe(
            map((concernCategories: IRootQuestionTicketOverview) =>
              fromActions.LoadConcernCategoryQuestionsSuccess({
                concernCategories,
              })
            ),
            catchError(error => [fromActions.LoadConcernCategoryQuestionsFailed({ error })])
          )
      )
    )
  );
  loadDamageQuestions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.LoadDamageQuestions),
      withLatestFrom(this.store.select(fromMasterDataState.getSelectedContract)),
      switchMap(([action, contract]) =>
        this.ticketsFacade
          .getDetailedQuestions(
            action.damageCategoryQuestions,
            TicketIssueType.PROPERTY_DAMAGE,
            contract?.id || undefined
          )
          .pipe(
            map((damageQuestions: ITicketResidentCategory) => {
              if (!damageQuestions) {
                damageQuestions = this.createEmptyQuestionObject();
              }
              return fromActions.LoadDamageQuestionsSuccess({
                damageQuestions,
              });
            }),
            catchError(error => [fromActions.LoadDamageQuestionsFailed({ error })])
          )
      )
    )
  );
  loadConcernQuestions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.LoadConcernQuestions),
      withLatestFrom(this.store.select(fromMasterDataState.getSelectedContract)),
      switchMap(([action, contract]) =>
        this.ticketsFacade
          .getDetailedQuestions(
            action.concernCategoryQuestions,
            TicketIssueType.CONCERN,
            contract?.id || undefined
          )
          .pipe(
            map((concernQuestions: ITicketResidentCategory) => {
              if (!concernQuestions) {
                concernQuestions = this.createEmptyQuestionObject();
              }
              return fromActions.LoadConcernQuestionsSuccess({
                concernQuestions,
              });
            }),
            catchError(error => [fromActions.LoadConcernQuestionsFailed({ error })])
          )
      )
    )
  );
  loadActiveDamageTickets$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.LoadActiveDamageTickets),
      withLatestFrom(this.store.select(fromMasterDataState.getContracts)),
      switchMap(([action, contracts]) =>
        forkJoin(
          contracts.map(c =>
            forkJoin([
              this.ticketsFacade
                .getTicketsOverview(
                  c?.id,
                  TicketIssueType.PROPERTY_DAMAGE,
                  TicketStatus.OPEN,
                  action.offset,
                  action.limit,
                  action.sort
                )
                .pipe(catchError(e => this.processError(e))),
              this.ticketsFacade
                .getTicketsOverview(
                  c?.id,
                  TicketIssueType.PROPERTY_DAMAGE,
                  TicketStatus.IN_PROGRESS,
                  action.offset,
                  action.limit,
                  action.sort
                )
                .pipe(catchError(e => this.processError(e))),
              this.ticketsFacade
                .getTicketsOverview(
                  c?.id,
                  TicketIssueType.PROPERTY_DAMAGE,
                  TicketStatus.WAITING_FOR_OTHERS,
                  action.offset,
                  action.limit,
                  action.sort
                )
                .pipe(catchError(e => this.processError(e))),
            ]).pipe(
              map(([openTickets, inProgressTickets, waitingForOthersTickets]) => [
                openTickets,
                inProgressTickets,
                waitingForOthersTickets,
              ])
            )
          )
        ).pipe(
          map(responses =>
            fromActions.LoadActiveDamageTicketsSuccess({
              sort: action.sort,
              response: responses.reduce(
                (accumulator, currentValue) => accumulator.concat(currentValue),
                []
              ),
            })
          ),
          catchError((error: Error) => [fromActions.LoadActiveDamageTicketsFailed({ error })])
        )
      )
    )
  );
  loadActiveDamageTicketsForSelectedContract$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.LoadActiveDamageTicketsForSelectedContract),
      withLatestFrom(this.store.select(fromMasterDataState.getSelectedContract)),
      switchMap(([action, contract]) =>
        forkJoin([
          this.ticketsFacade
            .getTicketsOverview(
              contract?.id,
              TicketIssueType.PROPERTY_DAMAGE,
              TicketStatus.OPEN,
              action.offset,
              action.limit,
              action.sort
            )
            .pipe(catchError(e => this.processError(e))),
          this.ticketsFacade
            .getTicketsOverview(
              contract?.id,
              TicketIssueType.PROPERTY_DAMAGE,
              TicketStatus.IN_PROGRESS,
              action.offset,
              action.limit,
              action.sort
            )
            .pipe(catchError(e => this.processError(e))),
          this.ticketsFacade
            .getTicketsOverview(
              contract?.id,
              TicketIssueType.PROPERTY_DAMAGE,
              TicketStatus.WAITING_FOR_OTHERS,
              action.offset,
              action.limit,
              action.sort
            )
            .pipe(catchError(e => this.processError(e))),
        ])
          .pipe(
            map(([openTickets, inProgressTickets, waitingForOthersTickets]) => [
              openTickets,
              inProgressTickets,
              waitingForOthersTickets,
            ])
          )
          .pipe(
            map(responses =>
              fromActions.LoadActiveDamageTicketsForSelectedContractSuccess({
                sort: action.sort,
                response: responses.reduce(
                  (accumulator, currentValue) => accumulator.concat(currentValue),
                  []
                ),
              })
            ),
            catchError((error: Error) => [
              fromActions.LoadActiveDamageTicketsForSelectedContractFailed({ error }),
            ])
          )
      )
    )
  );
  loadArchivedDamageTickets$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.LoadArchiveDamageTickets),
      withLatestFrom(this.store.select(fromMasterDataState.getContracts)),
      switchMap(([action, contracts]) =>
        forkJoin(
          contracts.map(c =>
            forkJoin([
              this.ticketsFacade
                .getTicketsOverview(
                  c?.id,
                  TicketIssueType.PROPERTY_DAMAGE,
                  TicketStatus.CLOSED,
                  action.offset,
                  action.limit,
                  action.sort
                )
                .pipe(catchError(e => this.processError(e))),
              this.ticketsFacade
                .getTicketsOverview(
                  c?.id,
                  TicketIssueType.PROPERTY_DAMAGE,
                  TicketStatus.CANCELLED,
                  action.offset,
                  action.limit,
                  action.sort
                )
                .pipe(catchError(e => this.processError(e))),
            ]).pipe(map(([closedTickets, cancelledTickets]) => [closedTickets, cancelledTickets]))
          )
        ).pipe(
          map(responses =>
            fromActions.LoadArchiveDamageTicketsSuccess({
              sort: action.sort,
              response: responses.reduce(
                (accumulator, currentValue) => accumulator.concat(currentValue),
                []
              ),
            })
          ),
          catchError((error: Error) => [fromActions.LoadActiveDamageTicketsFailed({ error })])
        )
      )
    )
  );
  loadArchivedDamageTicketsForSelectedContract$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.LoadArchiveDamageTicketsForSelectedContract),
      withLatestFrom(this.store.select(fromMasterDataState.getSelectedContract)),
      switchMap(([action, contract]) =>
        forkJoin([
          this.ticketsFacade
            .getTicketsOverview(
              contract?.id,
              TicketIssueType.PROPERTY_DAMAGE,
              TicketStatus.CLOSED,
              action.offset,
              action.limit,
              action.sort
            )
            .pipe(catchError(e => this.processError(e))),
          this.ticketsFacade
            .getTicketsOverview(
              contract?.id,
              TicketIssueType.PROPERTY_DAMAGE,
              TicketStatus.CANCELLED,
              action.offset,
              action.limit,
              action.sort
            )
            .pipe(catchError(e => this.processError(e))),
        ])
          .pipe(map(([closedTickets, cancelledTickets]) => [closedTickets, cancelledTickets]))
          .pipe(
            map(responses =>
              fromActions.LoadArchiveDamageTicketsForSelectedContractSuccess({
                sort: action.sort,
                response: responses.reduce(
                  (accumulator, currentValue) => accumulator.concat(currentValue),
                  []
                ),
              })
            ),
            catchError((error: Error) => [
              fromActions.LoadActiveDamageTicketsForSelectedContractFailed({ error }),
            ])
          )
      )
    )
  );
  loadActiveRequestTickets$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.LoadActiveRequestTickets),
      withLatestFrom(this.store.select(fromMasterDataState.getContracts)),
      switchMap(([action, contracts]) =>
        forkJoin(
          contracts.map(c =>
            forkJoin([
              this.ticketsFacade
                .getTicketsOverview(
                  c?.id,
                  TicketIssueType.CONCERN,
                  TicketStatus.OPEN,
                  action.offset,
                  action.limit,
                  action.sort
                )
                .pipe(catchError(e => this.processError(e))),
              this.ticketsFacade
                .getTicketsOverview(
                  c?.id,
                  TicketIssueType.CONCERN,
                  TicketStatus.IN_PROGRESS,
                  action.offset,
                  action.limit,
                  action.sort
                )
                .pipe(catchError(e => this.processError(e))),
              this.ticketsFacade
                .getTicketsOverview(
                  c?.id,
                  TicketIssueType.CONCERN,
                  TicketStatus.WAITING_FOR_OTHERS,
                  action.offset,
                  action.limit,
                  action.sort
                )
                .pipe(catchError(e => this.processError(e))),
            ]).pipe(
              map(([openTickets, inProgressTickets, waitingForOthersTickets]) => [
                openTickets,
                inProgressTickets,
                waitingForOthersTickets,
              ])
            )
          )
        ).pipe(
          map(responses =>
            fromActions.LoadActiveRequestTicketsSuccess({
              sort: action.sort,
              response: responses.reduce(
                (accumulator, currentValue) => accumulator.concat(currentValue),
                []
              ),
            })
          ),
          catchError((error: Error) => [fromActions.LoadActiveRequestTicketsFailed({ error })])
        )
      )
    )
  );
  loadActiveRequestTicketsForSelectedContract$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.LoadActiveRequestTicketsForSelectedContract),
      withLatestFrom(this.store.select(fromMasterDataState.getSelectedContract)),
      switchMap(([action, contract]) =>
        forkJoin([
          this.ticketsFacade
            .getTicketsOverview(
              contract?.id,
              TicketIssueType.CONCERN,
              TicketStatus.OPEN,
              action.offset,
              action.limit,
              action.sort
            )
            .pipe(catchError(e => this.processError(e))),
          this.ticketsFacade
            .getTicketsOverview(
              contract?.id,
              TicketIssueType.CONCERN,
              TicketStatus.IN_PROGRESS,
              action.offset,
              action.limit,
              action.sort
            )
            .pipe(catchError(e => this.processError(e))),
          this.ticketsFacade
            .getTicketsOverview(
              contract?.id,
              TicketIssueType.CONCERN,
              TicketStatus.WAITING_FOR_OTHERS,
              action.offset,
              action.limit,
              action.sort
            )
            .pipe(catchError(e => this.processError(e))),
        ])
          .pipe(
            map(([openTickets, inProgressTickets, waitingForOthersTickets]) => [
              openTickets,
              inProgressTickets,
              waitingForOthersTickets,
            ])
          )
          .pipe(
            map(responses =>
              fromActions.LoadActiveRequestTicketsForSelectedContractSuccess({
                sort: action.sort,
                response: responses.reduce(
                  (accumulator, currentValue) => accumulator.concat(currentValue),
                  []
                ),
              })
            ),
            catchError((error: Error) => [
              fromActions.LoadActiveRequestTicketsForSelectedContractFailed({ error }),
            ])
          )
      )
    )
  );
  LoadArchiveRequestTickets$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.LoadArchiveRequestTickets),
      withLatestFrom(this.store.select(fromMasterDataState.getContracts)),
      switchMap(([action, contracts]) =>
        forkJoin(
          contracts.map(c =>
            forkJoin([
              this.ticketsFacade
                .getTicketsOverview(
                  c?.id,
                  TicketIssueType.CONCERN,
                  TicketStatus.CLOSED,
                  action.offset,
                  action.limit,
                  action.sort
                )
                .pipe(catchError(e => this.processError(e))),
              this.ticketsFacade
                .getTicketsOverview(
                  c?.id,
                  TicketIssueType.CONCERN,
                  TicketStatus.CANCELLED,
                  action.offset,
                  action.limit,
                  action.sort
                )
                .pipe(catchError(e => this.processError(e))),
            ]).pipe(map(([closedTickets, cancelledTickets]) => [closedTickets, cancelledTickets]))
          )
        ).pipe(
          map(responses =>
            fromActions.LoadArchiveRequestTicketsSuccess({
              sort: action.sort,
              response: responses.reduce(
                (accumulator, currentValue) => accumulator.concat(currentValue),
                []
              ),
            })
          ),
          catchError((error: Error) => [fromActions.LoadArchiveRequestTicketsFailed({ error })])
        )
      )
    )
  );
  LoadArchiveRequestTicketsForSelectedContract$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.LoadArchiveRequestTicketsForSelectedContract),
      withLatestFrom(this.store.select(fromMasterDataState.getSelectedContract)),
      switchMap(([action, contract]) =>
        forkJoin([
          this.ticketsFacade
            .getTicketsOverview(
              contract?.id,
              TicketIssueType.CONCERN,
              TicketStatus.CLOSED,
              action.offset,
              action.limit,
              action.sort
            )
            .pipe(catchError(e => this.processError(e))),
          this.ticketsFacade
            .getTicketsOverview(
              contract?.id,
              TicketIssueType.CONCERN,
              TicketStatus.CANCELLED,
              action.offset,
              action.limit,
              action.sort
            )
            .pipe(catchError(e => this.processError(e))),
        ])
          .pipe(map(([closedTickets, cancelledTickets]) => [closedTickets, cancelledTickets]))
          .pipe(
            map(responses =>
              fromActions.LoadArchiveRequestTicketsForSelectedContractSuccess({
                sort: action.sort,
                response: responses.reduce(
                  (accumulator, currentValue) => accumulator.concat(currentValue),
                  []
                ),
              })
            ),
            catchError((error: Error) => [
              fromActions.LoadArchiveRequestTicketsForSelectedContractFailed({ error }),
            ])
          )
      )
    )
  );
  loadTicketDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.LoadTicketDetails),
      switchMap(({ ticketId, ticketIssueType }) =>
        this.ticketsFacade.getTicketDetailsHQ(ticketId, ticketIssueType).pipe(
          map((ticketDetailsResponse: IHQTicketDetailsResponse) =>
            fromActions.LoadTicketDetailsSuccess({ response: ticketDetailsResponse })
          ),
          catchError((error: Error) => [fromActions.LoadTicketDetailsFailed({ error })])
        )
      )
    )
  );
  createTicketHQ$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.CreateTicketHQ),
      withLatestFrom(this.store.select(fromMasterDataState.getSelectedContract)),
      switchMap(([action, contract]) => {
        const newTicket = { ...action.newTicketHQ, contractNumber: contract.id };
        return this.ticketsFacade.createTicketHQ(newTicket).pipe(
          map((createTicketResponse: ITicketEditResponse) =>
            fromActions.CreateTicketHQSuccess({ createTicketResponse })
          ),
          catchError((error: Error) => [fromActions.CreateTicketHQFailed({ error })])
        );
      })
    )
  );
  cancelTicket$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.CancelTicket),
      switchMap(({ ticketId, ticketIssueType }) =>
        this.ticketsFacade.cancelTicket(ticketId, ticketIssueType).pipe(
          map((cancelTicket: boolean) =>
            fromActions.CancelTicketSuccess({
              cancelTicketResponse: cancelTicket,
              ticketId,
              ticketIssueType,
            })
          ),
          catchError((error: Error) => [fromActions.LoadTicketDetailsFailed({ error })])
        )
      )
    )
  );
  loadTicketChat$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.LoadTicketChat),
      withLatestFrom(this.store.select(fromMasterDataState.getUser)),
      switchMap(([action, user]) =>
        this.ticketsFacade.getTicketChat(action.ticketId).pipe(
          map(ticketMessages => ticketChatConverter.fromDto(ticketMessages.messages, user)),
          map((ticketMessageGroups: ITicketMessageGroup[]) =>
            fromActions.LoadTicketChatSuccess({ ticketMessageGroups })
          ),
          catchError((error: Error) => [fromActions.LoadTicketChatFailed({ error })])
        )
      )
    )
  );
  addMessageToTicket$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.AddMessageToTicket),
      withLatestFrom(
        this.ticketStore.select(fromTicketState.getTicketChat),
        this.store.select(fromMasterDataState.getUser)
      ),
      switchMap(([action, messageGroups, user]) =>
        this.ticketsFacade.addMessageToTicket(action.message).pipe(
          map((newMessage: IConversationEditResponse) =>
            ticketChatConverter.toNewMessage(
              newMessage.createdMessageId,
              action.message,
              [...messageGroups],
              user
            )
          ),
          map((ticketMessageGroups: ITicketMessageGroup[]) =>
            fromActions.AddMessageToTicketSuccess({ ticketMessageGroups })
          ),
          catchError((error: Error) => [fromActions.AddMessageToTicketFailed({ error })])
        )
      )
    )
  );
  loadMetadataTicketDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.LoadMetadataTicketDetails),
      switchMap(({ ticketId, ticketIssueType }) =>
        this.ticketsFacade.getTicketDetailsMetadata(ticketId, ticketIssueType).pipe(
          map((metadataTicketDetailsResponse: IMetadataTicketDetailsResponse) =>
            fromActions.LoadMetadataTicketDetailsSuccess({
              response: metadataTicketDetailsResponse,
            })
          ),
          catchError((error: Error) => [fromActions.LoadMetadataTicketDetailsFailed({ error })])
        )
      )
    )
  );
  createTicketMetadata$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.CreateTicketMetadata),
      withLatestFrom(this.store.select(fromMasterDataState.getSelectedContract)),
      switchMap(([action, contract]) => {
        const newTicket = {
          ...action.newTicketMetadata,
          contractNumber: contract?.id || undefined,
        };
        return this.ticketsFacade.createTicketMetadata(newTicket).pipe(
          map((createTicketResponse: ITicketEditResponse) =>
            fromActions.CreateTicketMetadataSuccess({ createTicketResponse })
          ),
          catchError((error: Error) => [fromActions.CreateTicketMetadataFailed({ error })])
        );
      })
    )
  );
  loadDirectAssignmentContacts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.LoadDirectAssignmentContacts),
      withLatestFrom(this.store.select(fromMasterDataState.getSelectedContract)),
      switchMap(([action, contract]) => {
        return this.ticketsFacade.getDirectAssignments(action.category, contract.id).pipe(
          map((response: IDirectAssignmentContacts) =>
            fromActions.LoadDirectAssignmentContactsSuccess({
              directAssignmentContacts: response.directAssignmentContacts,
            })
          ),
          catchError((error: Error) => [fromActions.LoadDirectAssignmentContactsError({ error })])
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private ticketsFacade: TicketsFacade,
    private store: Store<fromReducers.IMasterDataState>,
    private ticketStore: Store<fromTicketReducers.ITicketsState>
  ) {}

  processError(e: Error): Observable<ITicketResidentOverviewResponse> {
    console.error(e);
    return of({
      edges: [] as ITicketResidentOverviewEdge[],
      pageInfo: { hasPreviousPage: false, hasNextPage: false, startCursor: null, endCursor: null },
    });
  }

  createEmptyQuestionObject(): ITicketResidentCategory {
    return {
      id: null,
      categoryAnswerSetRelationId: '',
      name: '',
      detailRootQuestion: {
        id: null,
        mainQuestionIds: [],
        questions: [],
        meta: [],
      },
    };
  }
}
