<div class="row-container">
  <div class="col-12">
    <rs-web-picture
      *ngIf="item.imageUrl"
      [src]="item.imageUrl"
      className="offers-image"
    ></rs-web-picture>
  </div>
  <div class="col-10">
    <rs-web-label [text]="item.title" className="h4 text-primary"></rs-web-label>
  </div>
  <div class="col-10">
    <rs-web-label [text]="item.excerpt" className="body text-secondary"></rs-web-label>
  </div>
</div>
