import { Component, OnInit, ViewChild } from '@angular/core';
import { HexColorCode, PieChartBaseComponent, DisplayCurrencyPipe } from '@resident-nx/shared';
import {
  ApexChart,
  ApexDataLabels,
  ApexLegend,
  ApexPlotOptions,
  ApexStates,
  ApexTooltip,
  ChartComponent,
} from 'ng-apexcharts';

@Component({
  selector: 'rs-web-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss'],
})
export class PieChartWebComponent extends PieChartBaseComponent implements OnInit {
  @ViewChild('chart') chart: ChartComponent;
  public chartOptions: {
    series: number[];
    chart: ApexChart;
    legend: ApexLegend;
    dataLabels: ApexDataLabels;
    colors: HexColorCode[];
    tooltip: ApexTooltip;
    plotOptions: ApexPlotOptions;
    states: ApexStates;
  };

  constructor(private displayCurrency: DisplayCurrencyPipe) {
    super();
  }

  public override ngOnInit(): void {
    super.ngOnInit();

    const series = this.data.set.map(s => s.amount);
    const colors = this.data.set.map(s => s.color);

    this.chartOptions = {
      series,
      colors,
      dataLabels: {
        enabled: false,
      },
      chart: {
        type: 'donut',
      },
      legend: {
        show: false,
      },
      tooltip: {
        enabled: false,
      },
      states: {
        hover: {
          filter: {
            type: 'none',
          },
        },
        active: {
          filter: {
            type: 'none',
          },
        },
      },
      plotOptions: {
        pie: {
          expandOnClick: false,
          donut: {
            size: '60%',
            labels: {
              show: true,
              name: {
                offsetY: 6,
              },
              total: {
                show: true,
                showAlways: true,
                fontSize: '20px',
                fontWeight: 600,
                label: `${this.displayCurrency.transform(
                  series.reduce((x, y) => x + y, 0),
                  'EUR'
                )}`,
                formatter: () => {
                  return '';
                },
              },
            },
          },
        },
      },
    };
  }
}
