<div class="d-flex">
  <div
    *ngFor="let item of tabItems; let i = index"
    [id]="'item' + i"
    (click)="select(item.index)"
    class="tab-bar-item h4 d-flex justify-content-center"
    [class.active]="selectedIndex === item.index"
  >
    {{ item.title | translate }}
  </div>
</div>
