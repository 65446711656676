import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnChanges,
  ViewChild,
} from '@angular/core';
import {
  ConsumptionAggregateType,
  ConsumptionType,
  ConsumptionUnitType,
  IConsumptionInfo,
  ImmomioIconName,
  NgChanges,
} from '@resident-nx/shared';
import { Router } from '@angular/router';
import { ROUTE } from '../../../models';

@Component({
  selector: 'rs-web-consumption-dashboard-cards',
  templateUrl: './consumption-dashboard-cards.component.html',
  styleUrls: ['./consumption-dashboard-cards.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConsumptionDashboardCardsWebComponent implements OnChanges {
  @Input() set metersByContract(metersByContract: IConsumptionInfo) {
    this._metersByContract = metersByContract;

    // fallback
    if (!metersByContract?.meters || metersByContract?.meters?.length === 0) {
      this._metersByContract = {
        meters: [
          {
            deviceId: '',
            currentValue: 0,
            defaultAggregation: ConsumptionAggregateType.DAY,
            updateInterval: 0,
            type: ConsumptionType.POWER,
            measurementUnit: ConsumptionUnitType.M3,
            max: 0,
            firstEntry: 0,
            lastEntry: 0,
          },
        ],
      };
    }
  }
  get metersByContract(): IConsumptionInfo {
    return this._metersByContract;
  }
  private _metersByContract: IConsumptionInfo;

  @ViewChild('scrollableContainer') scrollableCards: ElementRef;

  public showWhiteMobileControls = false;

  public readonly immomioIconName = ImmomioIconName;
  public activeCardIndex = 0;

  constructor(private router: Router) {}

  ngOnChanges(changes: NgChanges<ConsumptionDashboardCardsWebComponent>): void {
    if (changes.metersByContract) {
      this.updateMobileControlColor();
    }
  }

  public getNextConsumption(scroll = false) {
    if (scroll) {
      this.scrollableCards.nativeElement.scrollTo({
        left: this.scrollableCards.nativeElement.scrollLeft + 350,
        behavior: 'smooth',
      });
    }
  }

  public getPrevConsumption(scroll = false) {
    if (scroll) {
      this.scrollableCards.nativeElement.scrollTo({
        left: this.scrollableCards.nativeElement.scrollLeft - 350,
        behavior: 'smooth',
      });
    }
  }

  public onCardsScroll(event: EventTarget) {
    const el = event as HTMLElement;
    const elements = el.querySelectorAll('.card-container') as NodeListOf<HTMLElement>;
    if (
      this.activeCardIndex !== elements.length - 1 &&
      Math.floor(el.scrollLeft) - elements[this.activeCardIndex + 1].offsetLeft >= -150
    ) {
      this.activeCardIndex++;
      this.getNextConsumption();
    }

    if (
      this.activeCardIndex !== 0 &&
      Math.floor(el.scrollLeft) - elements[this.activeCardIndex - 1].offsetLeft <= 150
    ) {
      this.activeCardIndex--;
      this.getPrevConsumption();
    }

    this.updateMobileControlColor();
  }

  updateMobileControlColor(): void {
    const activeMeter = this.metersByContract.meters[this.activeCardIndex];
    const typesWithWhiteControls = [
      ConsumptionType.HEATING,
      ConsumptionType.HOT_WATER,
      ConsumptionType.COLD_WATER,
    ];

    this.showWhiteMobileControls = typesWithWhiteControls.includes(activeMeter?.type);
  }

  navigateToDetails(deviceId: string): void {
    void this.router.navigate([ROUTE.CONSUMPTION], { queryParams: { deviceId } });
  }
}
