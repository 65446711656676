import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UserLegalInfoPageBaseComponent } from '@resident-nx/shared';
import { LoaderWebComponent } from '@resident-nx/web';
import { AsyncPipe, NgIf } from '@angular/common';

@Component({
  selector: 'rs-web-legal',
  templateUrl: './legal.component.html',
  styleUrls: ['./legal.component.scss'],
  standalone: true,
  imports: [LoaderWebComponent, AsyncPipe, NgIf],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LegalWebComponent extends UserLegalInfoPageBaseComponent {}
