<div class="d-flex justify-content-between">
  <div class="date-picker-container">
    <rs-web-icon
      primary
      [icon]="ImmomioIconName.ArrowLeft"
      [size]="14"
      [color]="iconColor"
      (click)="selectPreviousPeriod()"
    ></rs-web-icon>
    <span class="date-picker-text" [class.month]="isMonthSelected">
      {{ date | date: dateFormat }}
    </span>
    <rs-web-icon
      primary
      [icon]="ImmomioIconName.ArrowRight"
      [size]="14"
      [color]="iconColor"
      (click)="selectNextPeriod()"
    ></rs-web-icon>
  </div>
  <div class="d-flex flex-row gap-2" *ngIf="isMonthAvailable">
    <div
      class="date-picker-toggle-item"
      [class.active]="isMonthSelected"
      (click)="timeframeChange.emit(ConsumptionTimeframe.MONTH)"
    >
      <span>{{ 'month' | translate }}</span>
    </div>
    <div
      class="date-picker-toggle-item"
      [class.active]="!isMonthSelected"
      (click)="timeframeChange.emit(ConsumptionTimeframe.YEAR)"
    >
      <span>{{ 'year' | translate }}</span>
    </div>
  </div>
</div>
