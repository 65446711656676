import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ImmomioIconName, TicketCardBaseComponent } from '@resident-nx/shared';
import {
  CardWebComponent,
  IconWebComponent,
  LabelWebComponent,
  StatusBadgeWebComponent,
} from '../../../atoms';
import { DatePipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'rs-web-ticket-card',
  standalone: true,
  imports: [
    CardWebComponent,
    StatusBadgeWebComponent,
    LabelWebComponent,
    IconWebComponent,
    DatePipe,
    TranslateModule,
  ],
  templateUrl: './ticket-card.component.html',
  styleUrl: './ticket-card.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TicketCardWebComponent extends TicketCardBaseComponent {
  protected readonly immomioIconName = ImmomioIconName;
}
