<ng-container *ngIf="(legalTextsState$ | async).done; else loading">
  <div class="container mt-4 mb-3">
    <div
      class="primary-text"
      [innerHTML]="(legalTexts$ | async)?.[(route.queryParams | async).link]"
    ></div>
  </div>
</ng-container>
<ng-template #loading>
  <div class="loading-area d-flex justify-content-center">
    <rs-web-loader></rs-web-loader>
  </div>
</ng-template>
