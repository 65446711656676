import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { HeaderBaseComponent, TicketStatus } from '@resident-nx/shared';
import { ButtonWebComponent, IconWebComponent, StatusBadgeWebComponent } from '../../../../atoms';

@Component({
  selector: 'rs-web-ticket-details-header',
  templateUrl: 'ticket-details-header.component.html',
  styleUrls: ['ticket-details-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, ButtonWebComponent, StatusBadgeWebComponent, IconWebComponent],
})
export class TicketDetailsHeaderWebComponent extends HeaderBaseComponent {
  @Input() state: TicketStatus;
  @Input() deleteButton = false;
  @Output() closeEvent = new EventEmitter();
  @Output() cancelTicketEvent = new EventEmitter();
}
