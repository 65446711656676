import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { SharedModule } from '../shared/shared.module';
import { AuthenticatedComponent } from './components';

export const routes: Routes = [];

@NgModule({
  imports: [SharedModule, RouterModule.forChild(routes)],
  declarations: [AuthenticatedComponent],
})
export class AuthenticationModule {}
