import { AnnouncementsWidgetWebComponent } from './announcements-widget/announcements-widget.component';
import { ConsumptionCardWebComponent } from './consumption-card/consumption-card.component';
import { ConsumptionDashboardCardsWebComponent } from './consumption-dashboard-cards/consumption-dashboard-cards.component';
import { ConsumptionWidgetWebComponent } from './consumption-widget/consumption-widget.component';
import { ContentTabsComponent } from './content-tabs/content-tabs.component';
import { ContractsWebComponent } from './contracts/contracts.component';
import { documentComponents } from './documents';
import { navigationComponents } from './navigation';
import { NavigationComponent } from './navigation/navigation.component';
import { NewsOverviewWebComponent } from './news-overview/news-overview.component';
import { NewsWidgetWebComponent } from './news-widget/news-widget.component';
import { OffersWidgetWebComponent } from './offers-widget/offers-widget.component';
import { PaymentDetailsWebComponent } from './payment-details/payment-details.component';
import { ProfileDetailsWebComponent } from './profile-details/profile-details.component';
import { UserContactDetailsWebComponent } from './user-contact-details/user-contact-details.component';

export { ContactDetailsWebComponent } from './contact-details/contact-details.component';
export { EmergencyContactsOverviewWebComponent } from './emergency-contacts-overview/emergency-contacts-overview.component';
export { EmergencyContactBannerComponent } from './emergency/emergency-contact-flow-banner/emergency-contact-banner.component';
export { EmergencyContactFlowComponent } from './emergency/emergency-contact-flow.component';
export { TicketsWidgetWebComponentComponent } from './ticketing/tickets-widget/tickets-widget.component';

export * from './announcements-widget/announcements-widget.component';
export * from './consumption-dashboard-cards/consumption-dashboard-cards.component';
export * from './contact-details/contact-details.component';
export * from './content-tabs/content-tabs.component';
export * from './content-tabs/tab-item';
export * from './contract-menu/contract-menu.component';
export * from './contracts/contracts.component';
export * from './documents';
export * from './emergency-contacts-overview/emergency-contacts-overview.component';
export * from './navigation/navigation.model';
export * from './news-overview/news-overview.component';
export * from './news-widget/news-widget.component';
export * from './offers-widget/offers-widget.component';
export * from './side-sheet-form';
export * from './ticketing';

export const ORGANISMS = [
  ContentTabsComponent,
  NewsOverviewWebComponent,
  OffersWidgetWebComponent,
  NewsWidgetWebComponent,
  AnnouncementsWidgetWebComponent,
  NavigationComponent,
  UserContactDetailsWebComponent,
  ProfileDetailsWebComponent,
  PaymentDetailsWebComponent,
  ConsumptionWidgetWebComponent,
  ConsumptionCardWebComponent,
  ...navigationComponents,
  ...documentComponents,
  PaymentDetailsWebComponent,
  ConsumptionDashboardCardsWebComponent,
  ContractsWebComponent,
];
