<apx-chart
  [series]="availableSeries[activeSeriesPeriod]"
  [colors]="colors"
  [states]="states"
  [stroke]="stroke"
  [chart]="options.chart"
  [dataLabels]="options.dataLabels"
  [grid]="options.grid"
  [legend]="options.legend"
  [markers]="options.markers"
  [plotOptions]="plotOptions"
  [tooltip]="options.tooltip"
  [xaxis]="options.xaxis"
  [yaxis]="options.yaxis"
></apx-chart>

<div
  *ngIf="isSemesterMode && activeSeriesPeriod === SeriesPeriod.SECOND_SEMESTER"
  class="semester-button__previous"
>
  <rs-web-icon
    (click)="selectSeriesPeriod(SeriesPeriod.FIRST_SEMESTER)"
    [icon]="ImmomioIconName.ChevronLeft"
    [color]="'var(--color-text-secondary, #7c8592)'"
    [size]="16"
  >
  </rs-web-icon>
</div>
<div
  *ngIf="isSemesterMode && activeSeriesPeriod === SeriesPeriod.FIRST_SEMESTER"
  class="semester-button__next"
>
  <rs-web-icon
    (click)="selectSeriesPeriod(SeriesPeriod.SECOND_SEMESTER)"
    [icon]="ImmomioIconName.ChevronRight"
    [color]="'var(--color-text-secondary, #7c8592)'"
    [size]="16"
  >
  </rs-web-icon>
</div>
