import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, forwardRef, inject } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  ButtonTypeEnum,
  LoadActiveDamageTicketsForSelectedContract,
  TicketIssueType,
  TicketsOverviewDamageBaseComponent,
} from '@resident-nx/shared';
import { combineLatest, tap } from 'rxjs';
import { SideSheetService } from '../../../../services';
import { WebModule } from '../../../../web.module';
import { CardsContainerHeaderWebComponent } from '../../../molecules';
import { ContractMenuDesign } from '../../contract-menu/contract-menu.component';
import { SortingMenuComponent } from '../../sorting-menu/sorting-menu.component';
import { TicketCreationPageWebComponent, TicketsWebComponent } from '../../ticketing';

@UntilDestroy()
@Component({
  selector: 'rs-web-tickets-overview-damage',
  standalone: true,
  imports: [
    CardsContainerHeaderWebComponent,
    TicketsWebComponent,
    AsyncPipe,
    SortingMenuComponent,
    forwardRef(() => WebModule),
  ],
  templateUrl: './tickets-overview-damage.component.html',
  styleUrl: './tickets-overview-damage.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TicketsOverviewDamageWebComponent
  extends TicketsOverviewDamageBaseComponent
  implements OnInit
{
  ngOnInit(): void {
    combineLatest([this.selectedContract$, this.selectedSorting$])
      .pipe(
        tap(([_, sort]) => {
          this.sort = sort;
          this.ticketsStore.dispatch(
            LoadActiveDamageTicketsForSelectedContract({ ...this.pagination, sort })
          );
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }
  protected readonly ButtonTypeEnum = ButtonTypeEnum;
  protected readonly ContractMenuDesign = ContractMenuDesign;
  #sideSheetService = inject(SideSheetService);

  public override createTicket() {
    this.#sideSheetService.open(TicketCreationPageWebComponent, {
      data: {
        type: TicketIssueType.PROPERTY_DAMAGE,
      },
    });
  }
}
