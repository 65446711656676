import { ModuleWithProviders, NgModule } from '@angular/core';
import { DIRECTIVES } from './directives';
import { AddressPipe, ContractIconPipe, ContractNamePipe, PIPES, TimeAgoPipe } from './pipes';
import { FACADES, GUARDS, SERVICES } from './services';

const STANDALONE_PIPES = [AddressPipe, ContractIconPipe, TimeAgoPipe, ContractNamePipe];

@NgModule({
  imports: [...STANDALONE_PIPES],
  declarations: [PIPES, DIRECTIVES],
  exports: [PIPES, DIRECTIVES, ...STANDALONE_PIPES],
  providers: [...FACADES, ...SERVICES, ...PIPES],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [...FACADES, ...GUARDS, ...SERVICES],
    };
  }
}
