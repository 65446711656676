import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { OffersOverviewBaseComponent, BreadcrumbItem, ButtonTypeEnum } from '@resident-nx/shared';
import { ROUTE } from '../../../models';
import { filter, take } from 'rxjs';
import { ContentCardPreset } from '../content-card/content-card.model';

@Component({
  selector: 'rs-web-offers-overview',
  templateUrl: './offers-overview.component.html',
  styleUrls: ['./offers-overview.component.scss'],
})
export class OffersOverviewWebComponent extends OffersOverviewBaseComponent {
  public breadcrumbs: BreadcrumbItem[] = [
    {
      label: 'main_navigation_news_l',
      url: ROUTE.NEWS,
    },
    {
      label: 'news.offers_l',
      url: `${ROUTE.NEWS}/${ROUTE.OFFERS}`,
    },
  ];
  public buttonTypeEnum = ButtonTypeEnum;
  public presets = ContentCardPreset;

  constructor(
    private router: Router,
    store: Store
  ) {
    super(store, 30);
  }

  public onNavigate(event: string = ROUTE.NEWS) {
    this.router.navigate([event]);
  }

  public onTapArticle(id: number) {
    this.router.navigate([ROUTE.NEWS, ROUTE.OFFERS, id]);
  }

  public onTapHomepage() {
    this.router.navigate([ROUTE.NEWS, ROUTE.OBJECTS]);
  }

  public onScroll() {
    this.offersActionState$
      .pipe(
        filter(state => !state.pending),
        take(1)
      )
      .subscribe(() => this.loadMoreOffers());
  }
}
