import { Directive, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';

import { ImmomioIconName } from '../../../models';
import { CustomerService, RouterService } from '../../../services';
import { LoadActiveDamageTickets, LoadArchiveDamageTickets } from '../../../+state';

@Directive()
export abstract class TicketsOverviewDamagePageBaseComponent implements OnInit {
  public showBandODamageFlow = false;
  public archiveViewOpened = false;
  public immomioIconName = ImmomioIconName;

  constructor(
    protected routerService: RouterService,
    protected route: ActivatedRoute,
    protected store: Store,
    protected customerService: CustomerService
  ) {}

  ngOnInit() {
    this.showBandODamageFlow = this.customerService.showBandODamageFlow();
  }

  protected reloadTickets(): void {
    // TODO: IMPROVEMENT check pagination before reloading
    if (this.archiveViewOpened) {
      this.store.dispatch(LoadArchiveDamageTickets({ offset: 0, limit: 100 }));
    }
    this.store.dispatch(LoadActiveDamageTickets({ offset: 0, limit: 100 }));
  }
}
