import { Component, Input } from '@angular/core';
import { IPublishedAnnouncement } from '@resident-nx/shared';

@Component({
  selector: 'rs-web-announcements-card',
  templateUrl: './announcements-card.component.html',
  styleUrls: ['./announcements-card.component.scss'],
})
export class AnnouncementsCardWebComponent {
  @Input() item: IPublishedAnnouncement;
}
