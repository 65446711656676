<div class="payment-requests-container">
  <div class="payment-requests-container__title">
    <rs-web-icon [icon]="immomioIconName.Coin" [size]="16" class="me-2"></rs-web-icon>
    <span class="primary-text font-weight-600">{{
      'payment_requests.active_requests_l' | translate
    }}</span>
  </div>

  <ng-container *ngIf="(paymentRequestsActionState$ | async).pending !== true; else loading">
    <ng-container *ngIf="paymentRequests$ | async as payments">
      <div class="payment-container" *ngIf="payments.length; else noPayments">
        <rs-web-payment-request-card
          class="payment-container__card"
          *ngFor="let payment of payments"
          [paymentRequest]="payment"
        ></rs-web-payment-request-card>
      </div>
    </ng-container>
    <ng-template #noPayments>
      <span class="primary-text font-weight-600">{{
        'payment_requests.no_requests_l' | translate
      }}</span>
    </ng-template>
  </ng-container>
</div>

<hr class="mobile-only-element" />

<ng-container *ngIf="archivePaymentRequests$ | async as archivePayments">
  <div
    class="archive-payments-container"
    [class.open]="archiveRequestsOpen"
    *ngIf="archivePayments?.length"
  >
    <div class="archive-payments-container__title">
      <rs-web-icon [icon]="immomioIconName.Archive" [size]="16" class="me-2"></rs-web-icon>
      <span class="primary-text font-weight-600">{{
        'payment_requests.archive_requests_l' | translate
      }}</span>
      <rs-web-icon
        *ngIf="archiveRequestsOpen"
        [icon]="immomioIconName.Close"
        [size]="16"
        class="icon web-only-element"
        (click)="closeArchivePayments()"
      ></rs-web-icon>
      <rs-web-button
        *ngIf="!archiveRequestsOpen"
        class="mobile-only-element"
        [type]="buttonType.LIGHT_BORDERED"
        [size]="buttonSize.SMALL"
        (click)="openArchivePayments()"
      >
        {{ 'general.open' | translate }}
      </rs-web-button>
      <rs-web-button
        *ngIf="archiveRequestsOpen"
        class="mobile-only-element"
        [type]="buttonType.LIGHT_BORDERED"
        [size]="buttonSize.SMALL"
        (click)="closeArchivePayments()"
      >
        {{ 'general.close' | translate }}
      </rs-web-button>
    </div>
    <div>
      <ng-container *ngIf="!archiveRequestsOpen">
        <rs-web-button
          class="web-only-element"
          [type]="buttonType.LIGHT_BORDERED"
          [size]="buttonSize.SMALL"
          (click)="openArchivePayments()"
        >
          {{ 'general.open' | translate }}
        </rs-web-button>
      </ng-container>
      <ng-container *ngIf="archiveRequestsOpen">
        <ng-container
          *ngIf="(archivePaymentRequstsActionState$ | async).pending !== true; else loading"
        >
          <div class="payment-container">
            <rs-web-payment-request-card
              *ngFor="let archivePayment of archivePayments"
              [paymentRequest]="archivePayment"
            ></rs-web-payment-request-card>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
</ng-container>

<div></div>
<ng-template #loading
  ><rs-web-loader [biggerSpinner]="true" [centered]="true"></rs-web-loader
></ng-template>
