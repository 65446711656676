<div class="news-widget">
  <rs-web-cards-container-header
    [icon]="icon"
    [title]="title"
    [buttonTypeEnum]="buttonTypeEnum.LIGHT_BORDERED"
    [buttonText]="'general.overview_a'"
    (tapped)="onTapOverview()"
  ></rs-web-cards-container-header>
  <div class="news-widget__overflow">
    <div class="news-widget__content">
      <ng-container *ngFor="let general of news$ | async; index as i">
        <rs-web-content-card
          *ngIf="newsLimit - 1 > i; else moreGeneral"
          class="news-widget__content-card"
          [id]="general.node.id"
          [image]="general.node.imageUrl"
          [title]="general.node.title"
          [text]="general.node.text"
          [date]="general.node.publishedAt"
          [preset]="contentCardPreset.NEWS"
          (tapped)="onTap($event)"
        ></rs-web-content-card>
        <ng-template #moreGeneral>
          <rs-web-card
            *ngIf="newsLimit - 1 === i"
            [hover]="true"
            [elevation]="3"
            (click)="onTapOverview()"
          >
            <div class="news-widget__extra-card">{{ buttonText | translate }}</div>
          </rs-web-card>
        </ng-template>
      </ng-container>
    </div>
  </div>
  <div *ngIf="(newsActionState$ | async).pending" class="d-flex justify-content-center m-5">
    <rs-web-loader></rs-web-loader>
  </div>
</div>
