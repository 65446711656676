import {
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  EventEmitter,
  inject,
  Input,
  Output,
  QueryList,
  ViewChild,
} from '@angular/core';
import { NgbActiveOffcanvas, NgbNav, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { NgTemplateOutlet } from '@angular/common';
import { SideSheetContentDirective } from './directives';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonWebComponent } from '../../atoms';
import { ButtonTypeEnum } from '@resident-nx/shared';

@Component({
  selector: 'rs-web-side-sheet-form',
  standalone: true,
  templateUrl: './side-sheet-form.component.html',
  styleUrl: './side-sheet-form.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: 'rsWebSideSheetForm',
  imports: [
    NgbNavModule,
    NgTemplateOutlet,
    SideSheetContentDirective,
    TranslateModule,
    ButtonWebComponent,
  ],
})
export class SideSheetFormComponent {
  #activeOffCanvas = inject(NgbActiveOffcanvas);
  @Input() showBackButton = true;
  @Input() loading: boolean;
  @Input() doneButtonText = 'send';
  @Output() dismiss = new EventEmitter<NgbActiveOffcanvas>();
  @ViewChild('nav') ngbNav: NgbNav;
  @ContentChildren(SideSheetContentDirective)
  contents: QueryList<SideSheetContentDirective>;

  public onDismiss() {
    this.dismiss.emit(this.#activeOffCanvas);
  }

  public nextTab() {
    this.ngbNav.select(this.ngbNav.activeId + 1);
  }

  onNext() {
    this.contents.get(this.ngbNav.activeId).next.emit();
  }
  public onDone() {
    this.contents.get(this.ngbNav.activeId).done.emit(this.#activeOffCanvas);
  }

  protected readonly ButtonTypeEnum = ButtonTypeEnum;
}
