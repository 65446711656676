// eslint-disable-next-line @nx/enforce-module-boundaries
import { StorageService } from '@resident-nx/shared';

export class StorageWebService extends StorageService {
  protected constructor(private storage: Storage) {
    super();
  }

  public getItem(key: string): object | string | null {
    const value = this.storage.getItem(key);
    try {
      return JSON.parse(value || null);
    } catch (e) {
      return value;
    }
  }

  public setItem(key: string, value: object | string): boolean {
    try {
      const val = JSON.stringify(value);
      this.storage.setItem(key, val);
      return true;
    } catch (e) {
      return false;
    }
  }

  public removeItem(key: string): boolean {
    try {
      this.storage.removeItem(key);
      return true;
    } catch (e) {
      return false;
    }
  }

  public clear(): boolean {
    this.storage.clear();
    return true;
  }
}
