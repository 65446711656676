import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'excerpt',
})
export class ExcerptPipe implements PipeTransform {
  transform(value: string, maxLength: number): string {
    value = value.replace(/<[^>]*>?/gm, '');
    const suffix = value && value.length > maxLength ? '...' : '';
    return value.slice(0, maxLength) + suffix;
  }
}
