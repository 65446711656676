import { environmentStg, IEnvironment } from '@resident-nx/shared';

export const environment: IEnvironment = {
  ...environmentStg,
  appName: 'saas',
  features: {
    search: false,
  },
  translationKey: 'resident_saas',
  legalInfoHrefs: {
    imprint: 'https://www.mieter.immomio.com/impressum',
    privacy: 'https://www.mieter.immomio.com/datenschutz',
  },
  graphqlServerUri: 'https://api-rs.stg.immomio.com/graphql',
  graphqlHost: 'api-rs.stg.immomio.com',
  filerUrl: 'https://filer-rs.stg.immomio.com/',
  appUrlHomepage: 'http://homepage.stg.immomio.com',
};
