export * from './documents.component';
export * from './document-mobile/document-mobile.component';
export * from './document-preview-modal/document-preview-modal.component';

import { DocumentsWebComponent } from './documents.component';
import { MobileDocumentsWebComponent } from './document-mobile/document-mobile.component';
import { DocumentWebPreviewModalComponent } from './document-preview-modal/document-preview-modal.component';

export const documentComponents = [
  DocumentsWebComponent,
  MobileDocumentsWebComponent,
  DocumentWebPreviewModalComponent,
];
