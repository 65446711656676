import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, of, switchMap } from 'rxjs';
import { catchError } from 'rxjs/operators';

import * as fromActions from './app-settings.actions';
import { AppSettingsFacade } from '../../../services/general/app-settings';
import { SetSelectedCustomerIdent } from '../../account';

@Injectable()
export class AppSettingsEffects {
  constructor(
    private actions$: Actions,
    private appSettingsFacade: AppSettingsFacade
  ) {}

  loadAppSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.LoadAppSettings),
      switchMap(() =>
        this.appSettingsFacade.getAppSettings().pipe(
          map(appSettings => fromActions.LoadAppSettingsSuccess({ appSettings })),
          catchError(error => [fromActions.LoadAppSettingsFailed(error)])
        )
      )
    )
  );

  // if a customerIdent gets selected a new theme will be loaded and vie the theme.service applied
  loadAppSettingsOnChangeOfSelectedCustomerIdent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SetSelectedCustomerIdent),
      switchMap(() => of(fromActions.LoadAppSettings()))
    )
  );
}
