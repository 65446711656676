import { ChangeDetectionStrategy, Component, inject, input, signal } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonWebComponent, IconWebComponent, ImageWebComponent } from '../../../atoms';
import {
  AddressPipe,
  ButtonTypeEnum,
  IContactPersonResponse,
  ImmomioIconName,
} from '@resident-nx/shared';
import { NgTemplateOutlet } from '@angular/common';
import { ContactDetailsWebComponent } from '../../contact-details/contact-details.component';
import { NgbActiveOffcanvas } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'rs-web-ticket-direct-assignment',
  standalone: true,
  templateUrl: './ticket-direct-assignment.component.html',
  styleUrl: './ticket-direct-assignment.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    TranslateModule,
    ImageWebComponent,
    IconWebComponent,
    AddressPipe,
    NgTemplateOutlet,
    IconWebComponent,
    ButtonWebComponent,
    ContactDetailsWebComponent,
  ],
})
export class TicketDirectAssignmentComponent {
  #activeOffCanvas = inject(NgbActiveOffcanvas);
  public title = input.required<string>();
  public contacts = input.required<IContactPersonResponse[]>();
  public selectedContact = signal<IContactPersonResponse | undefined>(undefined);
  protected readonly ImmomioIconName = ImmomioIconName;

  public dismiss() {
    this.#activeOffCanvas.dismiss();
  }

  protected readonly ButtonTypeEnum = ButtonTypeEnum;
}
