<div class="modal-xs">
  <div class="modal-body confirmation-dialog__body">
    <p class="title-m confirmation-dialog__title">
      {{ titleMessage | translate: { value: titleValue } }}
    </p>
    <p class="default-s confirmation-dialog__message" *ngIf="!innerHTML; else useInnerHTML">
      {{ message | translate: { value: messageValue } }}
    </p>
    <ng-template #useInnerHTML>
      <p
        class="default-s confirmation-dialog__message"
        [innerHTML]="message | translate: { value: messageValue }"
      ></p>
    </ng-template>
  </div>

  <div class="modal-footer confirmation-dialog__footer">
    <rs-web-button
      *ngIf="!acknowledge"
      (tapped)="cancelAction()"
      [type]="buttonTypes.LIGHT_BORDERED"
      >{{ cancelButtonMessage | translate }}</rs-web-button
    >
    <rs-web-button (tapped)="okAction()">{{ okButtonMessage | translate }}</rs-web-button>
  </div>
</div>
