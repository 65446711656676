<rs-web-card [hover]="true" [elevation]="2">
  <rs-web-status-badge class="d-block mb-2" [state]="ticket.state"></rs-web-status-badge>
  <rs-web-label
    [text]="
      ticket.category?.name ||
      ticket.title ||
      ('tickets.issue_from.' + ticket.issueType.toLowerCase() | translate) +
        ' ' +
        (ticket.created ? (ticket.created.date | date: 'dd.MM.yyyy') : '')
    "
    className="h4"
  ></rs-web-label>
  <hr />
  <div class="d-flex align-items-start">
    <rs-web-icon [icon]="immomioIconName.Calendar" [size]="20"></rs-web-icon>
    <div class="secondary-text ms-2">{{ ticket.created.date | date: 'EEEEEE dd.MM.yyyy' }}</div>
  </div>
</rs-web-card>
