import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { ROUTE } from '../../../models/navigation.model';
import { NewsOverviewBaseComponent } from '@resident-nx/shared';
import { Store } from '@ngrx/store';

@Component({
  selector: 'rs-web-news-overview',
  templateUrl: './news-overview.component.html',
  styleUrls: ['./news-overview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewsOverviewWebComponent extends NewsOverviewBaseComponent {
  constructor(
    store: Store,
    private router: Router
  ) {
    super(store);
  }

  public onTapAnnouncement(announcementId: number) {
    this.router.navigate([ROUTE.NEWS, ROUTE.ANNOUNCEMENTS, announcementId]);
    // announcement have a string id for some reason!?
  }

  public onTapOffer(offerId: number) {
    this.router.navigate([ROUTE.NEWS, ROUTE.OFFERS, offerId]);
  }

  public onTapCurrentNews(newsId: number) {
    this.router.navigate([ROUTE.NEWS, ROUTE.CURRENT_NEWS, newsId]);
  }

  public onTapAnnouncementOverview() {
    this.router.navigate([ROUTE.NEWS, ROUTE.ANNOUNCEMENTS]);
  }

  public onTapOfferOverview() {
    this.router.navigate([ROUTE.NEWS, ROUTE.OFFERS]);
  }

  public onTapOfferHomepage() {
    this.router.navigate([ROUTE.NEWS, ROUTE.OBJECTS]);
  }

  public onTapCurrentNewsOverview() {
    this.router.navigate([ROUTE.NEWS, ROUTE.CURRENT_NEWS]);
  }
}
