<div class="homepage container container--with-spacing">
  <div class="row">
    <!--TODO translation-->
    <span class="title-xxl col homepage__header">{{ 'news.offers_l' | translate }}</span>
  </div>
  <div class="row">
    <iframe
      #iframe
      *ngIf="url$ | async"
      [src]="url$ | async"
      [height]="iFrameHeight"
      width="100%"
      style="border: 0; overflow: hidden"
      (load)="onLoad()"
    ></iframe>
    <div *ngIf="(url$ | async) === undefined" class="d-flex justify-content-center">
      <rs-web-loader></rs-web-loader>
    </div>
  </div>
</div>
