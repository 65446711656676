<div *ngIf="item">
  <div>
    <rs-web-label [text]="item.title" className="h4 text-primary"></rs-web-label>
  </div>
  <div>
    <rs-web-label [text]="item.text | excerpt: 95" className="body text-secondary"></rs-web-label>
  </div>
  <div class="bg-primary-10 border-radius-bottom">
    <rs-web-card-footer [publishedAt]="item.publishedAt"></rs-web-card-footer>
  </div>
</div>
