import { Component } from '@angular/core';
import { ConsumptionWidgetBaseComponent, ImmomioIconName } from '@resident-nx/shared';
import { ContractMenuDesign } from '../contract-menu/contract-menu.component';

@Component({
  selector: 'rs-web-consumption-widget',
  templateUrl: './consumption-widget.component.html',
  styleUrls: ['./consumption-widget.component.scss'],
})
export class ConsumptionWidgetWebComponent extends ConsumptionWidgetBaseComponent {
  public contractMenuDesign = ContractMenuDesign;
  protected readonly ImmomioIconName = ImmomioIconName;
}
