import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonTypeEnum, TicketDetailsOverviewHQBaseComponent } from '@resident-nx/shared';
import {
  ButtonWebComponent,
  IconWebComponent,
  LabelWebComponent,
  PictureWebComponent,
  TicketImageViewerComponent,
} from '../../../../atoms';

@Component({
  selector: 'rs-web-ticket-details-overview-hq',
  templateUrl: './ticket-details-overview-hq.component.html',
  styleUrls: ['./ticket-details-overview-hq.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    PictureWebComponent,
    IconWebComponent,
    LabelWebComponent,
    ButtonWebComponent,
    TicketImageViewerComponent,
  ],
})
export class TicketDetailsOverviewHQWebComponent extends TicketDetailsOverviewHQBaseComponent {
  public buttonTypeEnum = ButtonTypeEnum;
}
