<div class="list-navigation__container">
  <div class="list-navigation d-flex">
    <ng-container *ngFor="let nav of navConfig; index as i">
      <div class="nav-item" [routerLinkActive]="'active'" (click)="onNavSelect(nav)">
        <div [routerLink]="nav.url" class="nav-item__name">
          {{ nav.label | translate }}
        </div>
      </div>
    </ng-container>
  </div>
</div>
