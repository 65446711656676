<div class="pie-chart">
  <div class="pie-chart__pie" [style]="{ 'max-width': width + 'px', 'max-height': height + 'px' }">
    <apx-chart
      [series]="chartOptions.series"
      [chart]="chartOptions.chart"
      [legend]="chartOptions.legend"
      [colors]="chartOptions.colors"
      [dataLabels]="chartOptions.dataLabels"
      [plotOptions]="chartOptions.plotOptions"
      [tooltip]="chartOptions.tooltip"
      [states]="chartOptions.states"
    ></apx-chart>
  </div>

  <div class="pie-chart__legend-below mt-4">
    <div *ngFor="let item of data.set" class="pie-chart__legend-item">
      <div class="pie-chart__legend-item-left">
        <div class="pie-chart__legend-item-dot" [style]="{ 'background-color': item.color }"></div>
        <div>{{ item.label | translate }}</div>
      </div>
      <div class="pie-chart__legend-item-right">
        {{ item.amount | displayCurrency: 'EUR' }}
      </div>
    </div>
  </div>
</div>
