import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';

import {
  collapseXAnimation,
  CollapseXAnimationStateEnum,
  FadeAnimationStateEnum,
  innerFadeAnimation,
  slideAnimation,
  SlideAnimationStateEnum,
} from '../../../../utils';
import { NavigationService } from '../navigation.service';
import { NavigationItem } from '../../../../models';

import { combineLatest } from 'rxjs';
import { NavigationAlignment } from '../navigation.model';
import { NavigationDrawerService } from './navigation-drawer.service';
import { ImmomioIconName } from '@resident-nx/shared';

@Component({
  selector: 'rs-web-navigation-drawer',
  templateUrl: './navigation-drawer.component.html',
  styleUrls: ['./navigation-drawer.component.scss'],
  animations: [slideAnimation, innerFadeAnimation, collapseXAnimation],
  providers: [NavigationDrawerService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationDrawerComponent implements OnInit, OnDestroy {
  @Input() items: NavigationItem[];

  /* eslint-disable @angular-eslint/no-input-rename */

  /**
   * Toggleable narrow mode if overall navigation is not an overlay. Will only work as long as there is an icon for each navigation item provided and this menu is not a floating sub menu.
   */
  @Input('narrow') _narrow: boolean;

  /**
   * Declares this menu as collapsable floating sub menu.
   */
  @Input('isFloatingSubMenu') _isFloatingSubMenu: boolean;

  /* eslint-enable @angular-eslint/no-input-rename */

  public widthNarrow = 56;
  public widthWide = 200;

  public get slideAnimationState$() {
    return combineLatest(
      [
        this.navigation.alignment$,
        this.navigationDrawer.open$,
        this.navigationDrawer.isFloatingSubMenu$,
      ],
      (alignment, open, isFloatingSubMenu): SlideAnimationStateEnum => {
        if (open) {
          return SlideAnimationStateEnum.OPEN;
        } else if (isFloatingSubMenu) {
          return alignment === NavigationAlignment.LEFT
            ? SlideAnimationStateEnum.CLOSED_LEFT_WITH_OFFSET
            : SlideAnimationStateEnum.CLOSED_RIGHT_WITH_OFFSET;
        } else {
          return alignment === NavigationAlignment.LEFT
            ? SlideAnimationStateEnum.CLOSED_LEFT
            : SlideAnimationStateEnum.CLOSED_RIGHT;
        }
      }
    );
  }

  public get showNarrowToggleContainer$() {
    return combineLatest(
      [
        this.navigation.mobileView$,
        this.navigationDrawer.isFloatingSubMenu$,
        this.navigationDrawer.collapsable$,
      ],
      (mobileView, isFloatingSubMenu, collapsable) =>
        !mobileView && !isFloatingSubMenu && collapsable
    );
  }

  constructor(
    public navigation: NavigationService,
    public navigationDrawer: NavigationDrawerService
  ) {}

  public ngOnInit() {
    this.navigationDrawer.init({
      isFloatingSubMenu: this._isFloatingSubMenu,
      narrow: this._narrow,
    });
  }

  public ngOnDestroy() {
    this.navigationDrawer.complete();
  }

  public getNarrowToggleItem(alignment: NavigationAlignment, narrow: boolean): NavigationItem {
    if (narrow) {
      return {
        name: 'expand',
        label: 'navigation.expand_sidebar_a',
        icon:
          alignment === NavigationAlignment.LEFT
            ? ImmomioIconName.ArrowRight
            : ImmomioIconName.ArrowLeft,
      };
    } else {
      return {
        name: 'collapse',
        label: 'navigation.collapse_sidebar_a',
        icon:
          alignment === NavigationAlignment.LEFT
            ? ImmomioIconName.ArrowLeft
            : ImmomioIconName.ArrowRight,
      };
    }
  }

  public collapseXWidth(narrow: boolean): string {
    return `${narrow ? this.widthNarrow : this.widthWide}px`;
  }

  public collapseXAnimationState(narrow: boolean): CollapseXAnimationStateEnum {
    if (narrow) {
      return CollapseXAnimationStateEnum.OPEN;
    } else {
      return CollapseXAnimationStateEnum.CLOSED;
    }
  }

  public innerFadeAnimationState(isOpen: boolean): FadeAnimationStateEnum {
    if (isOpen) {
      return FadeAnimationStateEnum.SHOW;
    } else {
      return FadeAnimationStateEnum.HIDE;
    }
  }

  public onNarrowToggleClick(narrow: boolean): void {
    if (narrow) {
      this.navigationDrawer.widen();
    } else {
      this.navigationDrawer.tighten();
    }

    this.navigation.setNarrowManualChange(!narrow);
  }
}
