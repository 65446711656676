import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { TicketCreationService } from '../../../../../services';
import { ControlContainer, ReactiveFormsModule } from '@angular/forms';
import { ImmomioIconName, ScoreQuestionType } from '@resident-nx/shared';
import { NgTemplateOutlet } from '@angular/common';
import {
  ControlErrorContainerDirective,
  FileUploadValueAccessorDirective,
  FormControlErrorsDirective,
} from '../../../../../directives';
import {
  DatePickerComponent,
  IconWebComponent,
  ImageBorderRadiusEnum,
  ImageWebComponent,
} from '../../../../atoms';
import { FileListPipe } from '../../../../../pipes';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'rs-web-ticket-detail-questions',
  standalone: true,
  templateUrl: './ticket-detail-questions.component.html',
  styleUrl: './ticket-detail-questions.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders: [
    {
      provide: ControlContainer,
      useFactory: () => inject(ControlContainer, { skipSelf: true }),
    },
  ],
  imports: [
    NgTemplateOutlet,
    ReactiveFormsModule,
    FormControlErrorsDirective,
    FileUploadValueAccessorDirective,
    ControlErrorContainerDirective,
    IconWebComponent,
    ImageWebComponent,
    FileListPipe,
    DatePickerComponent,
    TranslateModule,
  ],
})
export class TicketDetailQuestionsComponent {
  #ticketCreationService = inject(TicketCreationService);
  public detailQuestionsResponse = this.#ticketCreationService.detailQuestionsResponse;
  public formArray = this.#ticketCreationService.detailQuestionsFormArray;
  protected readonly ScoreQuestionType = ScoreQuestionType;
  protected readonly ImmomioIconName = ImmomioIconName;
  protected readonly ImageBorderRadiusEnum = ImageBorderRadiusEnum;
}
