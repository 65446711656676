import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { ImmomioIconName, SortingMenuBaseComponent } from '@resident-nx/shared';
import { IconWebComponent, LabelWebComponent } from '../../atoms';
import { ContractMenuWebComponent } from '../contract-menu/contract-menu.component';

@Component({
  selector: 'rs-web-sorting-menu',
  standalone: true,
  imports: [
    IconWebComponent,
    LabelWebComponent,
    NgbDropdownModule,
    TranslateModule,
    AsyncPipe,
    ContractMenuWebComponent,
  ],
  templateUrl: './sorting-menu.component.html',
  styleUrl: './sorting-menu.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SortingMenuComponent extends SortingMenuBaseComponent implements OnInit {
  public ImmomioIconName = ImmomioIconName;
}
