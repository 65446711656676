import { Directive, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';

import { ImmomioIconName } from '../../../models';
import { RouterService } from '../../../services';
import { LoadActiveRequestTickets, LoadArchiveRequestTickets } from '../../../+state';

@Directive()
export abstract class TicketsOverviewRequestPageBaseComponent {
  public archiveViewOpened = false;
  public immomioIconName = ImmomioIconName;
  @Input() contractId: string;

  constructor(
    protected routerService: RouterService,
    protected route: ActivatedRoute,
    protected store: Store
  ) {}

  protected reloadTickets(): void {
    // TODO: IMPROVEMENT check pagination before reloading
    if (this.archiveViewOpened) {
      this.store.dispatch(LoadArchiveRequestTickets({ offset: 0, limit: 100 }));
    }
    this.store.dispatch(LoadActiveRequestTickets({ offset: 0, limit: 100 }));
  }
}
