import { Component, ViewChild, ChangeDetectionStrategy, Input, OnChanges } from '@angular/core';
import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexStroke,
  ApexFill,
  ApexXAxis,
} from 'ng-apexcharts';
import { ConsumptionChartOptions } from '../../molecules/consumption-chart/types/consumption-chart-options';
import { ConsumptionSeriesIndex, IConsumptionItem, NgChanges } from '@resident-nx/shared';
import { format, getDaysInMonth, lastDayOfMonth } from 'date-fns';
import { de } from 'date-fns/locale';
import { UpdateSeriesParams } from '../../molecules/consumption-chart/consumption-chart.service';

@Component({
  selector: 'rs-web-consumption-chart-month',
  templateUrl: './consumption-chart-month.component.html',
  styleUrls: ['./consumption-chart-month.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConsumptionChartMonthWebComponent implements OnChanges {
  @Input() options: ConsumptionChartOptions;
  @Input() consumption: IConsumptionItem[];
  @Input() pastConsumption: IConsumptionItem[];

  @ViewChild(ChartComponent) chart: ChartComponent;

  public series: ApexAxisChartSeries;
  public fill: ApexFill;
  public stroke: ApexStroke;
  public colors: string[];

  constructor() {
    this.initChart();
  }

  ngOnChanges(changes: NgChanges<ConsumptionChartMonthWebComponent>): void {
    if (changes.options) {
      this.options = this.updateXAxisOption(this.options);
      this.options = this.updateChartType(this.options);
    }

    if (changes.consumption) {
      this.options = this.updateXAxisOption(this.options);
      this.series = this.updateSeries({
        series: this.series,
        indexToUpdate: ConsumptionSeriesIndex.CONSUMPTION,
        data: this.consumption,
      });
    }

    if (changes.pastConsumption) {
      this.series = this.updateSeries({
        series: this.series,
        indexToUpdate: ConsumptionSeriesIndex.PAST_CONSUMPTION,
        data: this.pastConsumption,
      });
    }
  }

  private initChart(): void {
    this.series = [
      { name: 'Previous', data: [] },
      { name: 'Selected', data: [] },
    ];
    const previousYearSeriesColor = 'var(--secondary-default, #193F70)';
    const selectedYearSeriesColor = 'var(--primary-default, #3486EF)';

    this.colors = [previousYearSeriesColor, selectedYearSeriesColor];
    this.fill = {
      type: ['solid', 'gradient'],
      gradient: {
        type: 'vertical',
        colorStops: [
          {
            offset: 0,
            color: selectedYearSeriesColor,
            opacity: 0.8,
          },
          {
            offset: 80,
            color: selectedYearSeriesColor,
            opacity: 0.2,
          },
          {
            offset: 100,
            color: selectedYearSeriesColor,
            opacity: 0.1,
          },
        ],
      },
    };
    this.fill = {
      type: ['solid', 'gradient'],
      gradient: {
        gradientToColors: [selectedYearSeriesColor],
        type: 'vertical',
        shadeIntensity: 0.5,
        opacityFrom: 0.1,
        opacityTo: 0,
        stops: [0, 80, 100],
      },
    } as ApexFill; // typing are broken in current ng-apexcharts, we need to cast until fixed ;
    this.stroke = {
      width: 1,
      curve: 'smooth',
      dashArray: [4, 0],
    };
  }

  private createXAxis(shared: ApexXAxis, isMobile = false): ApexXAxis {
    const [firstEntry] = this.consumption;
    const referenceTimestamp = firstEntry?.period?.start || new Date().getTime();
    const referenceDate = new Date(referenceTimestamp);
    return {
      ...shared,
      type: 'category',
      categories: this.createMonthCategories(referenceDate, isMobile),
      labels: {
        ...shared.labels,
      },
    };
  }

  private updateSeries({ series, indexToUpdate, data }: UpdateSeriesParams): ApexAxisChartSeries {
    if (!Array.isArray(data) || data.length === 0) {
      series[indexToUpdate].name = '';
      series[indexToUpdate].data = Array.from({
        length: this.options?.xaxis?.categories?.length,
      }).map(() => null);
      return [...series];
    }
    const [firstEntry] = data;
    const year = new Date(firstEntry.period.start).getFullYear();
    series[indexToUpdate].name = `${year}`;

    const daysInMonth = getDaysInMonth(firstEntry.period.start);
    const oneEntryForEachDay = Array.from({ length: daysInMonth }).map(() => null);

    for (const { amount, period } of data) {
      const dayIndexOfConsumption = new Date(period.start).getDate() - 1;
      oneEntryForEachDay[dayIndexOfConsumption] = parseFloat(amount.toFixed(2));
    }

    series[indexToUpdate].type =
      indexToUpdate === ConsumptionSeriesIndex.CONSUMPTION ? 'area' : 'line';

    series[indexToUpdate].data = oneEntryForEachDay;
    if (indexToUpdate === ConsumptionSeriesIndex.CONSUMPTION) {
      series[indexToUpdate].type = 'area';
    }
    return [...series];
  }

  private createMonthCategories(date: Date, isMobile = false): string[] {
    const numberOfDays = getDaysInMonth(date);

    const monthsLastDay = lastDayOfMonth(date).getDate();
    const labelsToShow = isMobile ? [1, 15, monthsLastDay] : [1, 5, 10, 15, 20, 25, monthsLastDay];

    return Array.from({ length: numberOfDays })
      .map((_value, index) => index + 1)
      .map(dayInMonth => {
        date.setDate(dayInMonth);
        if (labelsToShow.includes(date.getDate())) {
          return format(date, 'd. LLL', { locale: de });
        }

        return '';
      });
  }

  private updateXAxisOption(options: ConsumptionChartOptions): ConsumptionChartOptions {
    const breakpoint = 575;
    return {
      ...options,
      xaxis: this.createXAxis(options.xaxis),
      responsive: [
        {
          breakpoint,
          options: {
            xaxis: this.createXAxis(options.xaxis, true),
          },
        },
      ],
    };
  }

  private updateChartType(options: ConsumptionChartOptions): ConsumptionChartOptions {
    return {
      ...options,
      chart: {
        ...options.chart,
        type: 'line',
      },
    };
  }
}
