import { AvatarWebComponent } from './avatar/avatar.component';
import { BreadcrumbsWebComponent } from './breadcrumbs/breadcrumbs.component';
import { ConsumptionChartMonthWebComponent } from './consumption-chart-month/consumption-chart-month.component';
import { ConsumptionChartQuarterWebComponent } from './consumption-chart-quarter/consumption-chart-quarter.component';
import { ConsumptionChartYearWebComponent } from './consumption-chart-year/consumption-chart-year.component';
import { ConsumptionRowWebComponent } from './consumption-row/consumption-row.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderWebOldComponent } from './header-old/header-old.component';
import { InfiniteScrollWebComponent } from './infinite-scroll/infinite-scroll.component';
import { NavigationItemWebComponent } from './navigation-item/navigation-item.component';
import { TabBarWebComponent } from './tab-bar/tab-bar.component';

export { ButtonWebComponent } from './button/button.component';
export { CardWebComponent } from './card/card.component';
export { IconWebComponent } from './icon/icon.component';
export { ImageWebComponent } from './image/image.component';
export { LabelWebComponent } from './label/label.component';
export { LoaderWebComponent } from './loader/loader.component';
export { StatusBadgeWebComponent } from './status-badge/status-badge.component';
export { HeadlineWebComponent } from './headline/headline.component';
export { ToastsContainerComponent } from './toast/toast.component';
export { LogoComponent } from './logo/logo.component';

export * from './avatar/avatar.component';
export * from './button/button.component';
export * from './chip/chip.component';
export * from './consumption-chart-month/consumption-chart-month.component';
export * from './consumption-chart-quarter/consumption-chart-quarter.component';
export * from './consumption-chart-year/consumption-chart-year.component';
export * from './consumption-row/consumption-row.component';
export { DatePickerComponent } from './date-picker/date-picker.component';
export * from './footer/footer.component';
export * from './form';
export * from './header-old/header-old.component';
export * from './headline/headline.component';
export * from './icon/icon.component';
export * from './image/image.model';
export * from './label/label.component';
export * from './list-navigation/list-navigation.component';
export * from './navigation-item/navigation-item.component';
export * from './no-data/no-data.component';
export * from './picture/picture.component';
export * from './status-badge/status-badge.component';
export * from './tab-bar/tab-bar.component';
export * from './ticket-image-viewer/ticket-image-viewer.component';

export const ATOMS = [
  HeaderWebOldComponent,
  NavigationItemWebComponent,
  TabBarWebComponent,
  FooterComponent,
  BreadcrumbsWebComponent,
  InfiniteScrollWebComponent,
  AvatarWebComponent,
  ConsumptionChartMonthWebComponent,
  ConsumptionChartYearWebComponent,
  ConsumptionChartQuarterWebComponent,
  ConsumptionRowWebComponent,
];
