import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NavigationItem } from '../../../../models';

@Component({
  selector: 'rs-web-mobile-navigation',
  templateUrl: './mobile-navigation.component.html',
  styleUrls: ['./mobile-navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileNavigationComponent {
  @Input() navigationItems: NavigationItem[];
}
