import { Inject, Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, switchMap, take } from 'rxjs';
import { AuthTokenService } from '../../infrastructure';
import { Store } from '@ngrx/store';
import { getSelectedCustomerIdent } from '../../+state';
import { IEnvironment } from '../../models';
import * as fromRegisterState from '../../../shared';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private authTokenService: AuthTokenService,
    private store: Store,
    @Inject('ENVIRONMENT') private environment: IEnvironment
  ) {}
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.url.indexOf('/graphql') > -1) {
      return this.store.select(getSelectedCustomerIdent).pipe(
        take(1),
        switchMap(response => {
          const authToken = this.authTokenService.getToken();

          if (authToken?.accessToken) {
            request = request.clone({
              setHeaders: {
                Authorization: `Bearer ${String(authToken.accessToken)}`,
              },
            });
          }
          if (response) {
            request = request.clone({
              setHeaders: {
                'X-Customer-Ident': response,
              },
            });
          } else {
            return this.store.select(fromRegisterState.getInvitationCodeResponse).pipe(
              take(1),
              switchMap(response => {
                if (response) {
                  request = request.clone({
                    setHeaders: {
                      'X-Customer-Ident': response.customerIdent,
                    },
                  });
                } else if (this.environment.customerIdent) {
                  request = request.clone({
                    setHeaders: {
                      'X-Customer-Ident': this.environment.customerIdent,
                    },
                  });
                }
                return next.handle(request);
              })
            );
          }

          return next.handle(request);
        })
      );
    }

    return next.handle(request);
  }
}
