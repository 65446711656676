export abstract class NewRelicService {
  abstract initialize(args: NewRelicOptions): void;
  abstract startInteraction(action: string): string;
  abstract stopInteraction(action: string): void;
  abstract recordHttpFailure(
    url: string,
    httpMethod: string,
    startTime: number,
    endTime: number,
    failureCode: number
  ): void;
  abstract recordError(error: string): void;
}

export interface NewRelicOptions {
  token: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  context?: any; //android Context;
}
