import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs';

import { NotificationSettingsFacade } from '../../../services';
import * as fromActions from './notification-settings.actions';

@Injectable()
export class NotificationSettingsEffects {
  constructor(
    private actions$: Actions,
    private notificationSettingsFacade: NotificationSettingsFacade
  ) {}

  loadNotificationSettings$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.LoadNotificationSettings),
      switchMap(() =>
        this.notificationSettingsFacade.getNotificationSettings().pipe(
          map(success => fromActions.LoadNotificationSettingsSuccess({ success })),
          catchError(error => [fromActions.LoadNotificationSettingsFailed({ error })])
        )
      )
    );
  });

  changeNotificationSettings$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.ChangeNotificationSettings),
      switchMap(({ payload }) =>
        this.notificationSettingsFacade.updateNotificationSettings(payload).pipe(
          map(response => fromActions.ChangeNotificationSettingsSuccess({ response })),
          catchError(error => [fromActions.ChangeNotificationSettingsFailed({ error })])
        )
      )
    );
  });
}
