import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
  Input,
  OnChanges,
  OnInit,
} from '@angular/core';
import {
  ConsumptionAggregateType,
  ConsumptionTimeframe,
  ConsumptionType,
  ConsumptionUnitType,
  IConsumptionInfoItem,
  IConsumptionState,
  NameDisplayMode,
  NgChanges,
  SelectPeriod,
} from '@resident-nx/shared';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { endOfMonth, endOfYear, startOfMonth, startOfYear } from 'date-fns';
import { FormControl } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

type PossibleTimeframes = ConsumptionTimeframe.MONTH | ConsumptionTimeframe.YEAR;

@Component({
  selector: 'rs-web-consumption-card',
  templateUrl: './consumption-card.component.html',
  styleUrls: ['./consumption-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConsumptionCardWebComponent implements OnInit, OnChanges {
  public readonly NameDisplayMode = NameDisplayMode;
  public readonly ConsumptionAggregateType = ConsumptionAggregateType;
  private destroyRef = inject(DestroyRef);

  // fallback
  @Input() meter: IConsumptionInfoItem = {
    deviceId: '',
    currentValue: 0,
    defaultAggregation: ConsumptionAggregateType.DAY,
    updateInterval: 0,
    type: ConsumptionType.POWER,
    measurementUnit: ConsumptionUnitType.M3,
    max: 0,
    firstEntry: 0,
    lastEntry: 0,
  };

  public selectedTimeframe$ = new BehaviorSubject<PossibleTimeframes>(ConsumptionTimeframe.YEAR);
  public selectedDateControl: FormControl<Date> = new FormControl<Date>(null);

  constructor(private store: Store<IConsumptionState>) {}

  ngOnInit(): void {
    combineLatest([this.selectedDateControl.valueChanges, this.selectedTimeframe$])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(([date, timeframe]) => this.selectPeriod(date, timeframe));

    this.selectedDateControl.setValue(new Date());
  }

  ngOnChanges(changes: NgChanges<ConsumptionCardWebComponent>): void {
    if (changes.meter && !changes.meter.firstChange) {
      // needed for mobile view, as meter for card changes dynamically by tab select
      this.selectedDateControl.setValue(new Date());
    }
  }

  private selectPeriod(date: Date, timeframe: PossibleTimeframes): void {
    const isMonthSelected = timeframe === ConsumptionTimeframe.MONTH;
    const { deviceId } = this.meter;
    const start = isMonthSelected ? startOfMonth(date) : startOfYear(date);
    const end = isMonthSelected ? endOfMonth(date) : endOfYear(date);
    if (deviceId) {
      this.store.dispatch(SelectPeriod({ deviceId, start, end }));
    }
  }

  onTimeframeSelect(timeframe: PossibleTimeframes) {
    this.selectedTimeframe$.next(timeframe);
  }
}
