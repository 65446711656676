<div class="d-flex gap-2 mb-2">
  <rs-web-icon [icon]="immomioIconName.Calendar" [size]="16"></rs-web-icon>
  <div>{{ ticketDetails.created?.date | date: 'EEEEEE dd.MM.yyyy' }}</div>
</div>

@if (questionsAndAnswers.length > 0) {
  <div class="mb-3 h4">{{ 'damage.damage_details' | translate }}</div>
  <div>
    @for (response of questionsAndAnswers.slice(0, shownAnswersCount); track response) {
      @if (response.question?.length) {
        <div class="mb-2">
          <rs-web-label [text]="response.question" className="secondary-text"></rs-web-label>
        </div>
      }

      @switch (response.type) {
        @case (scoreQuestionType.INPUT_TEXT) {
          <div class="mb-3">{{ response.textAnswer }}</div>
        }
        @case (scoreQuestionType.INPUT_DATE) {
          <div class="mb-3">{{ response.dateAnswer | date: 'dd.MM.yyyy' }}</div>
        }
        @case (scoreQuestionType.INPUT_ATTACHMENTS) {
          <div class="row gy-4 mb-3">
            @for (s3File of response.attachments; track s3File) {
              <div class="col-4">
                <rs-web-ticket-image-viewer
                  [ticketId]="ticketDetails.id"
                  [attachmentIdentifier]="s3File.identifier"
                ></rs-web-ticket-image-viewer>
              </div>
            }
          </div>
        }
        @case (scoreQuestionType.SELECTION) {
          <div class="mb-3">{{ response.selectionAnswer }}</div>
        }
      }
    }
  </div>
}

@if (showMoreButton) {
  <div class="d-flex">
    <rs-web-button [type]="buttonTypeEnum.LIGHT_BORDERED" (tapped)="showMore()">{{
      ('general.show_more_b' | translate) + ' +'
    }}</rs-web-button>
  </div>
}
