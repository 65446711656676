<div class="announcements-widget">
  <rs-web-cards-container-header
    [icon]="icon"
    [title]="title"
    [buttonText]="'general.overview_a'"
    (tapped)="onTapOverview()"
    [buttonTypeEnum]="ButtonEnumTypes.LIGHT_BORDERED"
  ></rs-web-cards-container-header>
  <div class="announcements-widget__overflow">
    <div class="announcements-widget__content">
      <ng-container *ngFor="let announcement of announcements$ | async; index as i">
        <rs-web-content-card
          *ngIf="announcementsLimit - 1 > i; else moreAnnouncements"
          class="announcements-widget__content-card"
          [id]="announcement.node.id"
          [title]="announcement.node.title"
          [text]="announcement.node.text"
          [date]="announcement.node.publishedAt"
          (tapped)="onTap($event)"
        ></rs-web-content-card>
        <ng-template #moreAnnouncements>
          <rs-web-card
            *ngIf="announcementsLimit - 1 === i"
            [hover]="true"
            [elevation]="3"
            (click)="onTapOverview()"
            class="more-announcements-card"
          >
            <div class="announcements-widget__extra-card">
              {{
                showAmountOfContent
                  ? '+ ' + ((announcements$ | async).length - announcementsLimit + 1)
                  : ''
              }}
              {{ buttonText | translate }}
            </div>
          </rs-web-card>
        </ng-template>
      </ng-container>
    </div>
  </div>
  <div
    *ngIf="(announcementsActionState$ | async).pending"
    class="d-flex justify-content-center m-5"
  >
    <rs-web-loader></rs-web-loader>
  </div>
</div>
