import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';

import { TranslateLoader, TranslateModule, TranslateParser } from '@ngx-translate/core';
import { Apollo, APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { EffectsModule } from '@ngrx/effects';
import { ActionReducer, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { localStorageSync } from 'ngrx-store-localstorage';
import {
  appEffects,
  appReducers,
  AuthInterceptor,
  clearState,
  createDefaultApolloConnection,
  HttpInterceptorService,
  IInfrastructureConfig,
  TranslateParserService,
  TransLoader,
} from '@resident-nx/shared';
import { SharedModule } from './features/shared/shared.module';
import { AppRoutingModule } from './app.routing';
import { AppComponent } from './app.component';
import { AuthenticationModule } from './features/authentication/authentication.module';
import { environment } from '../environments';
import { CUSTOM_TRANSLATIONS } from '../../custom-translations';
import { ENDPOINTS } from '../endpoints';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularSvgIconModule } from 'angular-svg-icon';
import {
  AngularKeycloakService,
  AuthWebGuard,
  INFRASTRUCTURE_CONFIG,
  initializeKeycloak,
  LoginPageGuard,
} from '@resident-nx/web';

const infrastructureConfig: IInfrastructureConfig = {
  environment,
};

export function GqlLoaderFactory(apollo: Apollo, http: HttpClient) {
  return new TransLoader(apollo, http);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({
    keys: [
      { masterData: { encrypt: state => btoa(state), decrypt: state => atob(state) } },
      {
        ['consumption']: [
          'availableMeters',
          'selectedContract',
          'metersByContract',
          'metersByContractActionState',
          'selectedMeters',
          'selectedPeriodConsumptionActionStates',
          'selectedPeriodPastConsumptionActionStates',
          'selectedPeriodConsumptionBenchmarkActionStates',
        ],
      },
      {
        ['userApps']: ['selectedCustomerIdent'],
      },
      'appSettings',
    ],
    rehydrate: true,
  })(reducer);
}

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    SharedModule,
    StoreModule.forRoot(appReducers, { metaReducers: [localStorageSyncReducer, clearState] }),
    EffectsModule.forRoot(appEffects),
    AppRoutingModule,
    AuthenticationModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: GqlLoaderFactory,
        deps: [Apollo],
      },
      parser: { provide: TranslateParser, useClass: TranslateParserService },
    }),
    BrowserAnimationsModule,
    AngularSvgIconModule.forRoot(),
    !environment.production ? StoreDevtoolsModule.instrument({ connectInZone: true }) : [],
  ],
  exports: [TranslateModule],
  declarations: [AppComponent],
  providers: [
    { provide: 'ENVIRONMENT', useValue: environment },
    { provide: 'ENDPOINTS', useValue: ENDPOINTS },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [AngularKeycloakService],
    },
    { provide: INFRASTRUCTURE_CONFIG, useValue: infrastructureConfig },
    Apollo,
    {
      provide: APOLLO_OPTIONS,
      useFactory: createDefaultApolloConnection,
      deps: [HttpLink, INFRASTRUCTURE_CONFIG, 'ENVIRONMENT'],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,
    },
    { provide: LOCALE_ID, useValue: 'de-DE' },
    { provide: 'TRANSLATION_KEY', useValue: environment.translationKey },
    { provide: 'CUSTOM_TRANSLATIONS', useValue: [...CUSTOM_TRANSLATIONS] },
    AuthWebGuard,
    LoginPageGuard,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
