import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs';

import { NotificationsFacade } from '../../services';
import * as fromActions from './notifications.actions';

@Injectable()
export class NotificationsEffects {
  constructor(
    private actions$: Actions,
    private notificationsFacade: NotificationsFacade
  ) {}

  registerDevice$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.RegisterDevice),
      switchMap(({ registerDeviceInput }) =>
        this.notificationsFacade.registerDevice(registerDeviceInput).pipe(
          map(success => fromActions.RegisterDeviceSuccess({ success })),
          catchError(error => [fromActions.RegisterDeviceFailed({ error })])
        )
      )
    );
  });

  unregisterDevice$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.UnregisterDevice),
      switchMap(({ identifier }) =>
        this.notificationsFacade.unregisterDevice(identifier).pipe(
          map(success => fromActions.UnregisterDeviceSuccess({ success })),
          catchError(error => [fromActions.UnregisterDeviceFailed({ error })])
        )
      )
    );
  });
}
