export { NavigationService } from './navigation.service';
import { NavigationComponent } from './navigation.component';
import { navigationDrawerComponents } from './navigation-drawer';
import { NavigationUserProfileComponent } from './navigation-user-profile/navigation-user-profile.component';
import { MobileNavigationComponent } from './mobile-navigation/mobile-navigation.component';

export const navigationComponents = [
  ...navigationDrawerComponents,
  NavigationUserProfileComponent,
  NavigationComponent,
  MobileNavigationComponent,
];
