import {
  ConsumptionIconPipe,
  ConsumptionMonthIsInAvailableDataRangePipe,
  ConsumptionMonthNamePipe,
  ConsumptionNamePipe,
  ConsumptionPeriodPipe,
  ConsumptionStepsPipe,
  ConsumptionUnitTypePipe,
  ConsumptionValuePipe,
  MaxConsumptionPipe,
} from './consumption.pipe';
import { ContractTypeNamePipe } from './contract-name.pipe';
import { DateRangePipe } from './date-range.pipe';
import { DisplayCurrencyPipe } from './display-currency.pipe';
import { ExcerptPipe } from './excerpt.pipe';
import { InputLabelOptionalPipe } from './input-label-optional.pipe';
import { PaymentDueStatusPipe } from './payment-due-status.pipe';
import { PaymentStatusBgPipe } from './payment-status-bg.pipe';
import { PaymentStatusPipe } from './payment-status.pipe';
import { TicketStatusPipe } from './ticket-status.pipe';
import { TicketTitlePipe } from './ticket-title.pipe';
import { UserInitialsPipe, UsernamePipe } from './username.pipe';

export { AddressPipe } from './address.pipe';
export { TimeAgoPipe } from './time-ago.pipe';

export * from './address.pipe';
export * from './consumption.pipe';
export * from './contract-name.pipe';
export * from './date-range.pipe';
export * from './display-currency.pipe';
export * from './excerpt.pipe';
export * from './input-label-optional.pipe';
export * from './payment-status.pipe';
export * from './ticket-status.pipe';
export * from './ticket-title.pipe';
export * from './time-ago.pipe';
export * from './username.pipe';

export const PIPES = [
  ExcerptPipe,
  ConsumptionNamePipe,
  ConsumptionIconPipe,
  ConsumptionPeriodPipe,
  ConsumptionMonthIsInAvailableDataRangePipe,
  ConsumptionUnitTypePipe,
  DisplayCurrencyPipe,
  MaxConsumptionPipe,
  ConsumptionStepsPipe,
  ConsumptionValuePipe,
  UsernamePipe,
  UserInitialsPipe,
  PaymentStatusPipe,
  DateRangePipe,
  ConsumptionMonthNamePipe,
  TicketTitlePipe,
  TicketStatusPipe,
  PaymentStatusBgPipe,
  PaymentDueStatusPipe,
  ContractTypeNamePipe,
  InputLabelOptionalPipe,
];
