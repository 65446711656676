@if (contact.company) {
  <div class="p-b-12">
    <div class="text-secondary text-size-xs">
      {{ 'contact.company_l' | translate }}
    </div>
    <div class="text-size-md">
      {{ contact.company }}
    </div>
  </div>
}

@if (contact.contactData?.phone) {
  <div class="p-b-12">
    <div class="text-secondary text-size-xs">
      {{ 'contact.phone_l' | translate }}
    </div>
    <div class="text-size-md d-flex justify-content-between">
      <div>{{ contact.contactData.phone }}</div>
      <a class="icon-container" [href]="call(contact.contactData.phone)">
        <rs-web-icon [icon]="immomioIconName.Phone" [size]="16"></rs-web-icon>
      </a>
    </div>
  </div>
}

@if (contact.contactData?.mobile) {
  <div class="p-b-12">
    <div class="text-secondary text-size-xs">
      {{ 'contact.mobile_phone_l' | translate }}
    </div>
    <div class="text-size-md d-flex justify-content-between">
      <div>{{ contact.contactData.mobile }}</div>
      <a class="icon-container" [href]="call(contact.contactData.mobile)">
        <rs-web-icon [icon]="immomioIconName.Phone" [size]="16"></rs-web-icon>
      </a>
    </div>
  </div>
}

@if (contact.contactData?.email) {
  <div class="p-b-12">
    <div class="text-secondary text-size-xs">
      {{ 'contact.email_l' | translate }}
    </div>
    <div class="text-size-md d-flex justify-content-between">
      <div>{{ contact.contactData.email }}</div>
      <a class="icon-container" [href]="mailto(contact.contactData.email)">
        <rs-web-icon [icon]="immomioIconName.Mail" [size]="16"></rs-web-icon>
      </a>
    </div>
  </div>
}

@if (contact.address) {
  <div class="p-b-12">
    <div class="text-secondary text-size-xs">
      {{ 'contact.address_l' | translate }}
    </div>
    <div class="text-size-md">
      {{ contact.address | address }}
    </div>
  </div>
}

@if (contact.serviceHour) {
  <div class="p-b-12">
    <div class="text-secondary text-size-xs">
      {{ 'contact.working_hours_l' | translate }}
    </div>
    <div class="text-size-md">
      {{ contact.serviceHour }}
    </div>
  </div>
}
