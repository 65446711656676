import { Routes } from '@angular/router';
import { LoginPageGuard } from '@resident-nx/web';
import { LegalWebComponent } from '../legal/components';
import { LegalTextsResolver } from '@resident-nx/shared';
import { ENDPOINTS } from '../../../endpoints';
import { registrationGuard } from './guards';

export const unauthenticatedRoutes: Routes = [
  {
    path: '',
    canActivate: [LoginPageGuard],
    loadComponent: () =>
      import('../unauthenticated/components/').then(
        component => component.UnauthenticatedComponent
      ),
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'login',
      },
      {
        path: 'login',
        loadComponent: () =>
          import('../authentication/components').then(component => component.LoginComponent),
      },
      {
        path: 'registration',
        loadComponent: () => import('./components').then(cmp => cmp.SaasWebRegistrationComponent),
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'check-credentials',
          },
          {
            path: 'check-credentials',
            loadComponent: () =>
              import('./components').then(cmp => cmp.SaasWebRegistrationCheckCredentialsComponent),
          },
          {
            path: '',
            canActivate: [registrationGuard],
            children: [
              {
                path: 'set-consent',
                loadComponent: () =>
                  import('./components').then(cmp => cmp.SaasWebRegistrationSetConsentComponent),
                data: {},
              },
              {
                path: 'registration-success',
                loadComponent: () =>
                  import('./components').then(cmp => cmp.SaasWebRegistrationSuccessComponent),
              },
            ],
          },
        ],
      },
      {
        path: 'legal-information',
        component: LegalWebComponent,
        resolve: {
          legalTexts: LegalTextsResolver,
        },
      },
      {
        path: 'request-reset-password',
        loadComponent: () =>
          import('./components').then(component => component.RequestResetPasswordWebComponent),
      },
      {
        path: ENDPOINTS.PASSWORD_RESET_EMAIL_SENT_PAGE,
        loadComponent: () =>
          import('./components').then(
            component => component.RequestResetPasswordSuccessWebComponent
          ),
      },
      {
        path: 'reset-password/:token',
        loadComponent: () =>
          import('./components').then(component => component.ResetPasswordWebComponent),
        data: { needConfirmation: false, isResetLink: true },
      },
      {
        path: ENDPOINTS.PASSWORD_RESET_SUCCESS_PAGE,
        loadComponent: () =>
          import('./components').then(component => component.ResetPasswordSuccessWebComponent),
      },
    ],
  },
  {
    path: 'auth',
    loadComponent: () =>
      import('../unauthenticated/components/').then(component => component.AuthWebComponent),
  },
  {
    path: 'unauthorized-user',
    loadComponent: () =>
      import('./components/').then(component => component.UnauthorizedUserWebComponent),
  },
];
