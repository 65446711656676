import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap, from } from 'rxjs';

import { FeedbackService, ServiceFacade } from '../../services';
import * as fromActions from './service.actions';
import { IResidentS3File } from '../../models';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ServiceEffects {
  constructor(
    private actions$: Actions,
    private serviceFacade: ServiceFacade,
    private feedbackService: FeedbackService,
    private translate: TranslateService
  ) {}

  loadFaqs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.DownloadDocument),
      switchMap(({ document }) =>
        this.serviceFacade.downloadDocument(document).pipe(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          map((file: any) =>
            fromActions.DownloadDocumentSuccess({
              file,
            })
          ),
          catchError((error: Error) => [fromActions.DownloadDocumentFailed({ error })])
        )
      )
    )
  );

  uploadFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.UploadFiles),
      switchMap(({ filePaths }) =>
        from(this.serviceFacade.uploadFiles(filePaths)).pipe(
          map((files: IResidentS3File[]) => fromActions.UploadFilesSuccess({ files })),
          catchError((error: Error) => {
            void this.feedbackService.error({
              message: this.translate.instant('image_upload.error_l'),
            });
            return [fromActions.UploadFilesFailed({ error })];
          })
        )
      )
    )
  );

  uploadFileWeb$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.UploadFilesWeb),
      switchMap(({ filePaths }) =>
        this.serviceFacade.uploadFilesWeb(filePaths).pipe(
          map((files: IResidentS3File[]) => fromActions.UploadFilesSuccess({ files })),
          catchError((error: Error) => {
            void this.feedbackService.error({
              message: this.translate.instant('image_upload.error_l'),
            });
            return [fromActions.UploadFilesFailed({ error })];
          })
        )
      )
    )
  );
}
