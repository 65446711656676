import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AddressPipe, ContactDetailsBaseComponent } from '@resident-nx/shared';
import { TranslateModule } from '@ngx-translate/core';
import { IconWebComponent } from '../../atoms';

@Component({
  selector: 'rs-web-contact-details',
  templateUrl: './contact-details.component.html',
  styleUrls: ['./contact-details.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TranslateModule, IconWebComponent, AddressPipe],
})
export class ContactDetailsWebComponent extends ContactDetailsBaseComponent {
  public call(phoneNumber: string): string {
    return 'tel:' + phoneNumber;
  }

  public mailto(address: string): string {
    return 'mailto:' + address;
  }
}
