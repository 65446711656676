<div class="cards-container-header">
  <div>
    <i [ngClass]="['icon-' + icon, 'cards-container-header__icon']"></i>
    <span class="title-m primary-text">{{ title | translate }}</span>
  </div>
  <rs-web-button
    class="cards-container-header__button"
    [size]="buttonSizeEnum.SMALL"
    [type]="buttonTypeEnum"
    (tapped)="onTap()"
    >{{ buttonText | translate }}</rs-web-button
  >
</div>
