import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { ConsumptionTimeframe, ImmomioIconName } from '@resident-nx/shared';
import { addMonths, addYears, subMonths, subYears } from 'date-fns';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'rs-web-consumption-date-picker',
  templateUrl: './consumption-date-picker.component.html',
  styleUrls: ['./consumption-date-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: ConsumptionDatePickerWebComponent,
      multi: true,
    },
  ],
})
export class ConsumptionDatePickerWebComponent implements ControlValueAccessor {
  public readonly ImmomioIconName = ImmomioIconName;
  public readonly ConsumptionTimeframe = ConsumptionTimeframe;
  public iconColor = 'var(--color-primary, #3486ef)';
  public isDisabled = false;

  private onChange: (value: Date) => void;
  private onTouched: () => void;

  public date: Date = new Date();

  @Input() isMonthAvailable = false;

  @Input() selectedTimeframe: ConsumptionTimeframe.MONTH | ConsumptionTimeframe.YEAR =
    ConsumptionTimeframe.MONTH;

  @Output() timeframeChange = new EventEmitter<
    ConsumptionTimeframe.MONTH | ConsumptionTimeframe.YEAR
  >();

  @Output() dateChange = new EventEmitter<Date>();

  constructor(private cdr: ChangeDetectorRef) {}

  get isMonthSelected(): boolean {
    return this.selectedTimeframe === ConsumptionTimeframe.MONTH;
  }

  get dateFormat(): string {
    return this.isMonthSelected ? 'LLLL yyyy' : 'yyyy';
  }

  public selectPreviousPeriod(): void {
    this.date = this.isMonthSelected ? subMonths(this.date, 1) : subYears(this.date, 1);
    this.onChange(this.date);
  }

  public selectNextPeriod(): void {
    this.date = this.isMonthSelected ? addMonths(this.date, 1) : addYears(this.date, 1);
    this.onChange(this.date);
  }

  public writeValue(value: Date): void {
    this.date = value;
    this.cdr.markForCheck();
  }

  public registerOnChange(fn: (value: Date) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }
}
