import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ISharedFolderContentBreadcrumb } from '@resident-nx/shared';

@Pipe({
  name: 'rsDocumentBreadcrumbs',
})
export class DocumentBreadcrumbsPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}
  transform(value: ISharedFolderContentBreadcrumb[]): string {
    let breadcrumbs = '';
    const len = value.length;
    if (len > 3) {
      breadcrumbs = '.../';
      value.forEach((breadcrumb, i) => {
        const breadcrumbName =
          breadcrumb.name.length > 20 ? breadcrumb.name.slice(0, 10) + '...' : breadcrumb.name;
        if (i === len - 1 || i === len - 2 || i === len - 3) {
          breadcrumbs += `${breadcrumbName}`;
          if (i !== len - 1) breadcrumbs += '/';
        }
      });
    } else {
      value.forEach((breadcrumb, i) => {
        let breadcrumbName = '';
        if (i === 0) {
          breadcrumbName = this.translateService.instant('shared_content_header_l');
        } else
          breadcrumbName =
            breadcrumb.name.length > 20 ? breadcrumb.name.slice(0, 10) + '...' : breadcrumb.name;
        breadcrumbs += breadcrumbName;
        if (i !== len - 1) breadcrumbs += '/';
      });
    }
    return breadcrumbs;
  }
}
