<div class="mobile-title mb-5 title-m">{{ 'user_profile.contact_details_l' | translate }}</div>
<div class="flex-mobile-column row mb-4">
  <div class="d-flex flex-column col-6 custom-margin-bottom">
    <span class="column-title">{{ 'phone' | translate }}</span>
    <span *ngIf="userData?.phone; else noData">{{ userData.phone }}</span>
  </div>
  <div class="d-flex flex-column col-6">
    <span class="column-title">{{ 'mobile_phone' | translate }}</span>
    <span *ngIf="userData?.mobile; else noData">{{ userData.mobile }}</span>
  </div>
</div>
<div class="flex-mobile-column row mb-4">
  <div class="d-flex flex-column col-6">
    <span class="column-title">{{ 'general.email' | translate }}</span>
    <span *ngIf="userData?.email; else noData">{{ userData.email }}</span>
  </div>
</div>
