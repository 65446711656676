import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

import { RouterService } from '@resident-nx/shared';

@Injectable()
export class RouterWebService extends RouterService {
  navigate = this.router.navigate.bind(this.router);
  back = this.location.back.bind(this.location);
  navigateToExternalUrl = value => (window.location.href = value);

  constructor(
    private router: Router,
    private location: Location
  ) {
    super();
  }
}
