import { Component, input, signal } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import { injectNgControl, NoopValueAccessorDirective } from '../../../directives';
import { ImmomioIconName } from '@resident-nx/shared';
import { IconWebComponent } from '../../atoms';

@Component({
  selector: 'rs-web-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss'],
  standalone: true,
  imports: [TranslateModule, NgClass, NgTemplateOutlet, ReactiveFormsModule, IconWebComponent],
  hostDirectives: [NoopValueAccessorDirective],
})
export class PasswordComponent {
  public passwordControl = injectNgControl();
  public showPasswordAsText = signal(false);
  public showBox = signal(false);
  public hideValidatorBox = input(false);

  public validatorContexts: Array<{ name: string; text: string }> = [
    { name: 'minLength', text: 'password.min_8_characters_l' },
    {
      name: 'hasLowerUpperCase',
      text: 'password.capital_and_lower_case_char_l',
    },
    { name: 'hasNumberSpecial', text: 'pw-check.error.pw-has_number_special' },
  ];
  protected readonly ImmomioIconName = ImmomioIconName;
}
