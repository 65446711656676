import { ActionStateCreator, IActionState } from '../../../utils';
import { createReducer, on } from '@ngrx/store';
import * as fromActions from './login.actions';
import { IFederatedLoginResponse } from '../../../models';

export interface ILoginState {
  loadLoginDataActionState: IActionState;
  federatedLoginData: IFederatedLoginResponse;
}

export const initialState: ILoginState = {
  loadLoginDataActionState: ActionStateCreator.create(),
  federatedLoginData: null,
};

export const loginReducer = createReducer(
  initialState,

  on(fromActions.LoadFederatedLoginData, state => {
    return {
      ...state,
      loadLoginDataActionState: ActionStateCreator.onStart(),
    };
  }),

  on(fromActions.LoadFederatedLoginDataSuccess, (state, { federatedLoginData }) => {
    return {
      ...state,
      federatedLoginData,
      loadLoginDataActionState: ActionStateCreator.onSuccess(),
    };
  }),

  on(fromActions.LoadFederatedLoginDataFailed, (state, { error }) => {
    return {
      ...state,
      loadLoginDataActionState: ActionStateCreator.onError(error),
    };
  })
);

export const getFederatedLoginData = (state: ILoginState) => {
  return state.federatedLoginData;
};
export const getFederatedLoginDataActionState = (state: ILoginState) =>
  state.loadLoginDataActionState;
