import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { SharedContentFacade } from '../../services';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as fromActions from './shared-content.actions';
import { ISharedFolderContentResponse } from '../../models';

@Injectable()
export class SharedContentEffects {
  getSharedContent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.GetSharedContent),
      switchMap(action =>
        this.sharedContentFacade.getSharedContent(action.params).pipe(
          map((response: ISharedFolderContentResponse) =>
            fromActions.GetSharedContentSuccess({
              response,
            })
          ),
          catchError((error: Error) => [fromActions.GetSharedContentFailed({ error })])
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private sharedContentFacade: SharedContentFacade
  ) {}
}
