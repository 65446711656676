import { Component, Input, ViewEncapsulation } from '@angular/core';

import { Store } from '@ngrx/store';

import {
  IHomepageModelState,
  IOffersState,
  ImmomioIconName,
  OffersWidgetBaseComponent,
  getHomepageModuleToken,
  getOffers,
  ButtonTypeEnum,
} from '@resident-nx/shared';
import { ContentCardPreset } from '../../molecules/content-card/content-card.model';

@Component({
  selector: 'rs-web-offers-widget',
  templateUrl: './offers-widget.component.html',
  styleUrls: ['./offers-widget.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class OffersWidgetWebComponent extends OffersWidgetBaseComponent {
  @Input() override icon = ImmomioIconName.Tag;
  @Input() override title = 'news.offers_l';
  @Input() override buttonText = 'news.more_offers_a';

  public offersLimit = 6;
  public token$ = this.homepageStore.select(getHomepageModuleToken);
  public override offers$ = this.offersStore.select(getOffers);
  public ButtonEnumTypes = ButtonTypeEnum;
  public preset = ContentCardPreset;

  constructor(
    protected override offersStore: Store<IOffersState>,
    private homepageStore: Store<IHomepageModelState>
  ) {
    super(offersStore);
  }

  public onTap(offerId: number): void {
    this.tapped.emit(offerId);
  }

  public onTapOverview(): void {
    this.overviewTapped.emit();
  }

  public onTapHomepage(): void {
    this.homepageModuleTapped.emit();
  }
}
