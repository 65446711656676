import { Directive, EventEmitter, Inject, Input, Output } from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { map, take } from 'rxjs';

import { getResidentAppSettings, getTicketSorting } from '../../../+state/';
import * as fromMasterDataState from '../../../+state/account/masterdata';
import * as fromTicketsState from '../../../+state/tickets';
import {
  ContractStatus,
  IContract,
  IEnvironment,
  ImmomioIconName,
  ISort,
  LoadingType,
  SortType,
  TicketingVersion,
  TicketIssueType,
  TicketSortField,
} from '../../../models';
import { RouterService } from '../../../services';

@UntilDestroy()
@Directive()
export abstract class TicketsOverviewRequestBaseComponent {
  @Output() openTicket = new EventEmitter();
  @Input() contractId: string;
  public requestTickets$ = this.ticketsStore.select(
    fromTicketsState.getActiveRequestTicketsForSelectedContract
  );
  public hasNextPage$ = this.ticketsStore.select(
    fromTicketsState.hasNextPageActiveRequestTicketsForSelectedContract
  );
  public requestTicketsActionState$ = this.ticketsStore.select(
    fromTicketsState.getActiveRequestTicketsForSelectedContractActionState
  );
  public ticketingVersion$ = this.ticketsStore.select(getResidentAppSettings).pipe(
    map(settings => settings?.ticketingVersion),
    untilDestroyed(this)
  );

  public selectedSorting$ = this.masterDataStore.select(getTicketSorting);
  public selectedContractIsActive = true;
  public immomioIconName = ImmomioIconName;
  public numberOfContracts = this.masterDataStore
    .select(fromMasterDataState.getContractsCount)
    .pipe(untilDestroyed(this));
  public loadingTypes: LoadingType[] = [];
  public selectedContract$ = this.masterDataStore
    .select(fromMasterDataState.getSelectedContract)
    .pipe(untilDestroyed(this));
  public sort: ISort<TicketSortField>;
  protected pagination = {
    offset: 0,
    limit: 20,
  };
  protected readonly sortTypes = SortType;
  protected readonly ticketingVersion = TicketingVersion;

  constructor(
    @Inject('ENVIRONMENT') private environment: IEnvironment,
    private routerService: RouterService,
    private route: ActivatedRoute,
    protected ticketsStore: Store<fromTicketsState.ITicketsState>,
    private masterDataStore: Store<fromMasterDataState.IMasterDataState>
  ) {
    this.selectedContract$.subscribe(res => {
      this.selectedContractIsActive = res?.status !== ContractStatus.ENDED;
    });
  }

  public createTicket() {
    this.masterDataStore
      .select(fromMasterDataState.getContracts)
      .pipe(
        map((contracts: IContract[]) => contracts?.filter(c => c?.status !== ContractStatus.ENDED)),
        take(1)
      )
      .subscribe({
        next: res => {
          if (res.length === 1) {
            // prevent expired Contract to be used for ticket creation
            this.masterDataStore.dispatch(fromMasterDataState.SelectContract({ id: res[0].id }));
            this.redirect('ticket-creation');
          } else if (res.length >= 2) {
            this.redirect('select-contract');
          } else {
            this.redirect('ticket-creation');
          }
        },
        error: () => {
          this.redirect('ticket-categories');
        },
      });
  }

  public loadMoreRequestTickets(): void {
    this.pagination = { offset: this.pagination.offset + 20, limit: 20 };
    this.ticketsStore.dispatch(
      fromTicketsState.LoadActiveRequestTicketsForSelectedContract({
        ...this.pagination,
        sort: this.sort,
      })
    );
  }

  private redirect(path: string) {
    void this.routerService.navigate([`./${path}`, TicketIssueType.CONCERN], {
      relativeTo: this.route,
    });
  }
}
