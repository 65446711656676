import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { EmergencyContactBannerBaseComponent } from '@resident-nx/shared';
import { IconWebComponent, LabelWebComponent } from '../../../atoms';

@Component({
  selector: 'rs-web-emergency-contact-banner',
  standalone: true,
  imports: [IconWebComponent, LabelWebComponent, TranslateModule],
  templateUrl: './emergency-contact-banner.component.html',
  styleUrl: './emergency-contact-banner.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmergencyContactBannerComponent extends EmergencyContactBannerBaseComponent {}
