import { Pipe, PipeTransform } from '@angular/core';
import { IContract, ImmomioIconName } from '../models';

@Pipe({
  name: 'contractName',
  standalone: true,
})
export class ContractNamePipe implements PipeTransform {
  transform(value: IContract, brackets = false): string | null {
    if (!value) {
      return null;
    }
    let response = '';
    const address = value.property?.address;
    if (address) {
      response += address.street ? address.street : '';
      if (address.street && address.houseNumber) {
        response += ' ' + address.houseNumber;
      }
      if (response.length && !brackets) {
        response += ', ';
      }
    }
    response = brackets ? response + ' (' + value.externalId + ')' : response + value.externalId;
    return response;
  }
}
@Pipe({
  name: 'contractIcon',
  standalone: true,
})
export class ContractIconPipe implements PipeTransform {
  transform(contractType: string): ImmomioIconName {
    switch (contractType) {
      case 'FLAT':
        return ImmomioIconName.HouseDoor;
      case 'GARAGE':
        return ImmomioIconName.Parking;
      case 'COMMERCIAL':
        return ImmomioIconName.Skyscraper;

      default:
        return null;
    }
  }
}

@Pipe({
  name: 'contractTypeName',
})
export class ContractTypeNamePipe implements PipeTransform {
  transform(contractType: string): string {
    switch (contractType) {
      case 'FLAT':
        return 'document.type.rental_contract_flat';
      case 'GARAGE':
        return 'document.type.rental_contract_garage';
      case 'COMMERCIAL':
        return 'document.type.rental_contract_commercial';

      default:
        return 'document.type.rental_contract';
    }
  }
}
