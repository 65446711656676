import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { TicketCreationService } from '../../../../../services';
import { ScoreQuestionType } from '@resident-nx/shared';
import { DatePipe, NgTemplateOutlet } from '@angular/common';
import { FileListPipe } from '../../../../../pipes';
import { ImageBorderRadiusEnum, ImageWebComponent } from '../../../../atoms';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'rs-web-ticket-summary',
  standalone: true,
  templateUrl: './ticket-summary.component.html',
  styleUrl: './ticket-summary.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgTemplateOutlet, ImageWebComponent, FileListPipe, TranslateModule, DatePipe],
})
export class TicketSummaryComponent {
  #ticketCreationService = inject(TicketCreationService);
  public summary = this.#ticketCreationService.getSummaryData();
  protected readonly ScoreQuestionType = ScoreQuestionType;
  protected readonly ImageBorderRadiusEnum = ImageBorderRadiusEnum;
}
