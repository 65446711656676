import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, inject } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbActiveOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import {
  ButtonTypeEnum,
  IContactPersonResponse,
  SelectContract,
  getContracts,
} from '@resident-nx/shared';
import { BehaviorSubject, take } from 'rxjs';
import { TicketCreationService } from '../../../../web/services';
import { ButtonWebComponent } from '../../atoms';
import { ContactDetailsWebComponent } from '../contact-details/contact-details.component';
import { ContactPreviewComponent } from '../contact-preview/contact-preview.component';
import { SelectContractWebComponent } from '../ticketing/ticket-creation/select-contract/select-contract.component';
import { EmergencyContactFlowHeaderComponent } from './emergency-contact-flow-header/emergency-contact-flow-header.component';
import { SelectContactComponent } from './select-contact/select-contact.component';

type EmergencyContactFlowSteps = 0 | 1 | 2;

@Component({
  selector: 'rs-web-emergency-contact-flow',
  standalone: true,
  imports: [
    TranslateModule,
    SelectContractWebComponent,
    SelectContactComponent,
    AsyncPipe,
    ReactiveFormsModule,
    ContactDetailsWebComponent,
    ButtonWebComponent,
    ContactPreviewComponent,
    EmergencyContactFlowHeaderComponent,
  ],
  templateUrl: './emergency-contact-flow.component.html',
  styleUrl: './emergency-contact-flow.component.scss',
  providers: [TicketCreationService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmergencyContactFlowComponent implements OnInit {
  #store = inject(Store);
  #activeOffCanvas = inject(NgbActiveOffcanvas);
  #ticketCreationService = inject(TicketCreationService);
  public form = this.#ticketCreationService.form;
  public step = new BehaviorSubject<EmergencyContactFlowSteps>(0);
  protected readonly ButtonTypeEnum = ButtonTypeEnum;
  public contracts$ = this.#store.select(getContracts).pipe(take(1));
  public selectedContact$ = new BehaviorSubject<IContactPersonResponse | null>(null);
  public headerTitle = new BehaviorSubject<string>('emergency.emergency_help_title');
  public headerDescription = new BehaviorSubject<string>('emergency.emergency_help_desc');
  ngOnInit(): void {
    this.contracts$.subscribe(res => {
      if (Array.isArray(res) && res.length === 1) {
        this.#store.dispatch(
          SelectContract({
            id: res[0].id,
          })
        );
        this.step.next(1);
      }
    });
    this.form.controls.contract.valueChanges.subscribe(() => {
      if (this.form.controls.contract.valid) {
        this.step.next(1);
      }
    });
    this.step.subscribe(currentStep => this.handleStepChange(currentStep));
  }
  public contactSelected(contact: IContactPersonResponse) {
    this.selectedContact$.next(contact);
    this.step.next(2);
  }

  public back() {
    switch (this.step.getValue()) {
      case 1:
        this.form.controls.contract.setValue(null);
        this.step.next(0);
        break;
      case 2:
        this.step.next(1);
        this.selectedContact$.next(null);
        break;
      default:
        break;
    }
  }

  public close() {
    this.#activeOffCanvas.dismiss();
  }

  private handleStepChange(step: EmergencyContactFlowSteps): void {
    switch (step) {
      case 0:
        this.headerTitle.next('emergency.emergency_help_title');
        this.headerDescription.next('emergency.emergency_help_desc');
        break;
      case 1:
        this.headerTitle.next('emergency.emergency_help_title');
        this.headerDescription.next('emergency.emergency_help_select_contact_desc');
        break;
      case 2:
        this.headerTitle.next('emergency.emergency_help_title');
        this.headerDescription.next('emergency.emergency_help_contact_desc');
        break;
      default:
        break;
    }
  }
}
