import { Component, Input, inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ImmomioIconName } from '@resident-nx/shared';

@Component({
  selector: 'rs-web-account-balance-modal',
  templateUrl: './account-balance-modal.component.html',
  styleUrls: ['./account-balance-modal.component.scss'],
})
export class AccountBalanceWebModalComponent {
  @Input() text: string;
  public activeModal = inject(NgbActiveModal);
  public immomioIconName = ImmomioIconName;
}
