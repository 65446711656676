@if ((emergencyContactsActionState$ | async).done && (emergencyContacts$ | async).length > 0) {
  @for (contact of emergencyContacts$ | async; track contact) {
    <rs-web-contact-preview
      (click)="contactSelected.emit(contact)"
      [contact]="contact"
      [showSelectionArrowIndicator]="true"
    ></rs-web-contact-preview>
  }
} @else if (
  (emergencyContactsActionState$ | async).done && (emergencyContacts$ | async).length === 0
) {
  <rs-web-label
    className="text-secondary text-center"
    [text]="'contacts.no_data_l' | translate"
  ></rs-web-label>
}
