import { Directive } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';

@Directive()
export abstract class FederateLoginFormBaseComponent {
  emailControl = this.fb.control('', {
    validators: [Validators.required, Validators.email],
  });

  constructor(
    private fb: FormBuilder,
    protected store: Store
  ) {}
}
