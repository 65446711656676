import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, switchMap } from 'rxjs/operators';

import { IFaq, IFaqOverviewResponseBean } from '../../models';
import { SupportFacade } from '../../services/support/support.facade';
import * as fromActions from './support.actions';
import * as fromReducers from './support.reducer';

@Injectable()
export class SupportEffects {
  constructor(
    private actions$: Actions,
    private store: Store<fromReducers.ISupportState>,
    private supportFacade: SupportFacade
  ) {}

  loadFaqs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.LoadFaqs),
      switchMap(() =>
        // eslint-disable-next-line
        this.supportFacade.getPublishedFaqs().pipe(
          map((faqs: IFaq[]) =>
            fromActions.LoadFaqsSuccess({
              faqs,
            })
          ),
          catchError((error: Error) => [fromActions.LoadFaqsFailed({ error })])
        )
      )
    )
  );
  loadOverviewFaqs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.LoadOverviewFaqs),
      switchMap(() =>
        // eslint-disable-next-line
        this.supportFacade.getFaqOverview().pipe(
          map((faqsOverview: IFaqOverviewResponseBean) =>
            fromActions.LoadOverviewFaqsSuccess({
              faqsOverview,
            })
          ),
          catchError((error: Error) => [fromActions.LoadOverviewFaqsFailed({ error })])
        )
      )
    )
  );
}
