import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  ButtonSizeEnum,
  ButtonTypeEnum,
  CardsContainerHeaderBaseComponent,
} from '@resident-nx/shared';
import { ButtonWebComponent } from '../../atoms';
import { NgClass } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'rs-web-cards-container-header',
  templateUrl: './cards-container-header.component.html',
  styleUrls: ['./cards-container-header.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ButtonWebComponent, NgClass, TranslateModule],
})
export class CardsContainerHeaderWebComponent extends CardsContainerHeaderBaseComponent {
  @Input() buttonTypeEnum: ButtonTypeEnum = ButtonTypeEnum.PRIMARY_INVERTED;
  public buttonSizeEnum = ButtonSizeEnum;

  public onTap(): void {
    this.tapped.emit();
  }
}
