import { AsyncPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  OnInit,
  Output,
} from '@angular/core';
import { ControlContainer, ReactiveFormsModule } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ImmomioIconName } from '@resident-nx/shared';
import { debounceTime, filter } from 'rxjs';
import { TicketCreationService } from '../../../../../services';
import { IconWebComponent, LoaderWebComponent } from '../../../../atoms';
import { TicketsNoCatalogueComponent } from '../tickets-no-catalogue/tickets-no-catalogue.component';

@UntilDestroy()
@Component({
  selector: 'rs-web-ticket-categories',
  standalone: true,
  templateUrl: './ticket-categories.component.html',
  styleUrl: './ticket-categories.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders: [
    {
      provide: ControlContainer,
      useFactory: () => inject(ControlContainer, { skipSelf: true }),
    },
  ],
  imports: [
    ReactiveFormsModule,
    IconWebComponent,
    AsyncPipe,
    LoaderWebComponent,
    TicketsNoCatalogueComponent,
  ],
})
export class TicketCategoriesComponent implements OnInit {
  #ticketCreationService = inject(TicketCreationService);
  public categoriesFormRecord = this.#ticketCreationService.categoriesFormRecord;
  public currentCategoryFormControl = this.#ticketCreationService.currentCategoryFormControl;
  public categoriesActionState = this.#ticketCreationService.categoriesActionState;
  public step = this.#ticketCreationService.categoryStep;
  @Output() categoryQuestionChange = new EventEmitter<void>();

  ngOnInit() {
    this.#ticketCreationService.fetchCategoryQuestions();
    this.categoriesFormRecord.valueChanges
      .pipe(
        filter(v => !Object.values(v).some(value => value === null)),
        debounceTime(100),
        untilDestroyed(this)
      )
      .subscribe(value => {
        const latestValue = value[this.currentCategoryFormControl.value.id];
        if (latestValue.questionIds.length) {
          this.#ticketCreationService.setCurrentCategoryAndRecord(latestValue.questionIds[0]);
        } else {
          this.categoryQuestionChange.emit();
        }
      });
  }

  public goToPreviousCategory() {
    this.#ticketCreationService.goToPreviousCategory();
    this.categoryQuestionChange.emit();
  }

  protected readonly ImmomioIcons = ImmomioIconName;
}
