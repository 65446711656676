<div *ngIf="contracts$ | async">
  <div class="contracts__carousel">
    <div *ngIf="pageSize === 1" class="contracts__carousel-top-navigation mt-3 mb-3">
      <div class="contracts__card-title title-m">
        <rs-web-icon
          [icon]="allContracts[selectedContractIndex]?.type | contractIcon"
          [size]="18"
        ></rs-web-icon>
        <div class="m-l-10">
          <rs-web-label
            [text]="allContracts[selectedContractIndex]?.type | contractTypeName | translate"
          ></rs-web-label>
        </div>
      </div>
      <div class="contracts__carousel-top-buttons">
        <rs-web-button
          [iconLeft]="'chevron-left'"
          [type]="ButtonTypeEnum.EMPTY_LIGHT"
          (tapped)="onPrevious()"
        ></rs-web-button>
        <rs-web-button
          [iconLeft]="'chevron-right'"
          [type]="ButtonTypeEnum.EMPTY_LIGHT"
          (tapped)="onNext()"
        ></rs-web-button>
      </div>
    </div>
    <div class="contracts__carousel-row">
      <rs-web-button
        *ngIf="pageSize > 1 && splitContracts.length > 1"
        [iconLeft]="'chevron-left'"
        [type]="ButtonTypeEnum.EMPTY_LIGHT"
        (tapped)="onPrevious()"
      ></rs-web-button>
      <div class="contracts__carousel">
        <ngb-carousel
          #carousel
          [showNavigationArrows]="false"
          [showNavigationIndicators]="false"
          [interval]="0"
          (slide)="onSlide()"
        >
          <ng-template *ngFor="let contracts of splitContracts; index as pageIndex" ngbSlide>
            <div class="contracts__contract-container">
              <div
                class="contracts__card"
                *ngFor="let contract of contracts; index as contractIndex"
              >
                <rs-web-card [elevation]="2">
                  <div *ngIf="pageSize > 1" class="contracts__card-title mb-4 title-m">
                    <rs-web-icon
                      [icon]="contracts[contractIndex]?.type | contractIcon"
                      [size]="18"
                    ></rs-web-icon>
                    <div class="m-l-10">
                      <rs-web-label
                        [text]="contracts[contractIndex]?.type | contractTypeName | translate"
                      ></rs-web-label>
                    </div>
                  </div>
                  <div class="contracts__card-info mb-4">
                    <div class="contracts__card-address">
                      <div>
                        {{ contract?.property?.address?.zipCode }}
                        {{ contract?.property?.address?.city }}
                      </div>
                      <div class="contracts__card-address-down">
                        {{ contract?.property?.address?.street }}
                        {{ contract?.property?.address?.houseNumber }}
                      </div>
                    </div>
                    <div class="contracts__card-details">
                      <div>{{ 'contract_id_l' | translate }} {{ contract?.id }}</div>
                      <div>
                        {{ 'valid_from_l' | translate }}
                        {{ contract?.contractStartTime | date: 'dd.MM.YYYY' }}
                      </div>
                    </div>
                  </div>
                  <rs-web-pie-chart
                    [data]="contract.chartData"
                    [height]="300"
                    [width]="300"
                  ></rs-web-pie-chart>
                </rs-web-card>

                <rs-web-card [elevation]="2">
                  <div class="contracts__balance-card">
                    <div class="contracts__balance-icon-container">
                      <rs-web-icon [icon]="immomioIconName.ChartLine" [size]="36"></rs-web-icon>
                    </div>
                    <div class="contracts__balance-content">
                      <div class="contracts__balance-content__title-container">
                        <span
                          ><strong>{{ 'contracts.account_balance_title' | translate }}</strong>
                        </span>
                        <rs-web-icon
                          *ngIf="contract?.accountBalance?.infoText"
                          [icon]="immomioIconName.QuestionOutlined"
                          [size]="16"
                          [style.cursor]="'pointer'"
                          (click)="openModal(contract?.accountBalance?.infoText)"
                        ></rs-web-icon>
                      </div>
                      <span
                        [class]="{
                          negative: contract?.accountBalance?.amount < 0,
                          positive: contract?.accountBalance?.amount > 0,
                        }"
                      >
                        {{
                          contract?.accountBalance?.amount < 0
                            ? (contract?.accountBalance?.amount | displayCurrency: 'EUR') +
                              ' ' +
                              ('contracts.account_balance_negative_l' | translate)
                            : contract?.accountBalance?.amount > 0
                              ? '+ ' + (contract?.accountBalance?.amount | displayCurrency: 'EUR')
                              : (contract?.accountBalance?.amount | displayCurrency: 'EUR')
                        }}</span
                      >
                    </div>
                  </div>
                </rs-web-card>

                <rs-web-emergency-contacts-overview
                  [contract]="contract.id"
                ></rs-web-emergency-contacts-overview>
              </div>
            </div>
          </ng-template>
        </ngb-carousel>
      </div>
      <rs-web-button
        *ngIf="pageSize > 1 && splitContracts.length > 1"
        [iconLeft]="'chevron-right'"
        [type]="ButtonTypeEnum.EMPTY_LIGHT"
        (tapped)="onNext()"
      ></rs-web-button>
    </div>
  </div>
</div>
