<div ngbDropdown class="d-inline-block">
  <rs-web-icon ngbDropdownToggle [icon]="ImmomioIconName.Sorting" [size]="16" [cursor]="'pointer'">
  </rs-web-icon>
  <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
    <button ngbDropdownItem class="sortHeader">{{ 'sorting_l' | translate }}</button>
    @for (label of labels; track label.key) {
      <div class="d-flex">
        <button class="d-flex" ngbDropdownItem (click)="selectSorting(label)">
          {{ label.key | translate }}
          @if ((selectedSorting$ | async).key === label.key) {
            <rs-web-icon
              class="sortChecked"
              [icon]="ImmomioIconName.Check"
              [size]="20"
              color="#5FBF89"
            ></rs-web-icon>
          }
        </button>
      </div>
    }
  </div>
</div>
