import { Component, Input } from '@angular/core';
import { IPublishedOffer } from '@resident-nx/shared';

@Component({
  selector: 'rs-web-offers-card',
  templateUrl: './offers-card.component.html',
  styleUrls: ['./offers-card.component.scss'],
})
export class OffersCardWebComponent {
  @Input() item: IPublishedOffer;
}
