import { Component, Input, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  INewsState,
  ImmomioIconName,
  NewsWidgetBaseComponent,
  getNews,
  ButtonTypeEnum,
} from '@resident-nx/shared';
import { ContentCardPreset } from '../../molecules/content-card/content-card.model';

@Component({
  selector: 'rs-web-news-widget',
  templateUrl: './news-widget.component.html',
  styleUrls: ['./news-widget.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NewsWidgetWebComponent extends NewsWidgetBaseComponent {
  @Input() override icon = ImmomioIconName.News;
  @Input() override title = 'news.current_news_l';
  @Input() override buttonText = 'news.more_current_news_a';
  @Input() override maxLines = 1;

  public newsLimit = 4;
  public override news$ = this.newsStore.select(getNews);
  public contentCardPreset = ContentCardPreset;
  public buttonTypeEnum = ButtonTypeEnum;

  constructor(protected override newsStore: Store<INewsState>) {
    super(newsStore);
  }

  public onTap(newsId: number): void {
    this.tapped.emit(newsId);
  }

  public onTapOverview(): void {
    this.overviewTapped.emit();
  }
}
