import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  OnInit,
  Output,
} from '@angular/core';
import { AddressPipe, ContractIconPipe, SelectContractBaseComponent } from '@resident-nx/shared';
import { AsyncPipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ControlContainer, ReactiveFormsModule } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TicketCreationService } from '../../../../../services';
import { IconWebComponent } from '../../../../atoms';

@UntilDestroy()
@Component({
  selector: 'rs-web-select-contract',
  standalone: true,
  templateUrl: './select-contract.component.html',
  styleUrl: './select-contract.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AsyncPipe,
    AddressPipe,
    ContractIconPipe,
    TranslateModule,
    ReactiveFormsModule,
    IconWebComponent,
  ],
  viewProviders: [
    {
      provide: ControlContainer,
      useFactory: () => inject(ControlContainer, { skipSelf: true }),
    },
  ],
})
export class SelectContractWebComponent extends SelectContractBaseComponent implements OnInit {
  #ticketCreationService = inject(TicketCreationService);
  @Output() contractChange = new EventEmitter<void>();

  override ngOnInit() {
    this.#ticketCreationService.form.controls.contract.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(contract => {
        this.contractChange.emit();
        this.selectContract(contract, false);
      });
  }
}
