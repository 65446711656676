import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { IFederatedLoginEnvironmentVariables, StorageService } from '@resident-nx/shared';

@Injectable()
export class LoginPageGuard implements CanActivate {
  constructor(
    private sessionStorage: StorageService,
    private router: Router
  ) {}

  canActivate(): Observable<boolean> {
    const clientId = this.sessionStorage.getItem(IFederatedLoginEnvironmentVariables.CLIENT_ID);

    if (!clientId) return of(true);

    void this.router.navigate(['']);

    return of(false);
  }
}
