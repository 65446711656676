import { AuthWebGuard } from './auth-web.guard';
import { LoginPageGuard } from './login-page.guard';
export { ContractGuard } from './contract.guard';
export { PermissionsGuard } from './permissions.guard';

export * from './auth-web.guard';
export * from './login-page.guard';
export * from './permissions.guard';
export * from './ticketing.guard';

export const GENERAL_GUARDS = [AuthWebGuard, LoginPageGuard];
