import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbCarousel, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  AccentColors,
  ButtonTypeEnum,
  ContractsBaseComponent,
  ContractStatus,
  EnhancedContract,
} from '@resident-nx/shared';
import { combineLatest } from 'rxjs';
import { Store } from '@ngrx/store';
import { AccountBalanceWebModalComponent } from '../../molecules/';

@UntilDestroy()
@Component({
  selector: 'rs-web-contracts',
  templateUrl: './contracts.component.html',
  styleUrls: ['./contracts.component.scss'],
})
export class ContractsWebComponent extends ContractsBaseComponent implements OnInit {
  @ViewChild('carousel') carousel: NgbCarousel;
  @Input() pageSize = 3;

  public ButtonTypeEnum = ButtonTypeEnum;
  public splitContracts: EnhancedContract[][] = [[]];

  constructor(
    private modalService: NgbModal,
    protected override store: Store
  ) {
    super(store);
  }

  ngOnInit() {
    combineLatest([
      this.contracts$.pipe(untilDestroyed(this)),
      this.theme$.pipe(untilDestroyed(this)),
    ]).subscribe(([contracts, theme]) => {
      const baseColor = theme?.colorPrimary || AccentColors.SKYLIGHT;
      this.allContracts = contracts
        .filter(contract => contract?.payments && contract.status !== ContractStatus.EXPIRED)
        .map(contract => {
          const chartData = this.prepareChartData(contract, baseColor);
          return {
            ...contract,
            chartData,
          };
        });
      let count = 0;
      let iteration = 0;
      this.allContracts.forEach(contract => {
        if (iteration >= this.splitContracts.length) {
          this.splitContracts.push([]);
        }

        this.splitContracts[iteration].push(contract);

        count++;
        if (count >= this.pageSize) {
          count = 0;
          iteration++;
        }
      });
    });
  }

  onSlide(): void {
    // workaround, because the ApexChart for the pie-chart
    // component will not render by going to the next slide.
    // a resize event triggers the rendering
    window.dispatchEvent(new Event('resize'));
  }

  onNext() {
    this.selectedContractIndex += this.pageSize;
    if (this.selectedContractIndex >= this.splitContracts.length * this.pageSize) {
      this.selectedContractIndex = 0;
    }
    this.carousel.next();
  }

  onPrevious() {
    this.selectedContractIndex -= this.pageSize;
    if (this.selectedContractIndex < 0) {
      this.selectedContractIndex = (this.splitContracts.length - 1) * this.pageSize;
    }
    this.carousel.prev();
  }

  public openModal(text: string) {
    const modalRef = this.modalService.open(AccountBalanceWebModalComponent, { centered: true });
    modalRef.componentInstance.text = text;
  }
}
