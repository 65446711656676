import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  ButtonSizeEnum,
  ButtonTypeEnum,
  getActivePaymentRequests,
  getActivePaymentRequestsActionState,
  getArchivePaymentsActionState,
  getArchivePaymentsRequests,
  IActionState,
  ImmomioIconName,
  IPaymentPositionResident,
  LoadArchivePaymentRequests,
  LoadPaymentRequests,
} from '@resident-nx/shared';
import { Observable } from 'rxjs';

@Component({
  selector: 'rs-web-payments-page',
  templateUrl: './payments-page.component.html',
  styleUrls: ['./payments-page.component.scss'],
})
export class PaymentsPageWebComponent implements OnInit {
  public paymentRequests$: Observable<IPaymentPositionResident[]>;
  public paymentRequestsActionState$: Observable<IActionState>;

  public archivePaymentRequests$: Observable<IPaymentPositionResident[]>;
  public archivePaymentRequstsActionState$: Observable<IActionState>;

  public archiveRequestsOpen = false;

  public buttonType = ButtonTypeEnum;
  public buttonSize = ButtonSizeEnum;
  public immomioIconName = ImmomioIconName;

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.store.dispatch(LoadPaymentRequests({}));
    this.store.dispatch(LoadArchivePaymentRequests({}));

    this.paymentRequests$ = this.store.select(getActivePaymentRequests);
    this.paymentRequestsActionState$ = this.store.select(getActivePaymentRequestsActionState);

    this.archivePaymentRequests$ = this.store.select(getArchivePaymentsRequests);
    this.archivePaymentRequstsActionState$ = this.store.select(getArchivePaymentsActionState);
  }

  public openArchivePayments(): void {
    this.archiveRequestsOpen = true;
  }

  public closeArchivePayments(): void {
    this.archiveRequestsOpen = false;
  }
}
