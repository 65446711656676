@if (contractMenuDesign === design.DROPDOWN) {
  <div class="d-flex relative-positioned" ngbDropdown #dropdown="ngbDropdown">
    @if ((selectedContract$ | async) && showContract) {
      <rs-web-icon
        [icon]="(selectedContract$ | async).type | contractIcon"
        [size]="iconSize"
      ></rs-web-icon>
      @if (showContract) {
        <div class="title-xs">{{ selectedContract$ | async | contractName: true }}</div>
      }
    }
    @if ((contracts$ | async).length > 1 || showIcon) {
      <rs-web-icon
        (click)="dropdown.open()"
        [icon]="immomioIconName"
        [size]="iconSize"
      ></rs-web-icon>
    }
    <div
      ngbDropdownMenu
      rsWebElevation
      [elevation]="3"
      class="contract-menu-dropdown"
      [class.left]="dropdownLeft"
      (click)="dropdown.close()"
    >
      @if (contracts$ | async) {
        <div>
          <strong>{{ 'contracts.choose_contract_l' | translate }} </strong>
        </div>
        @for (contract of contracts$ | async; track contract.id) {
          <div class="contract-menu-dropdown__item" (click)="selectContract(contract)">
            <rs-web-icon [icon]="contract.type | contractIcon" [size]="iconSize"></rs-web-icon>
            <div class="m-x-5">{{ contract | contractName: true }}</div>
          </div>
        }
      }
    </div>
  </div>
} @else if (contractMenuDesign === design.CHIP && contracts$ | async) {
  <div class="d-flex flex-wrap chip-container">
    @for (contract of contracts$ | async; track contract.id) {
      <rs-web-chip
        [selected]="(selectedContract$ | async).externalId === contract.externalId"
        (click)="selectContract(contract)"
      >
        <rs-web-icon
          [color]="(selectedContract$ | async).externalId === contract.externalId ? 'white' : ''"
          [icon]="contract.type | contractIcon"
          [size]="17"
        ></rs-web-icon>

        <span
          [class.selected]="(selectedContract$ | async).externalId === contract.externalId"
          class="chip-text"
          >{{ contract | contractName: true }}</span
        >
      </rs-web-chip>
    }
  </div>
} @else if (contractMenuDesign === design.SELECT && contracts$ | async) {
  <div class="dropdown" ngbDropdown>
    <button
      class="btn btn-block text-left d-flex toggle"
      type="button"
      ngbDropdownToggle
      [title]="selectedContract$ | async | contractName: true"
    >
      <div class="d-flex">
        <rs-web-icon
          [icon]="(selectedContract$ | async).type | contractIcon"
          [size]="iconSize"
        ></rs-web-icon>
        <div class="m-x-5">
          {{ (selectedContract$ | async | contractName: true) || title | translate }}
        </div>
      </div>
    </button>
    <div class="dropdown-menu" ngbDropdownMenu>
      <div class="dropdown-items">
        <div>
          <strong>{{ title | translate }} </strong>
        </div>
        @for (contract of contracts$ | async; track contract.id) {
          <div
            class="dropdown-item d-flex"
            [class.expired-contract]="
              disableExpiredContractSelection && contract.status === contractStatus.EXPIRED
            "
            (click)="
              !(disableExpiredContractSelection && contract.status === contractStatus.EXPIRED) &&
                selectContract(contract)
            "
          >
            <rs-web-icon [icon]="contract.type | contractIcon" [size]="iconSize"></rs-web-icon>
            <div class="m-x-5">{{ contract | contractName: true }}</div>
          </div>
        }
      </div>
    </div>
  </div>
}
