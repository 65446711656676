<div class="d-flex flex-column position-relative mb-4">
  <h4 class="mobile title-m">{{ 'dashboard.consumption_widget_l' | translate }}</h4>
  <rs-web-contract-menu
    class="mb-3 mobile"
    [immomioIconName]="ImmomioIconName.ContextMenu"
    [iconSize]="12"
    [contractMenuDesign]="contractMenuDesign.DROPDOWN"
  ></rs-web-contract-menu>
  <rs-web-consumption-dashboard-cards
    [metersByContract]="metersByContract$ | async"
  ></rs-web-consumption-dashboard-cards>
</div>
