import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  ButtonTypeEnum,
  FederateLoginFormBaseComponent,
  LoadFederatedLoginData,
} from '@resident-nx/shared';
import {
  FormControlErrorsDirective,
  FormFieldComponent,
  WebModule,
  getNonceAndState,
  getRedirectUrl,
} from '@resident-nx/web';

@Component({
  selector: 'rs-web-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [WebModule, FormControlErrorsDirective, FormFieldComponent],
})
export class LoginComponent extends FederateLoginFormBaseComponent {
  public buttonTypes = ButtonTypeEnum;

  constructor(
    private router: Router,
    fb: FormBuilder,
    protected override store: Store
  ) {
    super(fb, store);
  }
  public navigateToRegistration() {
    void this.router.navigate(['/registration']);
  }

  public submit() {
    const { nonce, state } = getNonceAndState();
    const redirectUri = getRedirectUrl(window.location.toString(), '/auth', {
      pathAfterAuth: '/home',
    });

    const email = this.emailControl.value;
    this.store.dispatch(LoadFederatedLoginData({ email, nonce, state, redirectUri, isWeb: true }));
  }

  public navigateToRequestResetPasswordPage() {
    void this.router.navigate(['/request-reset-password']);
  }
}
