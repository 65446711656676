import { NgModule } from '@angular/core';

import { WebModule } from '@resident-nx/web';

const MODULES = [WebModule];

@NgModule({
  imports: [...MODULES],
  exports: [...MODULES],
})
export class SharedModule {}
