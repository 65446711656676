import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';

import {
  BreadcrumbItem,
  ButtonTypeEnum,
  CurrentNewsDetailsBaseComponent,
  INewsState,
} from '@resident-nx/shared';
import { ROUTE } from '../../../models';

@UntilDestroy()
@Component({
  selector: 'rs-web-current-news-details',
  templateUrl: './news-details.component.html',
  styleUrls: ['./news-details.component.scss'],
})
export class CurrentNewsDetailsWebComponent
  extends CurrentNewsDetailsBaseComponent
  implements OnInit
{
  public buttonTypeEnum = ButtonTypeEnum;
  public breadcrumbs: BreadcrumbItem[] = [
    {
      label: 'main_navigation_news_l',
      url: ROUTE.NEWS,
    },
    {
      label: 'news.current_news_l',
      url: `${ROUTE.NEWS}/${ROUTE.CURRENT_NEWS}`,
    },
  ];
  constructor(
    store: Store<INewsState>,
    private route: ActivatedRoute,
    private router: Router
  ) {
    super(store);
  }

  public ngOnInit(): void {
    this.route.params.pipe(untilDestroyed(this)).subscribe(params => {
      this.breadcrumbs.push({ label: params.id });
    });
  }

  public onNavigateBack() {
    this.router.navigate([ROUTE.NEWS, ROUTE.CURRENT_NEWS]);
  }

  public onNavigate(url: string) {
    this.router.navigate([url]);
  }
}
