import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { NgbActiveOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { ITicketResidentOverview } from '@resident-nx/shared';
import { TicketDetailsPageWebComponent } from '../../../organisms/ticketing';

/**
 * The side sheet component is there as a wrapper to
 * accept data from the side-sheet service, so that
 * the data can be passed on to the actual component
 * displaying the data.
 */
@Component({
  selector: 'rs-web-ticket-details-side-sheet',
  templateUrl: './ticket-details-side-sheet.component.html',
  styleUrl: './ticket-details-side-sheet.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TicketDetailsPageWebComponent],
})
export class TicketDetailsSideSheetWebComponent {
  @Input() node: ITicketResidentOverview;
  public ngbActiveOffcanvas = inject(NgbActiveOffcanvas);
}
