import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap, withLatestFrom } from 'rxjs';

import * as fromActions from './payment.actions';
import { PaymentRequestsFacade } from '../../services';
import { IPaymentPositionResident } from '../../models';
import { Store } from '@ngrx/store';
import {
  getActivePaymentRequests,
  getActivePaymentRequestsActionState,
  getArchivePaymentsActionState,
  getArchivePaymentsRequests,
} from './payment.selectors';
import { differenceInMinutes } from 'date-fns';

@Injectable()
export class PaymentRequestsEffects {
  loadPaymentRequests$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.LoadPaymentRequests),
      withLatestFrom(
        this.store.select(getActivePaymentRequests),
        this.store.select(getActivePaymentRequestsActionState)
      ),
      switchMap(([action, data, actionState]) => {
        const noData = !data || data?.length === 0;
        const doneTimeIsOld =
          actionState?.doneTime && differenceInMinutes(new Date(), actionState.doneTime) > 30;
        if (noData || doneTimeIsOld || action.forced) {
          return this.paymentRequestsFacade.getPaymentsRequests(false).pipe(
            map((response: IPaymentPositionResident[]) =>
              fromActions.LoadPaymentRequestsSuccess({ response })
            ),
            catchError((error: Error) => [fromActions.LoadPaymentRequestsFailed({ error })])
          );
        } else {
          return of(
            fromActions.LoadPaymentRequestsSuccess({
              response: data,
            })
          );
        }
      })
    )
  );
  loadArchivePaymentRequests$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.LoadArchivePaymentRequests),
      withLatestFrom(
        this.store.select(getArchivePaymentsRequests),
        this.store.select(getArchivePaymentsActionState)
      ),
      switchMap(([action, data, actionState]) => {
        const noData = !data || data?.length === 0;
        const doneTimeIsOld =
          actionState?.doneTime && differenceInMinutes(new Date(), actionState.doneTime) > 30;
        if (noData || doneTimeIsOld || action.forced) {
          return this.paymentRequestsFacade.getPaymentsRequests(true).pipe(
            map((response: IPaymentPositionResident[]) =>
              fromActions.LoadArchivePaymentRequestsSuccess({ response })
            ),
            catchError((error: Error) => [fromActions.LoadArchivePaymentRequestsFailed({ error })])
          );
        } else {
          return of(
            fromActions.LoadArchivePaymentRequestsSuccess({
              response: data,
            })
          );
        }
      })
    )
  );

  constructor(
    private actions$: Actions,
    private paymentRequestsFacade: PaymentRequestsFacade,
    private store: Store
  ) {}
}
