import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  ButtonSizeEnum,
  ButtonTypeEnum,
  IUserData,
  UserPageBaseComponent,
} from '@resident-nx/shared';
import { ROUTE } from '../../../models';

@Component({
  selector: 'rs-web-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserProfileWebComponent extends UserPageBaseComponent {
  @Input() userData: IUserData;
  public buttonType = ButtonTypeEnum;
  public buttonSize = ButtonSizeEnum;

  public goToTickets(): void {
    this.routerService.navigate([ROUTE.TICKETS, ROUTE.REQUESTS]);
  }
}
