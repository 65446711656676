import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  AnnouncementsOverviewBaseComponent,
  IAnnouncementsState,
  BreadcrumbItem,
  ButtonTypeEnum,
} from '@resident-nx/shared';
import { ROUTE } from '../../../models';
import { filter, take } from 'rxjs';

@Component({
  selector: 'rs-web-announcements-overview',
  templateUrl: './announcements-overview.component.html',
  styleUrls: ['./announcements-overview.component.scss'],
})
export class AnnouncementsOverviewWebComponent extends AnnouncementsOverviewBaseComponent {
  public breadcrumbs: BreadcrumbItem[] = [
    {
      label: 'main_navigation_news_l',
      url: ROUTE.NEWS,
    },
    {
      label: 'news.announcements_l',
      url: `${ROUTE.NEWS}/${ROUTE.ANNOUNCEMENTS}`,
    },
  ];
  public buttonTypeEnum = ButtonTypeEnum;

  constructor(
    private router: Router,
    announcementsStore: Store<IAnnouncementsState>
  ) {
    super(announcementsStore, 30);
  }

  public onNavigate(event: string = ROUTE.NEWS) {
    this.router.navigate([event]);
  }

  public onTapArticle(id: number) {
    this.router.navigate([ROUTE.NEWS, ROUTE.ANNOUNCEMENTS, id]);
  }

  public onScroll() {
    this.announcementsActionState$
      .pipe(
        filter(state => !state.pending),
        take(1)
      )
      .subscribe(() => this.loadMoreAnnouncements());
  }
}
