import { NgIfContext } from '@angular/common';
import { Component, Input, TemplateRef } from '@angular/core';
import { IUserData } from '@resident-nx/shared';

@Component({
  selector: 'rs-web-user-contact-details',
  templateUrl: './user-contact-details.component.html',
  styleUrls: ['./user-contact-details.component.scss'],
})
export class UserContactDetailsWebComponent {
  @Input() userData: IUserData;
  @Input() noData: TemplateRef<NgIfContext<string>>;
}
