import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import {
  ComparisonStatus,
  ComparisonType,
  ConsumptionComparisonService,
  ConsumptionComparisonWidths,
  ConsumptionTimeframe,
  getSelectedConsumptionForMeter,
  IConsumption,
  IConsumptionInfoItem,
  IConsumptionState,
  NgChanges,
} from '@resident-nx/shared';
import { Observable, tap } from 'rxjs';
import { Store } from '@ngrx/store';

@Component({
  selector: 'rs-web-consumption-comparison',
  templateUrl: 'consumption-comparison.component.html',
  styleUrls: ['consumption-comparison.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConsumptionComparisonWebComponent implements OnChanges {
  public readonly ComparisonType = ComparisonType;
  @Input() date = new Date();
  @Input() timeframe: ConsumptionTimeframe.MONTH | ConsumptionTimeframe.YEAR;
  @Input() meter: IConsumptionInfoItem;

  public selectedConsumption$: Observable<IConsumption>;
  public backgroundWidths: ConsumptionComparisonWidths = {
    consumption: 0,
    pastConsumption: 0,
    benchmark: 0,
  };
  public comparisonStatus: ComparisonStatus;

  public dateFormat: string;

  constructor(
    private store: Store<IConsumptionState>,
    private comparisonService: ConsumptionComparisonService
  ) {}

  ngOnChanges(changes: NgChanges<ConsumptionComparisonWebComponent>): void {
    if (changes.timeframe) {
      this.dateFormat = this.getDateFormatByTimeframe(this.timeframe);
    }
    if (changes.meter) {
      this.selectedConsumption$ = this.store
        .select(getSelectedConsumptionForMeter(this.meter.deviceId))
        .pipe(
          tap(selectedConsumption => {
            if (!selectedConsumption) {
              return;
            }

            this.backgroundWidths =
              this.comparisonService.calculateBackgroundWidth(selectedConsumption);

            this.comparisonStatus = this.comparisonService.getComparisonStatus(selectedConsumption);
          })
        );
    }
  }

  getDateFormatByTimeframe(timeframe: ConsumptionTimeframe.YEAR | ConsumptionTimeframe.MONTH) {
    if (timeframe === ConsumptionTimeframe.YEAR) {
      return 'MMMM yyyy';
    }

    return 'd. MMMM';
  }

  protected readonly ConsumptionTimeframe = ConsumptionTimeframe;
}
