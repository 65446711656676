import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { TicketsBaseComponent } from '@resident-nx/shared';
import {
  CardWebComponent,
  IconWebComponent,
  LabelWebComponent,
  LoaderWebComponent,
  NoDataWebComponent,
} from '../../../atoms';
import { TicketCardWebComponent } from '../ticket-card/ticket-card.component';

@Component({
  selector: 'rs-web-tickets',
  standalone: true,
  imports: [
    CommonModule,
    TicketCardWebComponent,
    CardWebComponent,
    TranslateModule,
    IconWebComponent,
    LoaderWebComponent,
    LabelWebComponent,
    NoDataWebComponent,
  ],
  templateUrl: './tickets.component.html',
  styleUrl: './tickets.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TicketsWebComponent extends TicketsBaseComponent {
  @Input() createButtonText: string;
  @Input() hasActiveContracts: boolean;
  @Output() createTicket = new EventEmitter();
}
