import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { map, Observable } from 'rxjs';
import { getFederatedLoginQuery } from '../../../queries';
import { IFederatedLoginResponse, LoginMethod } from '../../../models';

@Injectable()
export class LoginFacade {
  constructor(private apollo: Apollo) {}

  public getFederatedLoginData(
    email: string,
    nonce: string,
    state: string,
    redirectUri: string
  ): Observable<IFederatedLoginResponse> {
    return this.apollo
      .query<{ federatedLogin: IFederatedLoginResponse }>({
        query: getFederatedLoginQuery,
        variables: {
          input: {
            email: email,
            nonce,
            state,
            redirectUri,
            loginMethod: LoginMethod.DEFAULT,
          },
        },
      })
      .pipe(
        map(result => {
          return result.data.federatedLogin;
        })
      );
  }
}
