<div class="content-tabs" [class]="className">
  <div class="sidebar">
    <div
      *ngFor="let tab of tabItems"
      (click)="selectTab(tab.id)"
      class="tab-item"
      [ngClass]="selectedTab === tab.id ? 'selected' : ''"
    >
      <rs-web-icon
        [icon]="tab.icon"
        [class]="selectedTab === tab.id ? 'selected' : ''"
      ></rs-web-icon>
      <div class="nav-text tab-label" [ngClass]="selectedTab === tab.id ? 'selected' : ''">
        {{ tab.label }}
      </div>
    </div>
  </div>
  <div class="content">
    <router-outlet></router-outlet>
  </div>
</div>
