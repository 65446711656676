import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IUserData } from '@resident-nx/shared';

export enum AvatarSizeEnum {
  TINY = 'tiny',
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
  MAX = 'max',
}

@Component({
  selector: 'rs-web-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvatarWebComponent {
  @Input() name: IUserData;
  @Input() size = AvatarSizeEnum.MAX;
  @Input() rounded = false;
  public avatarSizeEnum = AvatarSizeEnum;
}
