import { ChangeDetectionStrategy, Component } from '@angular/core';
import { EmergencyContactsOverviewBaseComponent } from '@resident-nx/shared';
import { ContactDetailsWebComponent } from '../contact-details/contact-details.component';
import { IconWebComponent, LoaderWebComponent } from '../../atoms';
import { CardsContainerHeaderWebComponent } from '../../molecules';
import { TranslateModule } from '@ngx-translate/core';
import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'rs-web-emergency-contacts-overview',
  templateUrl: './emergency-contacts-overview.component.html',
  styleUrls: ['./emergency-contacts-overview.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ContactDetailsWebComponent,
    LoaderWebComponent,
    CardsContainerHeaderWebComponent,
    TranslateModule,
    AsyncPipe,
    NgIf,
    IconWebComponent,
    NgbAccordionModule,
    NgForOf,
  ],
})
export class EmergencyContactsOverviewWebComponent extends EmergencyContactsOverviewBaseComponent {}
