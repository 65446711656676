import { ACCOUNT_EFFECTS } from './account';
import { GENERAL_EFFECTS } from './general';
import { NEWS_EFFECTS } from './news';
import { SearchEffects } from './search';
import { ServiceEffects } from './service';
import { SupportEffects } from './support';
import { TicketsEffects } from './tickets';
import { ConsumptionEffects } from './consumption';
import { SharedContentEffects } from './shared-content';
import {
  EmailSettingsEffects,
  NotificationSettingsEffects,
  NotificationsEffects,
} from './notifications';

import { HomepageModuleEffects } from './homepage-module';
import { PaymentRequestsEffects } from './payment';

export * from './account';
export * from './news';
export * from './reducers';
export * from './tickets';
export * from './support';
export * from './service';
export * from './meta-reducers';
export * from './search';
export * from './general';
export * from './consumption';
export * from './shared-content';
export * from './notifications';
export * from './homepage-module';
export * from './payment';

export const appEffects = [
  ...NEWS_EFFECTS,
  TicketsEffects,
  ...GENERAL_EFFECTS,
  ...ACCOUNT_EFFECTS,
  SupportEffects,
  ServiceEffects,
  SearchEffects,
  ConsumptionEffects,
  SharedContentEffects,
  NotificationSettingsEffects,
  EmailSettingsEffects,
  NotificationsEffects,
  HomepageModuleEffects,
  PaymentRequestsEffects,
];
