import { Component, Input } from '@angular/core';
import { IPublishedNews, ImmomioIconName } from '@resident-nx/shared';

@Component({
  selector: 'rs-web-news-card',
  templateUrl: './news-card.component.html',
  styleUrls: ['./news-card.component.scss'],
})
export class NewsCardWebComponent {
  @Input() item: IPublishedNews;
  public immomioIconName = ImmomioIconName;
}
