import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, inject } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import {
  ButtonTypeEnum,
  ITicketResidentOverview,
  LoadActiveDamageTickets,
  LoadActiveRequestTickets,
  TicketsWidgetBaseComponent,
} from '@resident-nx/shared';
import { SideSheetService } from '../../../../services';
import { CardWebComponent, NoDataWebComponent } from '../../../atoms';
import { LoaderWebComponent } from '../../../atoms/loader/loader.component';
import {
  CardsContainerHeaderWebComponent,
  TicketDetailsSideSheetWebComponent,
} from '../../../molecules';
import { TicketCardWebComponent } from '../ticket-card/ticket-card.component';

@Component({
  selector: 'rs-web-ticket-widget',
  standalone: true,
  imports: [
    AsyncPipe,
    CardsContainerHeaderWebComponent,
    CardWebComponent,
    LoaderWebComponent,
    TicketCardWebComponent,
    TranslateModule,
    NoDataWebComponent,
  ],
  templateUrl: './tickets-widget.component.html',
  styleUrl: './tickets-widget.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TicketsWidgetWebComponentComponent
  extends TicketsWidgetBaseComponent
  implements OnInit
{
  private sideSheetService = inject(SideSheetService);
  public ticketsLimit = 3;
  public ButtonEnumTypes = ButtonTypeEnum;

  ngOnInit() {
    this.store.dispatch(LoadActiveRequestTickets({ limit: 4, offset: 0 }));
    this.store.dispatch(LoadActiveDamageTickets({ limit: 4, offset: 0 }));
  }

  public ticketNodeTabbed(node: ITicketResidentOverview) {
    this.sideSheetService.open(TicketDetailsSideSheetWebComponent, {
      data: {
        node,
      },
    });
  }
}
