import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';

import {
  BreadcrumbItem,
  ButtonTypeEnum,
  IOffersState,
  OfferDetailsBaseComponent,
} from '@resident-nx/shared';
import { ROUTE } from '../../../models';

@UntilDestroy()
@Component({
  selector: 'rs-web-offer-details',
  templateUrl: './offer-details.component.html',
  styleUrls: ['./offer-details.component.scss'],
})
export class OfferDetailsWebComponent extends OfferDetailsBaseComponent implements OnInit {
  public buttonTypeEnum = ButtonTypeEnum;
  public breadcrumbs: BreadcrumbItem[] = [
    {
      label: 'main_navigation_news_l',
      url: ROUTE.NEWS,
    },
    {
      label: 'news.offers_l',
      url: `${ROUTE.NEWS}/${ROUTE.OFFERS}`,
    },
  ];
  constructor(
    private store: Store<IOffersState>,
    private route: ActivatedRoute,
    private router: Router
  ) {
    super(store);
  }

  ngOnInit(): void {
    this.route.params.pipe(untilDestroyed(this)).subscribe(params => {
      this.breadcrumbs.push({ label: params.id });
    });
  }

  public onNavigateBack() {
    this.router.navigate([ROUTE.NEWS, ROUTE.OFFERS]);
  }

  public onNavigate(event: string) {
    this.router.navigate([event]);
  }
}
