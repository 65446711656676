<div class="cards-container pb-2" (scroll)="onCardsScroll($event.target)" #scrollableContainer>
  <rs-web-consumption-dashboard-card
    *ngFor="let meter of metersByContract?.meters"
    [meter]="meter"
    (click)="navigateToDetails(meter.deviceId)"
  ></rs-web-consumption-dashboard-card>
</div>

<div *ngIf="metersByContract?.meters.length > 1" class="mobile-controls">
  <rs-web-icon
    [ngClass]="{ hidden: activeCardIndex === 0 }"
    (click)="getPrevConsumption(true)"
    [icon]="immomioIconName.ChevronLeft"
    [size]="16"
  ></rs-web-icon>
  <div class="d-flex circle-container">
    <div
      *ngFor="let meter of metersByContract?.meters; let index = index"
      class="circle"
      [ngClass]="{ active: activeCardIndex === index }"
    ></div>
  </div>
  <rs-web-icon
    [ngClass]="{ hidden: activeCardIndex === metersByContract?.meters.length - 1 }"
    (click)="getNextConsumption(true)"
    [icon]="immomioIconName.ChevronRight"
    [size]="16"
  ></rs-web-icon>
</div>
