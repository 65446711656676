import { ACCOUNT_FACADES } from './account';
import { CustomerService } from './customer';
import { GENERAL_FACADES } from './general';
import { GENERAL_GUARDS } from './guards';
import { NEWS_FACADES } from './news';
import { SupportFacade } from './support';
import { TicketsFacade } from './tickets';
import { ConsumptionComparisonService, ConsumptionFacade } from './consumption';
import { SharedContentFacade } from './shared-content';
import {
  NotificationsFacade,
  NotificationSettingsFacade,
  EmailSettingsFacade,
} from './notifications';
import { HomepageModuleFacade } from './homepage-module';
import { PaymentRequestsFacade } from './payment';

export * from './account';
export * from './general';
export * from './interceptors';
export * from './news';
export * from './tickets';
export * from './support';
export * from './guards';
export * from './consumption';
export * from './resolvers';
export * from './shared-content';
export * from './file';
export * from './validators';
export * from './notifications';
export * from './newrelic';
export * from './customer';
export * from './homepage-module';
export * from './payment';

export const FACADES = [
  ...GENERAL_FACADES,
  ...ACCOUNT_FACADES,
  ...NEWS_FACADES,
  TicketsFacade,
  SupportFacade,
  ConsumptionFacade,
  SharedContentFacade,
  NotificationsFacade,
  HomepageModuleFacade,
  PaymentRequestsFacade,
  NotificationSettingsFacade,
  EmailSettingsFacade,
];
export const GUARDS = [...GENERAL_GUARDS];
export const SERVICES = [CustomerService, ConsumptionComparisonService];
