import { Directive } from '@angular/core';
import { RouterService } from '../../services';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../../shared';

@Directive()
export class UserPageBaseComponent {
  constructor(
    public routerService: RouterService,
    protected activeRoute: ActivatedRoute,
    public authService: AuthService
  ) {}

  public goToProfile() {
    void this.routerService.navigate(['profile'], { relativeTo: this.activeRoute });
  }

  public goToPrivacy() {
    void this.routerService.navigate(['privacy'], { relativeTo: this.activeRoute });
  }

  public goToDelete() {
    void this.routerService.navigate(['delete'], { relativeTo: this.activeRoute });
  }

  public goToLegal() {
    void this.routerService.navigate(['legal'], { relativeTo: this.activeRoute });
  }

  public goToFaq() {
    void this.routerService.navigate(['faq'], { relativeTo: this.activeRoute });
  }

  public goBack(): void {
    this.routerService.back();
  }
}
