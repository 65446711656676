import { AnnouncementsCardWebComponent } from './announcements-card/announcements-card.component';
import { CardFooterWebComponent } from './card-footer/card-footer.component';
import { CardsContainerContentWebComponent } from './cards-container-content/cards-container-content.component';

export { CardsContainerHeaderWebComponent } from './cards-container-header/cards-container-header.component';
import { CardsContainerWebComponent } from './cards-container/cards-container.component';
import { CurrentNewsDetailsWebComponent } from './news-details/news-details.component';
import { CurrentNewsOverviewWebComponent } from './current-news-overview/current-news-overview.component';
import { NewsCardWebComponent } from './news-card/news-card.component';
import { OffersCardWebComponent } from './offers-card/offers-card.component';
import { OffersOverviewWebComponent } from './offers-overview/offers-overview.component';
import { OfferDetailsWebComponent } from './offer-details/offer-details.component';
import { AnnouncementsOverviewWebComponent } from './announcements-overview/announcements-overview.component';
import { AnnouncementDetailsWebComponent } from './announcement-details/announcement-details.component';
import { HeaderComponent } from './header/header.component';

export { PasswordComponent } from './password/password.component';
import { ContentCardWebComponent } from './content-card/content-card.component';
import { ConfirmationDialogComponent, ModalComponent, ModalFooterComponent } from './modal';
import { FaqWebComponent } from './faq/faq.component';
import { HomePageWebComponent } from './homepage/homepage.component';
import { UserProfileWebComponent } from './user-profile';
import { PaymentRequestCardWebComponent } from './payment-request-card/payment-request-card.component';
import { ConsumptionChartWebComponent } from './consumption-chart/consumption-chart.component';
import { PieChartWebComponent } from './pie-chart/pie-chart.component';
import { AccountBalanceWebModalComponent } from './account-balance-modal/account-balance-modal.component';
import { ConsumptionDashboardCardWebComponent } from './consumption-dashboard-card/consumption-dashboard-card.component';
import { ConsumptionDatePickerWebComponent } from './consumption-date-picker/consumption-date-picker.component';
import { ConsumptionComparisonWebComponent } from './consumption-comparison/consumption-comparison.component';
import { ConsumptionChartAdditionalInfoWebComponent } from './consumption-chart/consumption-chart-additional-info/consumption-chart-additional-info.component';

export { NewAddressWebModalComponent } from './modal/new-address-modal/new-address.modal.component';
export { ModalContentComponent } from './modal/modal-content/modal-content.component';

export * from './announcements-card/announcements-card.component';
export * from './card-footer/card-footer.component';
export * from './cards-container-content/cards-container-content.component';
export * from './cards-container-header/cards-container-header.component';
export * from './cards-container/cards-container.component';
export * from './news-card/news-card.component';
export * from './offers-card/offers-card.component';
export * from './news-details/news-details.component';
export * from './current-news-overview/current-news-overview.component';
export * from './offers-overview/offers-overview.component';
export * from './offer-details/offer-details.component';
export * from './announcements-overview/announcements-overview.component';
export * from './announcement-details/announcement-details.component';
export * from './password/password.component';
export * from './faq/faq.component';
export * from './modal/index';
export * from './homepage/homepage.component';
export * from './user-profile/user-profile.component';
export * from './consumption-chart/consumption-chart.component';
export * from './consumption-dashboard-card/consumption-dashboard-card.component';
export { FormFieldComponent } from './form/form-field.component';
export * from './account-balance-modal/account-balance-modal.component';
export * from './consumption-comparison/consumption-comparison.component';
export * from './consumption-chart/consumption-chart-additional-info/consumption-chart-additional-info.component';
export * from './ticket';

const modalComponents = [ModalFooterComponent, ConfirmationDialogComponent, ModalComponent];

export const MOLECULES = [
  AnnouncementsCardWebComponent,
  CardFooterWebComponent,
  NewsCardWebComponent,
  OffersCardWebComponent,
  CardsContainerWebComponent,
  CardsContainerContentWebComponent,
  CurrentNewsDetailsWebComponent,
  CurrentNewsOverviewWebComponent,
  OffersOverviewWebComponent,
  OfferDetailsWebComponent,
  AnnouncementsOverviewWebComponent,
  AnnouncementDetailsWebComponent,
  HeaderComponent,
  ContentCardWebComponent,
  FaqWebComponent,
  UserProfileWebComponent,
  ...modalComponents,
  HomePageWebComponent,
  ConsumptionChartWebComponent,
  PaymentRequestCardWebComponent,
  PieChartWebComponent,
  AccountBalanceWebModalComponent,
  ConsumptionDashboardCardWebComponent,
  ConsumptionDatePickerWebComponent,
  ConsumptionComparisonWebComponent,
  ConsumptionChartAdditionalInfoWebComponent,
];
