import { ChangeDetectionStrategy, Component, EventEmitter, Output, inject } from '@angular/core';
import {
  CancelTicket,
  IHQTicketDetailsResponse,
  IMetadataTicketDetailsResponse,
  SharedModule,
  TicketDetailsPageBaseComponent,
  TicketingVersion,
} from '@resident-nx/shared';
import { TicketDetailsOverviewHQWebComponent } from '../ticket-details-overview-hq/ticket-details-overview-hq.component';
import { TicketDetailsOverviewMetadataWebComponent } from '../ticket-details-overview-metadata/ticket-details-overview-metadata.component';
import { CommonModule } from '@angular/common';
import { TicketDetailsHeaderWebComponent } from '../ticket-details-header/ticket-details-header.component';
import { ModalService, TicketCancelModalWebComponent } from '../../../../molecules';
import { ButtonWebComponent } from '../../../../atoms';
import { TicketDetailsChatComponent } from '../ticket-details-chat/ticket-details-chat.component';
import { TicketDetailsChatOverviewComponent } from '../ticket-details-chat/ticket-details-chat-overview/ticket-details-chat-overview.component';

@Component({
  selector: 'rs-web-ticket-details-page',
  templateUrl: './ticket-details-page.component.html',
  styleUrls: ['./ticket-details-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TicketDetailsOverviewHQWebComponent,
    TicketDetailsOverviewMetadataWebComponent,
    TicketDetailsHeaderWebComponent,
    TicketDetailsChatOverviewComponent,
    TicketDetailsChatComponent,
    ButtonWebComponent,
    CommonModule,
    SharedModule,
  ],
})
export class TicketDetailsPageWebComponent extends TicketDetailsPageBaseComponent {
  @Output() closeEvent = new EventEmitter();
  @Output() cancelTicketEvent = new EventEmitter();

  private modalService = inject(ModalService);
  public hideNewMessageCounter = false;
  public chatOpen = false;

  public hasDeleteButton(
    version: TicketingVersion,
    details: IHQTicketDetailsResponse | IMetadataTicketDetailsResponse,
    internalTicketingBooked: boolean
  ): boolean {
    if (version === TicketingVersion.HIERARCHICAL) {
      return ['OPEN', 'IN_PROGRESS', 'WAITING_FOR_OTHERS'].includes(
        (details as IHQTicketDetailsResponse).state
      );
    } else if (version === TicketingVersion.METADATA) {
      // clients with external metadata ticketing can't cancel tickets
      if (!internalTicketingBooked) {
        return false;
      }
      return ['OPEN', 'IN_PROGRESS', 'WAITING_FOR_OTHERS'].includes(
        (details as IMetadataTicketDetailsResponse).status
      );
    }
    return false;
  }

  public onCancelTicket() {
    const modalRef = this.modalService.open(TicketCancelModalWebComponent, { centered: true });
    modalRef.onClose().subscribe(() => {
      this.store.dispatch(
        CancelTicket({
          ticketId: this.ticketId,
          ticketIssueType: this.ticketIssueType,
        })
      );
      this.cancelTicketEvent.emit();
    });
  }

  public openChat() {
    this.chatOpen = true;
    this.hideNewMessageCounter = true;
  }
}
