import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs';

import { EmailSettingsFacade } from '../../../services';
import * as fromActions from './email-settings.actions';

@Injectable()
export class EmailSettingsEffects {
  constructor(
    private actions$: Actions,
    private emailSettingsFacade: EmailSettingsFacade
  ) {}

  loadEmailSettings$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.LoadEmailSettings),
      switchMap(() =>
        this.emailSettingsFacade.getEmailSettings().pipe(
          map(success => fromActions.LoadEmailSettingsSuccess({ success })),
          catchError(error => [fromActions.LoadEmailSettingsFailed({ error })])
        )
      )
    );
  });

  changeEmailSettings$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.ChangeEmailSettings),
      switchMap(({ payload }) =>
        this.emailSettingsFacade.updateEmailSettings(payload).pipe(
          map(response => fromActions.ChangeEmailSettingsSuccess({ response })),
          catchError(error => [fromActions.ChangeEmailSettingsFailed({ error })])
        )
      )
    );
  });
}
